import { useTheme } from '../../../Context/Themes'
import { useLanguage } from '../../../Context/LanguageContext'
import { Frame } from '../../Another/Frame'

export default function Skills() {
    const { lang } = useLanguage();

    return (
        <Frame title={<Title/>} left={false} >
            <div className={`w-full flex flex-col`}>
                <h1 className='text-sm'>{ lang.skills.status }<span className='text-green-500'>OK</span></h1>
                <div className='w-full h-fit grid max-sm:grid-cols-1 max-xl:grid-cols-2 max-2xl:grid-cols-3 2xl:grid-cols-4 m-4'>
                    <SkillsItem name='Python' status='OK'/>
                    <SkillsItem name='C++' status='OK'/>
                    <SkillsItem name='Java' status='OK'/>
                    <SkillsItem name='JavaScript' status='OK'/>
                    <SkillsItem name='Go' status='OK'/>
                    <SkillsItem name='Visual Basic' status='OK'/>
                    <SkillsItem name='React' status='OK'/>
                    <SkillsItem name='Vue' status='OK'/>
                    <SkillsItem name='Bootstrap' status='OK'/>
                    <SkillsItem name='Tailwind' status='OK'/>
                    <SkillsItem name='Flask' status='OK'/>
                    <SkillsItem name='MySQL' status='OK'/>
                    <SkillsItem name='Mongo-DB' status='OK'/>
                    <SkillsItem name='ElasticSearch' status='OK'/>
                    <SkillsItem name='React Native' status='Prox'/>
                    <SkillsItem name='Typescript' status='OK'/>
                </div>
            </div>
        </Frame>
  )
}

const Title = () => {
    const { theme } = useTheme();
    const { lang } = useLanguage();

    return(
        <h1 className={`${theme.primary.textColor} text-sm`}>
            { lang.skills.title }
        </h1>
    )
}

function SkillsItem({name, status} : {name: string, status: string}) {
    return (
        <div>
            <h1 className='text-sm flex'>[<span className={status === 'OK' ? 'text-green-500' : 'text-yellow-500'}>{status}</span>]<span className='truncate'>{name}</span></h1>
        </div>
    )
}
