import { useState, useEffect, useCallback, Fragment } from "react";
import { useLanguage } from "../../../../Context/LanguageContext";
import { useTheme } from "../../../../Context/Themes";

import { themesMap } from "../../../../Utils/Settings";

enum Themes {
  nervessStyle = 'nervessStyle',
  retroStyle = 'retroStyle',
  christmasStyle = 'christmasStyle',
  oldMacStyle = 'oldMacStyle',
  halloweenStyle = 'halloweenStyle'
}

const savingThemeToLocalStorage = (themeName : string) => {
  localStorage.setItem('theme', themeName)
}

type ThemeKeys = 'nervessStyle' | 'retroStyle' | 'christmasStyle' | 'oldMacStyle' | 'halloweenStyle';

interface ButtonStates {
  [key: string]: boolean,
}

const defaultTheme = Themes.oldMacStyle as ThemeKeys;
const defaultButtonStates = {
  'nervessStyle': false,
  'retroStyle': false,
  'christmasStyle': false,
  'oldMacStyle': false,
  'halloweenStyle': false,
}

const changeScrollBarStyle = (theme: string) => {
  const themes = [Themes.nervessStyle, Themes.retroStyle, Themes.christmasStyle, Themes.oldMacStyle, Themes.halloweenStyle];
  
  themes.forEach(t => document.body.classList.remove(t));
  document.body.classList.add(theme);
}

export const ThemesSelector = () => {
    const { lang } = useLanguage();
    const { theme, setTheme } = useTheme();
  
    const [buttonStates, setButtonStates] = useState<ButtonStates>(defaultButtonStates);
    
    const changeTheme = useCallback((themeName : string) => {
      const themeKey = themeName as ThemeKeys;
      const selectedTheme = themesMap[themeKey] || themesMap[defaultTheme]
      setTheme(selectedTheme);
      if (themesMap[themeKey]) {
        savingThemeToLocalStorage(themeName);
      }
    }, [setTheme])
    
    const getThemeFromLocalStorage = () => {
      const theme = localStorage.getItem('theme')
      if (theme) {
        changeTheme(theme)
      } else {
        savingThemeToLocalStorage(defaultTheme)
      }
    }
    
    useEffect(getThemeFromLocalStorage, [changeTheme])
    
    useEffect(() => {
      const date = new Date()
      if (date.getMonth() >= 10 || date.getMonth() === 0) {
        setTheme(themesMap.christmasStyle)
      }
    }, [setTheme])
  
    const changeButtonState = useCallback((theme: string) => {
      const bsTheme = theme as ThemeKeys;
      setButtonStates(() => {
        const newValue = { ...defaultButtonStates };
        newValue[bsTheme] = true;
        return newValue
      })
    }, [])

    useEffect (() => {
      changeScrollBarStyle(theme.misc.style);
      changeButtonState(theme.misc.style);
    }, [theme, changeButtonState])

    const handleSwitch = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      changeTheme(e.currentTarget.getAttribute('data-theme') as string)
    };
  
    return(
      <Fragment>
        <h1 className={`text-center text-xl font-extrabold ${theme.secondary.textColor}`}>{ lang.settings.themes }</h1>
        <div className='w-full h-full flex flex-wrap gap-4 place-content-center'>
          <Interruptor state={buttonStates.nervessStyle} onClick={handleSwitch} themeId={Themes.nervessStyle} title="Nervesscat 🟪" color="🟪"/>
          <Interruptor state={buttonStates.retroStyle} onClick={handleSwitch} themeId={Themes.retroStyle} title="Retro 🟩" color="🟩"/>
          {/* <Interruptor state={buttonStates.halloweenStyle} onClick={handleSwitch} themeId={Themes.halloweenStyle} title="Halloween 🎃" color="🎃"/> */}
          <Interruptor state={buttonStates.oldMacStyle} onClick={handleSwitch} themeId={Themes.oldMacStyle} title="Old Mac 🍎" color="⬛"/>
        </div>
      </Fragment>
    )
  }

interface InterruptorType {
  state: boolean,
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  themeId: string
  title: string;
  color: string;
}

const Interruptor = ({ state, onClick, themeId, title, color } : InterruptorType) => {
  const { theme } = useTheme();

  return(
    <div className="flex flex-col items-center gap-2">
      <div className={`w-2 h-2 transition-colors ${ state ? 'bg-green-400' : 'bg-red-500' } rounded-full`}/>
      <div className={`w-8 h-10 rounded-md ${ theme.secondary.bgColor }`} onClick={onClick} title={title} data-theme={themeId}>
        <div className="w-full h-full flex flex-col gap-1 p-1">
          <div className={`w-full h-1/2 transition-transform ${ theme.primary.bgColor } ${ state ? ''  : 'translate-y-full' } rounded-md`}>
          </div>
        </div>
      </div>
      <p className="text-sm">{ color }</p>
    </div>
  )
}