import { useEffect, useRef } from "react"

export default function NewIcon(){
    const nRef = useRef<HTMLHeadingElement | null>(null)
    const eRef = useRef<HTMLHeadingElement | null>(null)
    const wRef = useRef<HTMLHeadingElement | null>(null)

    useEffect(() => {
        const rainbowColors = ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'purple']
        let i = 0
        const interval = setInterval(() => {
            if (!nRef.current) return
            if (!eRef.current) return
            if (!wRef.current) return
            
            nRef.current.style.color = rainbowColors[i%3]
            eRef.current.style.color = rainbowColors[i%3 + 1]
            wRef.current.style.color = rainbowColors[i%3 + 2]

            nRef.current.style.top = `${Math.sin(i) * 10}px`
            eRef.current.style.top = `${Math.sin(i + 1) * 10}px`
            wRef.current.style.top = `${Math.sin(i + 2) * 10}px`

            i++
        }, 100)
        return () => clearInterval(interval)
    }, [])

    return (
        <div className='absolute -top-6 z-20'>
            <div className='relative flex'>
                <h1 className='absolute left-0' ref={nRef}>N</h1>
                <h1 className='absolute left-6' ref={eRef}>E</h1>
                <h1 className='absolute left-12' ref={wRef}>W</h1>
            </div>
        </div>
    )
}