import { useEffect, useRef, useCallback } from 'react'

import { useTheme } from '../../Context/Themes'

import { Make } from '../../Assets/TS/CanvasThemes'
import { CanvasManager } from '../../Assets/TS/CanvasManager'

export default function BackgroundCanvas() {
    const { theme } = useTheme();
    const canvasRef = useRef(null);
    const canvasTheme = useRef<CanvasManager | null>(null);

    const updateBackground = useCallback(() => {
        if (!canvasRef.current) return null;
        let canvas = canvasRef.current;
        canvasTheme.current = Make(theme.misc.style, canvas);
        canvasTheme.current.init()
    }, [theme])

    useEffect(() => {
        updateBackground()
        return () => {
            if (canvasTheme.current) canvasTheme.current.cleanup()
        }
    }, [updateBackground])

    return (
        <div className="h-screen w-screen fixed -z-10">
            <canvas ref={canvasRef} className="h-screen w-screen"></canvas>
        </div>
    )
}
