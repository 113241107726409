import { useState, useEffect } from 'react'
import CatImage from '../../Assets/Img/Misc/404_error_image.png'

import { useTheme } from '../../Context/Themes'
import { useLanguage } from '../../Context/LanguageContext'

export default function Error() {
    const { theme } = useTheme();
    const { lang } = useLanguage();

    const [seconds, setSeconds] = useState(5)

    useEffect(() => {
        if(seconds > 0){
            const timerId = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        } else {
            window.location.href = "/";
        }
    }, [seconds])

    return (
        <div className='w-full h-full'>
            <div className='flex justify-center p-8'>
                <div className={`flex flex-col items-center p-8 rounded-2xl border-2 ${theme.primary.borderColor} ${theme.misc.hoverBg}`}>
                    <img src={CatImage} alt='A cat holding a 404 error cartel, pixel art' className='w-64 h-64'></img>
                    <h1 className='text-center'><span className='text-red-800'>404</span> { lang.errorRouter.title }</h1>
                    <p><a href="/" rel="noopener noreferrer" className='text-red-800'>{ lang.errorRouter.description[0] }</a> { lang.errorRouter.description[1] } { seconds } { lang.errorRouter.description[2] }</p>
                </div>
            </div>
        </div>
    )
}
