import { useTheme } from '../../Context/Themes'
import { useLanguage } from '../../Context/LanguageContext'

import ConnIcon from '../../Assets/Img/Misc/NervessError.png'

export default function ConnFailed(props : { isVertical : boolean }) {
  const { theme } = useTheme();
  const { lang } = useLanguage();

  return (
    <div className={`w-full h-full flex ${props.isVertical ? 'flex-col' : 'sm:flex-row flex-col'} ${theme.secondary.bgColor} border-4 ${theme.primary.borderColor} rounded-md p-4`}>
        <img src={ ConnIcon } alt='internet globe icon, pixel art icon' className={`h-44 w-44 self-center ${ theme.misc.style === 'oldMacStyle' ? 'saturate-0' : ''}`}/>
        <div className={`w-full flex flex-col self-center ${theme.secondary.textColor} ${props.isVertical ? '' : 'lg:px-32' }`}>
            <p className='text-xl text-center text-red-600'>{ lang.connFailed.title }</p>
            <p className='text-center'>{ lang.connFailed.description }</p>
            <p onClick={() => { window.open('https://ko-fi.com/nervesscat') }} className={`text-center text-green-700 p-2 border-4 ${ theme.primary.borderColor } rounded-md mt-2 cursor-pointer`}>{ lang.connFailed.support }</p>
        </div>
    </div>
  )
}