import { Queue } from "../DataStructures/Queue";

export type Point = {
	x: number;
	y: number;
};

type CallbackType = (...args: any[]) => void;

export class Glyph {
	__start_point: Point;
	__end_point: Point;
	__middle: Point;
	__ctx: CanvasRenderingContext2D;
	public width: number;
	public height: number;

	constructor(ctx: CanvasRenderingContext2D) {
		this.__start_point = {
			x: 0,
			y: 0,
		};
		this.__end_point = {
			x: 0,
			y: 0,
		};
		this.__middle = {
			x: 0,
			y: 0,
		};
		this.__ctx = ctx;
		this.width = 0;
		this.height = 0;
	}

	setMiddle(middle: Point, sizeX: number, sizeY: number = sizeX) {
		this.__middle = middle;
		this.__start_point = {
			x: middle.x - sizeX / 2,
			y: middle.y - sizeY / 2,
		};
		this.__end_point = {
			x: middle.x + sizeX / 2,
			y: middle.y + sizeY / 2,
		};
		this.width = sizeX;
		this.height = sizeY;
	}

	setPoints(start: Point, end: Point) {
		this.__start_point = {
			x: start.x,
			y: start.y,
		};
		this.__end_point = {
			x: end.x,
			y: end.y,
		};
		this.width = this.__end_point.x - this.__start_point.x;
		this.height = this.__end_point.y - this.__start_point.y;
	}

	mouseUp(e: MouseEvent) {}

	draw() {}

	defaultAction: CallbackType = () => {};

	setDefaultAction(callback: CallbackType) {
		this.defaultAction = callback;
	}
}

export class VolumeSlider extends Glyph {
	__color: string;
	__audio: HTMLAudioElement;

	constructor(ctx: CanvasRenderingContext2D, audio: HTMLAudioElement, color: string){
		super(ctx);
		this.__audio = audio;
		this.__audio.volume = 0.8
		this.__color = color;
	}

	override draw(){
		this.__ctx.save();
		this.drawBackgroundFill();
		this.drawBackgroundSelection();
		this.__ctx.restore();
	}

	drawBackgroundFill(){
		this.__ctx.fillStyle = "white";
		this.__ctx.fillRect(
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height
		)
	}

	drawBackgroundSelection(){
		let actualVolume = this.__audio.volume
		this.__ctx.fillStyle = this.__color;
		this.__ctx.fillRect(
			this.__start_point.x,
			this.__end_point.y,
			this.width,
			- this.height * actualVolume
		)
	}

	mouseUp(e: MouseEvent) {
		let cursorPosition = (e.offsetY - this.__end_point.y) / - this.height;
		this.__audio.volume = cursorPosition;
	}
}

export class PlayButton extends Glyph {
	__state: boolean;

	constructor(ctx: CanvasRenderingContext2D) {
		super(ctx);
		this.__state = false;
	}

	override draw(): void {
		if (!this.__ctx) return;
		if (this.__state) {
			this.drawPauseButton();
		} else {
			this.drawPlayButton();
		}
	}

	drawPlayButton() {
		this.__ctx.fillStyle = "white";
		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__start_point.x, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x, this.__end_point.y);
		this.__ctx.lineTo(
			this.__end_point.x,
			(this.__start_point.y + this.__end_point.y) / 2
		);
		this.__ctx.fill();
	}

	drawPauseButton() {
		this.__ctx.fillStyle = "white";
		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__start_point.x, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x, this.__end_point.y);
		this.__ctx.lineTo(this.__start_point.x + 10, this.__end_point.y);
		this.__ctx.lineTo(this.__start_point.x + 10, this.__start_point.y);

		this.__ctx.moveTo(this.__start_point.x + 20, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x + 20, this.__end_point.y);
		this.__ctx.lineTo(this.__start_point.x + 30, this.__end_point.y);
		this.__ctx.lineTo(this.__start_point.x + 30, this.__start_point.y);
		this.__ctx.fill();
	}

	changeState(newState: boolean) {
		this.__state = newState;
	}

	override mouseUp(e: MouseEvent): void {
		this.__state = !this.__state;
		this.defaultAction(this.__state);
	}
}

export class NextButton extends Glyph {
	__audio: HTMLAudioElement;
	__playlist: Array<any>;
	__currentSongIndex: number;

	constructor(
		ctx: CanvasRenderingContext2D,
		audio: HTMLAudioElement,
		playlist: Array<any>
	) {
		super(ctx);
		this.__audio = audio;
		this.__playlist = playlist;
		this.__currentSongIndex = 0;
	}

	override draw(): void {
		if (!this.__ctx) return;
		this.__ctx.fillStyle = "white";
		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__start_point.x, this.__start_point.y);
		this.__ctx.lineTo(this.__end_point.x, this.__middle.y);
		this.__ctx.lineTo(this.__start_point.x, this.__end_point.y);
		this.__ctx.fill();

		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__start_point.x + 10, this.__start_point.y);
		this.__ctx.lineTo(this.__end_point.x + 10, this.__middle.y);
		this.__ctx.lineTo(this.__start_point.x + 10, this.__end_point.y);
		this.__ctx.fill();
	}

	override mouseUp(e: MouseEvent): void {
		this.defaultAction();
	}
}

export class PreviousButton extends Glyph {
	__audio: HTMLAudioElement;
	__playlist: Array<any>;
	__currentSongIndex: number;

	constructor(
		ctx: CanvasRenderingContext2D,
		audio: HTMLAudioElement,
		playlist: Array<any>
	) {
		super(ctx);
		this.__audio = audio;
		this.__playlist = playlist;
		this.__currentSongIndex = 0;
	}

	override draw(): void {
		if (!this.__ctx) return;
		this.__ctx.fillStyle = "white";
		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__end_point.x, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x, this.__middle.y);
		this.__ctx.lineTo(this.__end_point.x, this.__end_point.y);
		this.__ctx.fill();

		this.__ctx.beginPath();
		this.__ctx.moveTo(this.__end_point.x - 10, this.__start_point.y);
		this.__ctx.lineTo(this.__start_point.x - 10, this.__middle.y);
		this.__ctx.lineTo(this.__end_point.x - 10, this.__end_point.y);
		this.__ctx.fill();
	}

	override mouseUp(e: MouseEvent): void {
		this.defaultAction();
	}
}

export class MusicCover extends Glyph {
	private musicCover: HTMLImageElement;
	private turningAngle: number;
	private isTurning: boolean;

	constructor(ctx: CanvasRenderingContext2D, img: any) {
		super(ctx);
		this.musicCover = new Image();
		this.musicCover.src = img;
		this.turningAngle = 0;
		this.isTurning = false;
	}

	setCover(img: HTMLImageElement) {
		this.musicCover = img;
	}

	draw() {
		this.__ctx.save();

		this.__ctx.translate(this.__middle.x, this.__middle.y);
		this.__ctx.rotate((this.turningAngle * Math.PI) / 180);
		this.__ctx.translate(-this.__middle.x, -this.__middle.y);

		this.__ctx.beginPath();
		this.__ctx.arc(
			this.__middle.x,
			this.__middle.y,
			this.width / 2,
			0,
			Math.PI * 2,
			true
		);
		this.__ctx.closePath();
		this.__ctx.clip();

		this.__ctx.drawImage(
			this.musicCover,
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height
		);

		this.__ctx.restore();

		this.__ctx.strokeStyle = "white";
		this.__ctx.lineWidth = 4;
		this.__ctx.stroke();

		if (this.isTurning) this.turningAngle += 1;
	}

	resume() {
		this.isTurning = true;
	}

	pause() {
		this.isTurning = false;
	}
}

export class Background extends Glyph {
	image: HTMLImageElement;

	constructor(
		ctx: CanvasRenderingContext2D,
		width: number,
		height: number,
		bg: any
	) {
		super(ctx);
		this.__start_point = {
			x: 0,
			y: 0,
		};
		this.__end_point = {
			x: width,
			y: height,
		};
		this.width = width;
		this.height = height;
		this.image = new Image();
		this.image.src = bg;
		this.image.onload = () => {
			this.draw();
		};
	}

	override draw(): void {
		if (!this.__ctx) return;
		const canvasAspect = this.width / this.height;
		const imageAspect = this.image.width / this.image.height;
		let drawWidth, drawHeight, offsetX, offsetY;

		if (canvasAspect > imageAspect) {
			drawWidth = this.width;
			drawHeight = this.width / imageAspect;
			offsetX = 0;
			offsetY = (this.height - drawHeight) / 2;
		} else {
			drawWidth = this.height * imageAspect;
			drawHeight = this.height;
			offsetX = (this.width - drawWidth) / 2;
			offsetY = 0;
		}

		this.__ctx.drawImage(this.image, offsetX, offsetY, drawWidth, drawHeight);
	}

	changeBackground(src: any): void {
		this.image.src = src;
	}
}

export class TitleSong extends Glyph {
	__text: string;
	__size: number;
	constructor(ctx: CanvasRenderingContext2D, text: string, size: number = 18) {
		super(ctx);
		this.__text = text;
		this.__size = size;
	}

	override draw(): void {
		if (!this.__ctx) return;

		this.__ctx.font = `${this.__size}px msgothic`;
		this.__ctx.fillStyle = "white";
		this.__ctx.textAlign = "center";
		this.__ctx.textBaseline = "middle";

		let text = `${this.__text}`;
		this.__ctx.fillText(text, this.__start_point.x, this.__start_point.y);
	}
}

export class AudioSpectrumViewer extends Glyph {
	private __audioContext: AudioContext;
	private __dataArray: Uint8Array;
	private __queueSlices: Queue<number>;
	private __sliceWidth: number;
	private __totalSpace: number;
	private __margin: number;
	private __limit: number;
	private __source: any = null;
	private __analyser: AnalyserNode;

	constructor(
		ctx: CanvasRenderingContext2D,
		audioContext: AudioContext,
		mediaElementSource: MediaElementAudioSourceNode
	) {
		super(ctx);
		this.__limit = 20;
		this.__margin = 10;
		this.__totalSpace = this.width / this.__limit;
		this.__sliceWidth = this.__totalSpace - this.__margin;
		this.__queueSlices = new Queue<number>(this.__limit);
		this.__audioContext = audioContext;
		this.__analyser = this.__audioContext.createAnalyser();
		this.__source = mediaElementSource;
		this.__source.connect(this.__analyser);
		this.__dataArray = new Uint8Array(this.__analyser.frequencyBinCount);
	}

	override setMiddle(middle: Point, sizeX: number, sizeY?: number): void {
		super.setMiddle(middle, sizeX, sizeY);
		this.__limit = 20;
		this.__margin = 5;
		this.__totalSpace = this.width / this.__limit;
		this.__sliceWidth = this.__totalSpace - this.__margin;
	}

	getDecibels(): number {
		this.__analyser.getByteFrequencyData(this.__dataArray);
		let sum = 0;
		for (let i = 0; i < this.__dataArray.length; i++) {
			sum += this.__dataArray[i];
		}
		const average = sum / this.__dataArray.length;
		const decibels = 20 * Math.log10(average / 255);
		return decibels;
	}

	draw(): void {
		const decibels = this.getDecibels();
		this.__queueSlices.enqueue(decibels);

		let i = 1;
		for (const db of this.__queueSlices) {
			this.drawSlice(i, db);
			i += 1;
		}
	}

	drawSlice(position: number, decibels: number): void {
		let [x, y] = this.getCoordinatesFromPosition(position);
		const amplifier = -350;
		const height = amplifier / decibels;
		y = y - height / 2;
		drawRoundedRect(this.__ctx, x, y, this.__sliceWidth, height, 2, "white");
	}

	getCoordinatesFromPosition(position: number): [number, number] {
		let x = this.__end_point.x - this.__totalSpace * position;
		let y = this.__middle.y;
		return [x, y];
	}
}

function drawRoundedRect(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number,
	fillStyle?: string,
	strokeStyle?: string
): void {
	ctx.beginPath();
	ctx.moveTo(x + radius, y);
	ctx.arcTo(x + width, y, x + width, y + height, radius);
	ctx.arcTo(x + width, y + height, x, y + height, radius);
	ctx.arcTo(x, y + height, x, y, radius);
	ctx.arcTo(x, y, x + width, y, radius);
	ctx.closePath();

	if (fillStyle) {
		ctx.fillStyle = fillStyle;
		ctx.fill();
	}

	if (strokeStyle) {
		ctx.strokeStyle = strokeStyle;
		ctx.stroke();
	}
}

export class PlaylistSlider extends Glyph {
	private __audio: HTMLAudioElement;
	private __color: string;

	constructor(
		ctx: CanvasRenderingContext2D,
		audio: HTMLAudioElement,
		color: string
	) {
		super(ctx);
		this.__color = color;
		this.__audio = audio;
	}

	draw() {
		this.__ctx.save();
		this.drawBackgroundFill();
		this.drawPlayingState();
		this.__ctx.restore();
	}

	drawBackgroundFill() {
		this.__ctx.fillStyle = "white";
		this.__ctx.fillRect(
			this.__start_point.x,
			this.__start_point.y,
			this.width,
			this.height
		);
	}

	drawPlayingState() {
		let actualPosition = this.__audio.currentTime / this.__audio.duration;
		this.__ctx.fillStyle = this.__color;
		this.__ctx.fillRect(
			this.__start_point.x - 1,
			this.__start_point.y - 1,
			this.width * actualPosition + 1,
			this.height + 2
		);
	}

	changeColor(color: string): void {
		this.__color = color;
	}

	mouseUp(e: MouseEvent): void {
		let cursorPosition = (e.offsetX - this.__start_point.x) / this.width;
		let cursorPositionToSeconds = this.__audio.duration * cursorPosition;
		this.__audio.currentTime = cursorPositionToSeconds;
	}
}
