import { useState } from 'react'
import CMDLogo from './CMDLogo'
import CMDLoading from './CMDLoading'
import CMDPoints from './CMDPoints'
import { useTheme } from '../../Context/Themes'

import '../../Assets/Styles/Logo.scss'

function HeaderScreen({bodyRef} : {bodyRef : React.RefObject<HTMLDivElement>}) {
  const [finished, setFinished] = useState(false)
  const [lastComponent, setLastComponent] = useState(false)
  const { theme } = useTheme();

  function pressArrow(){
    if (!bodyRef.current) return
    bodyRef.current.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div className='h-full'>
        <div className='h-screen p-2 pb-8 sm:p-8 xl:p-16 relative'>
            <div className={`h-full justify-center flex sm:border-2 ${theme.primary.borderColor} bg-zinc-950 rounded-3xl overflow-clip`}>
              { !finished ? <CMDLoading setFinished={setFinished}/> : !lastComponent ? <CMDLogo setLastComponent={setLastComponent}/> : <CMDPoints/> }        
              <button onClick={pressArrow} className={`w-16 h-16 z-10 flex justify-center rounded-full self-end absolute mb-8 ${theme.primary.bgColor} text-6xl border-4 ${theme.primary.borderColor} animate-bounce ${theme.primary.textColor}`}>
                <span className='relative text-2xl self-center'>
                ▼
                </span>
              </button>
            </div>
        </div>
    </div>
  )
}

export default HeaderScreen