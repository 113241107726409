import { CanvasManager } from "../CanvasManager";
import { Glyph } from "./Glyphs";
import { Stack } from "../DataStructures/Stack";

export class Window extends CanvasManager {
	protected __glyphs: Glyph[];
	protected static __mouseEvents: MouseEventsWindow;

	constructor(canvas: HTMLCanvasElement) {
		super(canvas);
		this.__glyphs = [];
		if (!Window.__mouseEvents) {
			Window.__mouseEvents = new MouseEventsWindow(this.canvas);
		}
	}

	addElementsToResize(): void {
		this.init();
	}

	init() {
		const draw = () => {
			this.__glyphs.forEach((glyph) => {
				glyph.draw();
			});
			this.id = requestAnimationFrame(draw);
		};
		draw();
	}

	addBackground(glyph: Glyph) {
		this.__glyphs.unshift(glyph);
	}

	addGlyph(glyph: Glyph) {
		this.__glyphs.push(glyph);
		Window.__mouseEvents.addGlyph(glyph);
	}

	override cleanup() {
		super.cleanup();
		Window.__mouseEvents.cleanup();
	}
}

class MouseEventsWindow {
	__eGlyphs: Stack<Glyph>;
	__canvas: HTMLCanvasElement;

	constructor(canvas: HTMLCanvasElement) {
		this.__eGlyphs = new Stack<Glyph>();
		this.__canvas = canvas;
		this.__canvas.addEventListener("click", this.__mouseEvent.bind(this));
	}

	__searchGlyph(e: MouseEvent) {
		for (let gl of this.__eGlyphs) {
			if (
				gl.__start_point.x <= e.offsetX &&
				gl.__end_point.x >= e.offsetX &&
				gl.__start_point.y <= e.offsetY &&
				gl.__end_point.y >= e.offsetY
			) {
				gl.mouseUp(e);
				break;
			}
		}
	}

	__mouseEvent(e: MouseEvent) {
		this.__searchGlyph(e);
	}

	cleanup() {
		this.__eGlyphs = new Stack<Glyph>();
		this.__canvas.removeEventListener("click", this.__mouseEvent.bind(this));
	}

	addGlyph(gl: Glyph) {
		this.__eGlyphs.append(gl);
	}
}
