import { useEffect, useRef } from 'react'

import { useTheme } from '../../Context/Themes'

import { CMDPointsAction } from '../../Assets/TS/CMDPoints'

export default function CMDPoints() {
    const { theme } = useTheme();
    const canvasRef = useRef(null)
    
    useEffect(() => {
        if (!canvasRef.current) return
        let canvasAction = new CMDPointsAction(theme.misc.points, canvasRef.current)
        canvasAction.init()
        return () => {
            canvasAction.stop()
        }
    }, [theme])

    return (
        <div className='w-full h-full flex flex-col justify-start'>
            <canvas className='w-full h-full' ref={canvasRef}/>
        </div>
  )
}
