import ChristmasBG from "../Img/Background/ChristmasBackground.webp";
import HalloweenBG from "../Img/Background/background_halloween.png";

import { LCG } from "./LGC";
import { CanvasManager } from "./CanvasManager";

export function Make(style: string, canvas: HTMLCanvasElement): CanvasManager {
	if (style === "christmasStyle") {
		return new ChristmasSnow(canvas);
	} else if (style === "retroStyle") {
		return new HackingTheme(canvas);
	} else if (style === "oldMacStyle") {
		return new OldMacTheme(canvas);
	} else if (style === "halloweenStyle") {
		return new HalloweenTheme(canvas);
	}
	return new NervessTheme(canvas);
}

class HackingTheme extends CanvasManager {
	drops: number[];
	horizontalSeparation: number;

	constructor(canvas: HTMLCanvasElement) {
		super(canvas);
		this.drops = [];
		this.horizontalSeparation = 20;
	}

	addElementsToResize() {
		this.drops = this.calculateColumns();
	}

	override init() {
		// Pseudo random number generator - For performance reasons
		const lcg = new LCG(123456789);

		const draw = () => {
			if (!this.ctx) return;
			this.ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
			this.ctx.fillRect(0, 0, this.width, this.height);
			this.ctx.fillStyle = "#0F0";
			this.ctx.font = "12px arial";

			for (let i = 0; i < this.drops.length; i++) {
				const text = String.fromCharCode(lcg.next());
				this.ctx.fillText(
					text,
					i * this.horizontalSeparation,
					this.drops[i] * 12
				);
				if (this.drops[i] * 10 > this.height && Math.random() > 0.975) {
					this.drops[i] = 0;
				}
				this.drops[i]++;
			}
			this.id = requestAnimationFrame(draw);
		};

		draw();
	}

	calculateColumns() {
		let columns = Math.floor(this.width / this.horizontalSeparation);
		let drops: number[] = [];
		for (let x = 0; x < columns; x++) {
			drops[x] = 0;
		}
		return drops;
	}
}

class Point {
	x: number;
	y: number;

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}
}

class Line {
	start: Point;
	end: Point;

	constructor(start: Point, end: Point) {
		this.start = start;
		this.end = end;
	}
}

class NervessTheme extends CanvasManager {
	horizontalLines: Line[];
	verticalLines: Line[];
	space: number;
	horizon: number;
	topHorizontalLines: Line[];

	constructor(canvas: HTMLCanvasElement) {
		super(canvas);
		this.horizontalLines = [];
		this.verticalLines = [];
		this.topHorizontalLines = [];
		this.horizon = this.height / 2;
		this.space = 50;
	}

	override init() {
		this.createLines();
		const draw = () => {
			this.drawPurpleBackground();
			this.moveTopLines(this.horizontalLines, 2);

			this.drawLines(this.topHorizontalLines, 4);
			this.drawLines(this.horizontalLines, 3);
			this.drawLines(this.verticalLines, 2);

			this.id = requestAnimationFrame(draw);
		};
		draw();
	}

	addElementsToResize() {
		this.horizon = this.height / 2;
		this.createLines();
	}

	createLines() {
		this.topHorizontalLines = this.createHorizonalLines(10, this.horizon);
		this.horizontalLines = this.createHorizonalLines(9, this.height);
		this.verticalLines = this.createVerticalLines(10 * 4);
	}

	drawPurpleBackground() {
		if (!this.ctx) return;
		this.ctx.fillStyle = "#1D001C";
		this.ctx.fillRect(0, 0, this.width, this.height);
	}

	moveTopLines(lines: Line[], space: number) {
		lines.forEach((line) => {
			line.start.y -= space;
			line.end.y -= space;
			if (line.start.y <= this.horizon) {
				line.start.y = this.height;
				line.end.y = this.height;
			}
		});
	}

	createHorizonalLines(num: number, height: number) {
		let lines: Line[] = [];
		for (let i = 0; i <= num; i++) {
			let start: Point = new Point(0, height - i * this.space);
			let end: Point = new Point(this.width, height - i * this.space);

			lines.push(new Line(start, end));
		}
		return lines;
	}

	createVerticalLines(num: number) {
		let lines: Line[] = [];
		let spaceDeformed = this.space * 2;
		let x = -spaceDeformed * Math.ceil(num / 4);
		for (let i = 0; i <= num; i++) {
			let start = {
				x: x,
				y: this.height,
			};
			let endx = i * this.space;
			x += spaceDeformed;
			let end = {
				x: endx,
				y: this.horizon,
			};
			lines.push(new Line(start, end));
		}
		return lines;
	}

	drawLines(lines: Line[], thikness: number = 2) {
		if (!this.ctx) return;
		this.ctx.strokeStyle = "#800080";
		this.ctx.lineWidth = thikness;
		lines.forEach((line: Line) => {
			if (!this.ctx) return;
			this.ctx.beginPath();
			this.ctx.moveTo(line.start.x, line.start.y);
			this.ctx.lineTo(line.end.x, line.end.y);
			this.ctx.stroke();
		});
	}
}

class ChristmasSnow extends CanvasManager {
	snowflakes: Snowflake[];
	bgImage: HTMLImageElement;

	constructor(canvas: HTMLCanvasElement) {
		super(canvas);
		this.snowflakes = [];
		this.bgImage = new Image();
		this.bgImage.src = ChristmasBG;
		this.bgImage.onload = () => {
			if (!this.ctx) return;
			this.ctx.drawImage(this.bgImage, 0, 0);
			this.init();
		};
	}

	init(): void {
		this.resize();
		window.addEventListener("resize", this.resize.bind(this));
		this.createSnowflakes();
		this.animate();
	}

	createSnowflakes(): void {
		let snowflakeCount = this.width / 4;
		for (let i = 0; i < snowflakeCount; i++) {
			this.snowflakes.push(new Snowflake(this));
		}
	}

	animate(): void {
		if (!this.ctx) return;
		this.ctx.drawImage(this.bgImage, 0, 0);
		this.snowflakes.forEach((snowflake) => snowflake.update());
		requestAnimationFrame(this.animate.bind(this));
	}

	addElementsToResize(): void {
		if (!this.ctx) return;
		this.ctx.drawImage(this.bgImage, 0, 0);
	}
}

class Snowflake {
	parent: ChristmasSnow;
	x: number;
	y: number;
	radius: number;
	speed: number;
	wind: number;
	opacity: number;

	constructor(parent: ChristmasSnow) {
		this.parent = parent;
		this.x = Math.random() * parent.width;
		this.y = Math.random() * -parent.height;
		this.radius = Math.random() * 5;
		this.speed = Math.random() * 1 + 0.5;
		this.wind = Math.random() * 0.5 - 0.25;
		this.opacity = Math.random() * 0.5 + 0.3;
	}

	update() {
		this.x += this.wind;
		this.y += this.speed;
		if (this.y > this.parent.height) {
			this.y = -this.radius;
			this.x = Math.random() * this.parent.width;
		}
		if (!this.parent.ctx) return;
		this.parent.ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
		this.parent.ctx.beginPath();
		this.parent.ctx.fillRect(this.x, this.y, this.radius, this.radius);
		this.parent.ctx.fill();
	}
}

class OldMacTheme extends CanvasManager {
	__verticalLines!: Line[];
	__horizontalLines!: Line[];
	__TOTAL_LINES!: number;
	__SEPARATION!: number;

	constructor(canvas: HTMLCanvasElement) {
		super(canvas);
		this.__SEPARATION = 50;
		this.setUpVariables();
	}

	setUpVariables(): void {
		this.__verticalLines = [];
		this.__horizontalLines = [];
		if (this.width >= this.height) {
			this.__TOTAL_LINES = this.width / this.__SEPARATION;
		} else {
			this.__TOTAL_LINES = this.height / this.__SEPARATION;
		}
	}

	override addElementsToResize(): void {
		this.setUpVariables();
		this.createVerticalLines();
		this.createHorizontalLines();
	}

	createVerticalLines() {
		for (let i = 0; i < this.__TOTAL_LINES; i++) {
			if (i * this.__SEPARATION > this.width) break;
			const startP = new Point(i * this.__SEPARATION, 0);
			const endP = new Point(i * this.__SEPARATION, this.height);
			const line = new Line(startP, endP);
			this.__verticalLines.push(line);
		}
	}

	createHorizontalLines() {
		for (let i = 0; i < this.__TOTAL_LINES; i++) {
			if (i * this.__SEPARATION > this.height) break;
			const startP = new Point(0, i * this.__SEPARATION);
			const endP = new Point(this.width, i * this.__SEPARATION);
			const line = new Line(startP, endP);
			this.__horizontalLines.push(line);
		}
	}

	drawHorizontalLines() {
		this.__horizontalLines.forEach((line: Line) => {
			if (!this.ctx) return;
			this.drawLine(line);
			this.animateHorizontalLines(line);
		});
	}

	animateHorizontalLines(line: Line) {
		line.start.y += 1;
		line.end.y += 1;
		if (line.start.y > this.height + this.__SEPARATION) {
			line.start.y = 0;
			line.end.y = 0;
		}
	}

	drawVerticalLines() {
		this.__verticalLines.forEach((line: Line) => {
			this.drawLine(line);
			this.animateVerticalLines(line);
		});
	}

	animateVerticalLines(line: Line) {
		line.end.x += 1;
		line.start.x += 1;
		if (line.start.x > this.width + this.__SEPARATION) {
			line.start.x = 0;
			line.end.x = 0;
		}
	}

	drawLine(line: Line) {
		if (!this.ctx) return;
		this.ctx.strokeStyle = "rgba(220, 38, 38, 0.3)";
		this.ctx.lineWidth = 2;
		this.ctx.beginPath();
		this.ctx.moveTo(line.start.x, line.start.y);
		this.ctx.lineTo(line.end.x, line.end.y);
		this.ctx.stroke();
	}

	drawGrid() {
		if (!this.ctx) return;
		this.drawHorizontalLines();
		this.drawVerticalLines();
	}

	override init(): void {
		const draw = () => {
			this.drawBlackBackground();
			this.drawGrid();
			this.id = requestAnimationFrame(draw);
		};
		draw();
	}
}

class HalloweenTheme extends CanvasManager {
	bgImage: HTMLImageElement;

	constructor(canvas: HTMLCanvasElement) {
		super(canvas);
		this.bgImage = new Image();
		this.bgImage.src = HalloweenBG;
		this.bgImage.onload = () => {
			if (!this.ctx) return;
			this.ctx.drawImage(this.bgImage, 0, 0);
		};
	}

	override init() {
		const draw = () => {
			if (!this.ctx) return;
			this.ctx.drawImage(this.bgImage, 0, 0, this.bgImage.width, this.height);
			this.id = requestAnimationFrame(draw);
		};
		draw();
	}
}
