import { ReactNode, useState, createContext, useContext} from "react";

import { LanguageStruct, language } from "../Utils/Settings";

interface LanguageContextType {
    lang: LanguageStruct;
    setLang: (lang: LanguageStruct) => void;
}

export const LanguageContext = createContext<LanguageContextType>(
    {} as LanguageContextType
);

export const LanguageProvider = ({ children } : { children:  ReactNode}) => {
    const [lang, setLang] = useState(language.en);

    return (
        <LanguageContext.Provider value={{ lang, setLang }}>
            { children }
        </LanguageContext.Provider>
    )
}

export const useLanguage = () => {
    const context = useContext(LanguageContext);

    if (!context){
        throw new Error('useLanguage must be within a LanguageProvider')
    }

    return context
}