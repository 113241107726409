import { useContext, ReactNode, useState } from "react";
import { createContext } from "react";

interface LoginContextType {
    login: boolean;
    setLogin: (login: boolean) => void;
}

export const LoginContext = createContext<LoginContextType>(
    {} as LoginContextType
);

export const LoginProvider = ({ children } : { children: ReactNode }) => {
    const [login, setLogin] = useState(false);

    return (
        <LoginContext.Provider value={{login, setLogin}}>
            { children }
        </LoginContext.Provider>
    )
}

export const useLogin = () => {
    const context = useContext(LoginContext);

    if (!context) {
        throw new Error('useLogin must be within LoginProvider');
    }

    return context
}