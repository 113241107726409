import { useState, useEffect, useRef, useMemo } from 'react'
import { MutableRefObject, SyntheticEvent } from 'react'
import { useWindowSize } from 'react-use';

import '../../../Assets/Styles/StatusRainbow.scss'

import { useTheme } from '../../../Context/Themes'
import { useLanguage } from '../../../Context/LanguageContext';

// import twitter from '../../../Assets/Img/SVG/twitter.svg'
import instagram from '../../../Assets/Img/SVG/instagram.svg'
// import deviantart from '../../../Assets/Img/SVG/deviantart.svg'
// import artstation from '../../../Assets/Img/SVG/artstation.svg'

import Hillary1 from '../../../Assets/Img/Art/Hillary/1.jpg'
import Hillary2 from '../../../Assets/Img/Art/Hillary/2.jpg'
import Hillary3 from '../../../Assets/Img/Art/Hillary/3.jpg'

import Sarah1 from '../../../Assets/Img/Art/SarahCastillo/1.jpeg'
import Sarah2 from '../../../Assets/Img/Art/SarahCastillo/2.jpeg'
import Sarah3 from '../../../Assets/Img/Art/SarahCastillo/3.jpeg'

import Nico1 from '../../../Assets/Img/Art/Nico/1.jpg'
import Nico2 from '../../../Assets/Img/Art/Nico/2.jpg'
import Nico3 from '../../../Assets/Img/Art/Nico/3.jpg'
import { Frame } from '../../Another/Frame';

interface ArtistType {
    name: string;
    images: any[];
    icons: SocialType[];
}

interface SocialType {
    name: string;
    icon: any;
    link: string;
}

const artists = [
    {
        name: 'Iason Mink',
        images: [ Hillary1, Hillary2, Hillary3 ],
        icons: [ 
            {
                name: 'Instagram',
                icon: instagram,
                link: 'https://www.instagram.com/iason_mink1/'
            },
        ],
    },
    {
        name: 'Nico',
        images: [ Nico1, Nico2, Nico3 ],
        icons: [ 
            {
                name: 'Instagram',
                icon: instagram,
                link: 'https://www.instagram.com/lucimau.art/'
            },
        ],
    },
    {
        name: 'Sarah Castillo',
        images: [ Sarah1, Sarah2, Sarah3 ],
        icons: [ 
            {
                name: 'Instagram',
                icon: instagram,
                link: 'https://www.instagram.com/srcfroghat/'
            },
        ],
    },
]

function Art() {
    const [selectedArtist, setSelectedArtist] = useState(0)
    const [artist, setArtist] = useState(artists[selectedArtist])

    useEffect(() => {
        setArtist(artists[selectedArtist])
    }, [selectedArtist])

    return (
        <Frame title={<Title artist={artist}/>}>
            <div className={`w-full flex flex-col`}>
                <ArtItem artist={artist} selectedArtist={selectedArtist} setSelectedArtist={setSelectedArtist}/>
                <ArtistSelector artists={artists} selectedArtist={selectedArtist} setSelectedArtist={setSelectedArtist}/>
            </div>
        </Frame>
    )
}

const Title = ({ artist } : { artist: ArtistType }) => {
    const { lang } = useLanguage();

    return(
        <h1 className='w-full text-sm flex justify-center gap-4'><span className='truncate'>{artist.name}</span><span className='rainbow-text'>{ lang.art.title }</span></h1>
    )
}

interface ArtItemType {
    artist: ArtistType
    selectedArtist: number,
    setSelectedArtist: (index: number) => void
}

function ArtItem({artist, selectedArtist, setSelectedArtist}: ArtItemType) {
    const { lang } = useLanguage();

    return (
        <div className='w-full flex flex-col gap-4'>
            <Gallery images={ artist.images } setSelectedArtist={ setSelectedArtist } selectedArtist={ selectedArtist }/>
            <div className='flex flex-col sm:flex-row md:flex-col lg:flex-row gap-4 self-center'>
                <h1 className='text-2xl self-center text-center'>{ lang.art.goCheck }</h1>
                <div className='flex flex-row gap-4 self-center'>
                {
                    artist.icons.map((item, index) => {
                        return(
                            <img className='w-8 h-8 cursor-pointer' src={ item.icon } alt='icon' onClick={() => { window.open(item.link) }} key={index}/>  
                        )
                    })                    
                }
                </div>
            </div>
        </div>
    )
}

interface ArtistSelectorType {
    artists: ArtistType[]
    selectedArtist: number,
    setSelectedArtist: (index: number) => void
}

const ArtistSelector = ({ artists, selectedArtist, setSelectedArtist } : ArtistSelectorType) => {
    const { theme } = useTheme();

    return (
        <div className='flex gap-2 self-center mt-4'>
            {
                artists.map((_, index) => {
                    return (
                        <button className={`w-4 h-2 rounded-full ${ selectedArtist === index ? theme.secondary.bgColor : theme.primary.bgColor }`} onClick={() => { setSelectedArtist(index) }} key={index} title='Change artist' type='button'/>
                    )
                })
            }
        </div>
    )
}

const themeClasses: Record<string, string> = {
    'christmasStyle': 'rainbow-background-red',
    'nervessStyle': 'rainbow-background',
    'retroStyle': 'rainbow-background-green',
    'oldMacStyle': 'rainbow-background-bw',
    'halloweenStyle': 'rainbow-background-orange'
}

function Gallery(props : any) {
    const { theme } = useTheme();
    const refImg1 = useRef(null)
    const refImg2 = useRef(null)
    const refImg3 = useRef(null)

    const refsImg = useMemo(() => [refImg1, refImg2, refImg3], [refImg1, refImg2, refImg3])

    const { width } = useWindowSize()

    const isMobile = width < 768;
    const isTablet = width < 1024;
    const imagesToDisplay = isMobile ? props.images.slice(0, 1) : isTablet ? props.images.slice(0, 2) : props.images.slice(0, 3)

    function changeImage() {
        if (props.selectedArtist < props.images.length - 1) {
            props.setSelectedArtist(props.selectedArtist + 1)
        } else {
            props.setSelectedArtist(0)
        }
    }
    
    function changeImageBack() {
        if (props.selectedArtist > 0) {
            props.setSelectedArtist(props.selectedArtist - 1)
        } else {
            props.setSelectedArtist(props.images.length - 1)
        }
    }

    function checkImagesLoading(){
        refsImg.forEach((item : MutableRefObject<HTMLImageElement | null>) => {
            if (item.current && !item.current.complete) {
                item.current.classList.add('animate-pulse')
            }
        });
    }

    useEffect(checkImagesLoading, [props.images, refsImg]);

    const handleImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.classList.remove('animate-pulse');
    }

    return (
        <div className={`relative w-full border-2 rounded-xl ${ theme.primary.borderColor } ${themeClasses[theme.misc.style]}`}>
            <button className={`px-4 self-center absolute inset-y-0 left-0 z-10`} value='<' onClick={changeImageBack}>
                <h1>{'<'}</h1>
            </button>
            <div className='w-full h-96 flex justify-center gap-2 md:px-0 md:py-2 p-2'>
            {
                imagesToDisplay.map((item : any, index : number) => {
                    return (
                        <img onLoad={handleImageLoad} className={`w-full md:w-2/6 lg:w-3/12 hover:-translate-y-6 ${theme.misc.shadowImage} shadow-2xl relative transition-all rounded-xl md:-left-2 object-cover`} alt='project, note editor' src={ item } ref={refsImg[index]} key={index}/>
                    )
                })
            }
            </div>
            <button className={`px-4 self-center absolute inset-y-0 right-0 z-10`} value='>' onClick={changeImage}>
                <h1>{'>'}</h1>
            </button>
        </div>
    )
}

export default Art;