import AlbumCover from "../../Sounds/Music/DarkWorld/AlbumCover.jpg";
import Song1 from "../../Sounds/Music/DarkWorld/1.mp3";
import Song2 from "../../Sounds/Music/DarkWorld/2.mp3";
import Song3 from "../../Sounds/Music/DarkWorld/3.mp3";
import Song4 from "../../Sounds/Music/DarkWorld/4.mp3";
import Song5 from "../../Sounds/Music/DarkWorld/5.mp3";
import Song6 from "../../Sounds/Music/DarkWorld/6.mp3";
import Song7 from "../../Sounds/Music/DarkWorld/7.mp3";
import Song8 from "../../Sounds/Music/DarkWorld/8.mp3";
import Song9 from "../../Sounds/Music/DarkWorld/9.mp3";
import Song10 from "../../Sounds/Music/DarkWorld/10.mp3";
import Song11 from "../../Sounds/Music/DarkWorld/11.mp3";
import Song12 from "../../Sounds/Music/DarkWorld/12.mp3";
import Song13 from "../../Sounds/Music/DarkWorld/13.mp3";
import Song14 from "../../Sounds/Music/DarkWorld/14.mp3";
import Song15 from "../../Sounds/Music/DarkWorld/15.mp3";
import Song16 from "../../Sounds/Music/DarkWorld/16.mp3";
import Song17 from "../../Sounds/Music/DarkWorld/17.mp3";

export interface Song {
	song: any;
	albumCover: any;
	title: string;
}

export const Playlist = [
	{
		song: Song1,
		albumCover: AlbumCover,
		title: "Intro!! - Nervesscat",
	},
	{
		song: Song2,
		albumCover: AlbumCover,
		title: "Yummy - Nervesscat",
	},
	{
		song: Song3,
		albumCover: AlbumCover,
		title: "Did you have the key? - Nervesscat",
	},
	{
		song: Song4,
		albumCover: AlbumCover,
		title: "Boring House - Nervesscat",
	},
	{
		song: Song5,
		albumCover: AlbumCover,
		title: "Fight Against Little Annoying Monsters - Nervesscat",
	},
	{
		song: Song6,
		albumCover: AlbumCover,
		title: "Magic Elsewhere - Nervesscat",
	},
	{
		song: Song7,
		albumCover: AlbumCover,
		title: "Listen to Me!! - Nervesscat",
	},
	{
		song: Song8,
		albumCover: AlbumCover,
		title: "I Think He's Mad - Nervesscat",
	},
	{
		song: Song9,
		albumCover: AlbumCover,
		title: "You Really Come Back - Nervesscat",
	},
	{
		song: Song10,
		albumCover: AlbumCover,
		title: "I Forget To Buy Something - Nervesscat",
	},
	{
		song: Song11,
		albumCover: AlbumCover,
		title: "I Though You Forgot Something - Nervesscat",
	},
	{
		song: Song12,
		albumCover: AlbumCover,
		title: "Light Forest - Nervesscat",
	},
	{
		song: Song13,
		albumCover: AlbumCover,
		title: "Don't Go There!! - Nervesscat",
	},
	{
		song: Song14,
		albumCover: AlbumCover,
		title: "Nostalgic Meadow - Nervesscat",
	},
	{
		song: Song15,
		albumCover: AlbumCover,
		title: "You're Fast, But Not Much Faster Than Me - Nervesscat",
	},
	{
		song: Song16,
		albumCover: AlbumCover,
		title: "Stars Hallway - Nervesscat",
	},
	{
		song: Song17,
		albumCover: AlbumCover,
		title: "So Sorry - Nervesscat",
	},
];
