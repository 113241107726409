export class CMDPointsAction{
    color: string
    canvas: HTMLCanvasElement
    ctx: CanvasRenderingContext2D | null
    width: number
    height: number
    turn: boolean

    constructor(color: string, ref: HTMLCanvasElement){
        this.color = color;
        this.canvas = ref;
        this.ctx = this.canvas.getContext('2d');
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.turn = true;
    }

    init(){
        this.resize()
        window.addEventListener('resize', this.resize.bind(this))
        this.drawPoints()
    }

    drawPoints(){
        if (!this.ctx) return
        this.ctx.font = '20px Arial'
        this.ctx.textAlign = 'center'

        let radius = 0
        let centerX = this.width / 2
        let centerY = this.height / 2

        let cant = 10
        let maxDistance = Math.sqrt(Math.pow(centerX, 2) + Math.pow(centerY, 2))

        let interval = setInterval(() => {
            if (!this.ctx) return
            if (radius < maxDistance) {
                radius += 40
                for (let i = 0; i < cant; i++) {
                    let angle = Math.PI * 2 / cant * i
                    let x = Math.cos(angle) * radius + centerX
                    let y = Math.sin(angle) * radius + centerY
                    this.ctx.fillStyle = this.color
                    this.ctx.textAlign = 'center'
                    this.ctx.fillText("*", x, y)
                }
                cant += 10

                this.ctx.fillStyle = 'rgba(0, 0, 0, 0.2)'
                this.ctx.fillRect(0, 0, this.width, this.height)
            } else {
                clearInterval(interval)
                if(this.turn) this.drawPoints()
            }
        }, 100)
    }

    resize(){
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.canvas.width = this.width
        this.canvas.height = this.height
    }

    stop(){
        this.turn = false
        window.removeEventListener('resize', this.resize.bind(this))
    }
}