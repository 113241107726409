import { useEffect } from 'react'

import { LanguageSelector } from './SettingsButtons/LanguageSelector'
import { ThemesSelector } from './SettingsButtons/ThemesSelector'
import { MiscSelector } from './SettingsButtons/MiscSelector'
import { Frame } from '../../Another/Frame';

import SettingIcon from '../../../Assets/Img/SVG/Settings/settings.svg'

export default function ThemeAndSoundButtons(){
  function clearConsole(){
    if (process.env.NODE_ENV === 'development') return;
    let id = setTimeout(() => {
      console.clear();
      console.log(
        '%cYou look sus!',
        'color: red; font-size: 50px; font-weight: bold',
      );
      console.log(
        '%cNervesscat needs you 😼😼',
        'color: purple; font-size: 36px; font-weight: semi-bold',
      );
    }, 5000);

    return () => clearTimeout(id);
  }

  useEffect(clearConsole, []);

  return(
    <Frame title={<Title/>} primary={false} >
      <div className='w-full flex flex-col gap-4 px-2'>
        <MiscSelector/>
        <ThemesSelector/>
        <LanguageSelector/>
      </div>
    </Frame>
  )
}

const Title = () => {
  return(
    <img src={SettingIcon} alt='setting gear icon'></img>
  )
}