import { useState, useEffect, useMemo, useRef, Fragment, useCallback } from "react";

import { useLanguage } from "../../../../Context/LanguageContext";
import { useTheme } from "../../../../Context/Themes";
import { useLowPerformance } from "../../../../Context/LowPerformance";

import audioRain from '../../../../Assets/Sounds/rainLight1.mp3'

import VolumeUp from '../../../../Assets/Img/SVG/Settings/volume_up.svg'
import VolumeOff from '../../../../Assets/Img/SVG/Settings/volume_off.svg'

export const MiscSelector = () => {
    const { theme } = useTheme();
    const { lang } = useLanguage();
    
    return(
        <Fragment>
        <h1 className={`text-center text-xl font-extrabold truncate ${ theme.secondary.textColor } `}>{ lang.settings.title }</h1>
        <div className={`w-full flex gap-4 p-2 justify-around rounded-md ${ theme.secondary.bgColor }`}>
            <LowPerformanceButton/>
            <AudioButton/>
        </div>
        </Fragment>
    )
}

const LowPerformanceButton = () => {
    const { lowPerformance, setLowPerformance } = useLowPerformance();
    
    function handleEffectsOnToggle() {
        setLowPerformance(!lowPerformance)
    }
    
    function setLowPerformanceFromLocalStorage() {
        const lowPerformanceLocal = localStorage.getItem('lowPerformance');
        if (lowPerformanceLocal === 'true' || lowPerformanceLocal === 'false') {
        setLowPerformance(lowPerformanceLocal === 'true' ? true : false);
        } else {
        setLowPerformance(true);
        }
    }
    
    useEffect(setLowPerformanceFromLocalStorage, [setLowPerformance]);
    
    function saveLowPerformance() {
        if (lowPerformance === true || lowPerformance === false) {
        localStorage.setItem('lowPerformance', lowPerformance.toString())
        }
    }
    
    useEffect(saveLowPerformance, [lowPerformance]);

    return(
        <svg onClick={handleEffectsOnToggle} className={`w-8 h-8 cursor-pointer ${ lowPerformance ? 'fill-white' : 'fill-yellow-400'}`} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="m284-32 40-280H100l427-616h131l-40 320h272L414-32H284Z"/></svg>
    )
}

const AudioButton = () => {
    const [isPressed, setIsPressed] = useState(false)
    
    const audio = useMemo(() => {
        const audio = new Audio(audioRain)
        audio.volume = 0.4
        audio.loop = true
        return audio
    }, [])
    
    const isPressedRef = useRef(isPressed);
    
    useEffect(() => {
        isPressedRef.current = isPressed;
    }, [isPressed]);
    
    const audioPause = useCallback(() => {
        if(isPressedRef.current) audio.pause();
    }, [audio])
    
    const audioPlay = useCallback(() => {
        if(isPressedRef.current) audio.play();
    }, [audio])
    
    function handleSoundOnToggle() {
        setIsPressed(prevIsPressed => {
        const newIsPressed = !prevIsPressed;
        if (newIsPressed) {
            audio.play();
        } else {
            audio.pause();
        }
        return newIsPressed;
        });
    }
    
    function eventsCreation() {
        window.addEventListener('blur', audioPause)
        window.addEventListener('focus', audioPlay)
    
        return () => {
        window.removeEventListener('blur', audioPause)
        window.removeEventListener('focus', audioPlay)
        }
    }
    
    useEffect(eventsCreation, [audioPause, audioPlay])
    
    return(
        <img src={ isPressed ? VolumeUp : VolumeOff } alt='Toogle button with pixel art' className={`cursor-pointer `} onClick={ handleSoundOnToggle }/>
    )
}