import { useState, useEffect, useCallback } from 'react'

import { useLogin } from '../../../Context/Login'
import { useTheme } from '../../../Context/Themes'
import { useLanguage } from '../../../Context/LanguageContext'

import axios from 'axios'
import { Frame } from '../../Another/Frame'

export default function Status() {
  const { theme } = useTheme();
  const { login } = useLogin();
  const { lang } = useLanguage();

  const [status, setStatus] = useState(true)
  const [lastConnection, setLastConnection] = useState('10:23:45 Mon 10 June')
  
  const getStatus = useCallback(() => {
    const STATUS_API_URL = process.env.REACT_APP_STATUS_API_URL;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    axios({
      method: 'GET',
      url: STATUS_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'Timezone': timezone,
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then(res => {
      setStatus(res.data.statusOnline)
      setLastConnection(res.data.lastConn)
    })
    .catch(err => {
      console.log(err)
    })
  }, [])

  useEffect(() => {
    if (login){
      getStatus()
    }
  }, [login, getStatus])

  return (
    <Frame primary={false}>
      <div className={`w-full flex flex-col gap-2`}>
        <h1 className={`text-sm font-extrabold ` + (status ? 'text-green-700' : 'text-yellow-500')}><span className={theme.primary.textColor}>Nervesscat: </span>{ status ? lang.status.online : lang.status.offline }</h1>
        {
          status ? null : <h1 className={`text-sm ${theme.primary.textColor}`}>{ lang.status.lastConnection }<span className='text-red-500'> { lastConnection }</span></h1>
        }
      </div>
    </Frame>
  )
}
