export class Stack<T> implements Iterable<T> {
	private __items: T[];
	private __size: number;

	constructor(){
		this.__items = [];
		this.__size = 0;
	}

	append(value: T): void {
		this.__items.push(value);
		this.__size++;
	}

	get(index: number): T | undefined{
		if (index > this.__size){
			return undefined
		}
		return this.__items[index]
	}

	[Symbol.iterator](): Iterator<T> {
		let index = this.__size - 1;
		const items = this.__items;

		return {
			next(): IteratorResult<T> {
				if (index >= 0) {
					return {
						value: items[index--],
						done: false,
					};
				} else {
					return {
						value: undefined,
						done: true,
					};
				}
			}
		};
	}

}
