import { useState, useEffect } from 'react'

import Button1 from '../../../Assets/Img/Ecco/Button_1.png'
import Button_Mobile from '../../../Assets/Img/Ecco/Button_mobile.png'
import Mouse from '../../../Assets/Img/Ecco/Mouse.png'

export default function Ecco() {
    const [mouse, setMouse] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    function checkMobile() {
        if (window.innerWidth <= 640) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        checkMobile()
        window.addEventListener('resize', checkMobile)
        return () => {
            window.removeEventListener('resize', checkMobile)
        }
    }, [])

    const handleHover = () => {
        setMouse(true)
    }

    const handleLeave = () => {
        setMouse(false)
    }
    
    const openWebsite = () => {
        const websiteLink = 'https://ecco-a4c02.web.app/'
        window.open(websiteLink, '_blank')
    }

    return(
        <div className='relative' onMouseEnter={handleHover} onMouseLeave={handleLeave} onClick={openWebsite} >
            <img src={Mouse} alt='Mouse' className={`w-auto h-24 mx-auto absolute translate-x-full translate-y-full ${
                mouse ? 'top-1/4 sm:-top-1/4 right-1/2' : '-top-32 right-16'
            } transition-all duration-500 ease-in-out z-10`}/>
            <img src={ isMobile ? Button_Mobile : Button1 } alt='Button1' className={`w-full h-auto mx-auto transition-all duration-300 z-0 hover:brightness-125`}/>
        </div>
    )
}