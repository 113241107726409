import { useCallback, useState, useEffect } from 'react'

import { useLogin } from '../../../Context/Login'
import { useTheme } from '../../../Context/Themes'
import { useLanguage } from '../../../Context/LanguageContext'

import axios from 'axios'
import { Frame } from '../../Another/Frame'

export default function Visitors() {
  const { login } = useLogin();
  const { theme } = useTheme();
  const { lang } = useLanguage();

  const [visitors, setVisitors] = useState({
    totalVisits: 0,
    uniqueVisits: 0
  })

  const VISITOR_API_URL = process.env.REACT_APP_VISITOR_API_URL

  const getVisitors = useCallback(() => {
    axios({
      method: 'get',
      url: VISITOR_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    }).then((res) => {
      if (res.data == null || res.data === undefined || !isNaN(res.data)) {
        return
      }
      setVisitors(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [VISITOR_API_URL])
  
  useEffect(() => {  
    if (login) {
      getVisitors()
    }
  }, [login, getVisitors])

  return (
    <Frame title={<Title/>} primary={false} left={false} >
      <div className={`flex flex-col`}>
        <p className='text-lg'>{ lang.visitors.total } <span className={`font-extrabold ${theme.secondary.textBold}`}>{ visitors.totalVisits }</span></p>
        <p className='text-lg'>{ lang.visitors.unique } <span className={`font-extrabold ${theme.secondary.textBold}`}>{ visitors.uniqueVisits }</span></p>
        <p className='text-lg'>{ lang.visitors.returning } <span className={`font-extrabold ${theme.secondary.textBold}`}>{ visitors.totalVisits - visitors.uniqueVisits }</span></p>
      </div>
    </Frame>
  )
}

const Title = () => {
  const { lang } = useLanguage();

  return(
    <h1 className={`text-sm break-words text-white`}>{ lang.visitors.title }</h1>
  )
}