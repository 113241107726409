import { useState, useRef, useEffect } from 'react'

import { useTheme } from '../../../Context/Themes'
import { useLogin } from '../../../Context/Login'

import { Planet3D } from '../../../Assets/TS/Planet3D'

import PurplePlanetTexture from '../../../Assets/Img/Planet/planet_texture_purple.png'
import GreenPlanetTexture from '../../../Assets/Img/Planet/planet_texture_green.png'
import OrangePlanetTexture from '../../../Assets/Img/Planet/planet_texture_orange.png'
import OldMacPlanetTexture from '../../../Assets/Img/Planet/planet_texture.png'

import axios from 'axios'   
import { Frame } from '../../Another/Frame'

type City = {
    name: string,
    visits: number,
    latitude: number,
    longitude: number
}

const planetTextureThemes: Record<string, any> = {
    'christmasStyle': GreenPlanetTexture,
    'nervessStyle': PurplePlanetTexture,
    'retroStyle': GreenPlanetTexture,
    'oldMacStyle': OldMacPlanetTexture,
    'halloweenStyle': OrangePlanetTexture
}

export default function Planet() {
    const [citiesList, setCitiesList] = useState<City[]>([]);
    const [planetTexture, setPlanetTexture] = useState(null);
    
    const { login } = useLogin();
    const { theme } = useTheme();
    
    const mountRef = useRef<HTMLDivElement | null>(null);
    const planetRef = useRef<Planet3D | null>(null);
    
    useEffect(() => {
        setPlanetTexture(planetTextureThemes[theme.misc.style]);
        if (!mountRef.current) return;
        planetRef.current = new Planet3D(mountRef.current, planetTexture, theme.misc.points);
        planetRef.current.animate();
        const currentMountRef = mountRef.current;
        if (!currentMountRef) return;
        if (currentMountRef.children.length > 0) {
            currentMountRef.removeChild(currentMountRef.children[0]);
        }
        currentMountRef.appendChild(planetRef.current.getDomElement());
        return () => {
            if (currentMountRef && planetRef.current) {
                currentMountRef.removeChild(planetRef.current.getDomElement());
                planetRef.current.dispose();
            }
        }
    }, [mountRef, planetTexture, theme])

    useEffect(() => {
        const CITIES_URL = process.env.REACT_APP_CITIES_API_URL
        fetchCities();

        function fetchCities(){
            if (!login) {
                setCitiesList(setDefaultValues());
                return;
            }
            axios({
                method: 'GET',
                url: CITIES_URL,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    res.data.cities.sort((a : City, b : City) => b.visits - a.visits);
                    fetchData(res.data.cities, res.data.cities[0].visits);
                }
            }).catch((err) => {
                console.log(err);
                return [[], 0];
            })
        }

        function setDefaultValues(): City[]{
            return [
                {
                    name: "Tokio",
                    visits: 21,
                    latitude: 35.41243,
                    longitude: 139.413
                },
                {
                    name: "New York",
                    visits: 15,
                    latitude: 40.7128,
                    longitude: -74.006
                },
                {
                    name: "London",
                    visits: 10,
                    latitude: 51.5074,
                    longitude: -0.1278
                },
                {
                    name: "Paris",
                    visits: 8,
                    latitude: 48.8566,
                    longitude: 2.3522
                },
                {
                    name: "Berlin",
                    visits: 5,
                    latitude: 52.5200,
                    longitude: 13.4050
                },
                {
                    name: "Rome",
                    visits: 3,
                    latitude: 41.9028,
                    longitude: 12.4964
                },
                {
                    name: "Madrid",
                    visits: 1,
                    latitude: 40.4168,
                    longitude: -3.7038
                }
            ]
        }

        function fetchData(cities : City[], recordVisits : number) {
            if (cities.length === 0) return;
            setCitiesList(cities);
            if (planetRef.current) planetRef.current.traverseListAndAddPoints(cities, recordVisits);
        }
    }, [mountRef, login])

    useEffect(() => {
        if (planetRef.current && citiesList.length > 0) { 
            planetRef.current.changeTexture(planetTexture);
            planetRef.current.traverseListAndAddPoints(citiesList, citiesList[0].visits);
        }
    }, [theme, planetTexture, citiesList])

    return (
        <Frame title={<Title/>} primary={false} left={false}>
            <div className={`w-full h-full flex justify-center relative`}>
                <div className={`w-full h-full absolute z-10`}>
                    <div className='w-full h-full overflow-y-auto'>
                    { citiesList.map((city, index) => <CityInfo key={index} index={index} city={city}/>) }
                    </div>
                </div>
                <div ref={mountRef} className='w-48 h-48 flex justify-center place-items-center'></div>
            </div>
        </Frame>
    )
}

const Title = () => {
    const { theme } = useTheme();

    return(
        <div className={`rounded-br-md ${theme.primary.textColor}`}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z" fill="#fff" fill-opacity="0" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10"/><path d="M11.27 21.69A15.938 15.938 0 0 1 8 12c0-3.64 1.24-7.07 3.34-9.78M12.73 2.22C14.78 4.91 16 8.27 16 11.91c0 3.64-1.24 7.07-3.34 9.78" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/><path d="M19.14 19c-1.82-1.85-4.34-3-7.14-3s-5.33 1.15-7.14 3M19.14 5C17.33 6.85 14.8 8 12 8S6.68 6.85 4.86 5" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10"/><path d="M2 12h20" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/></svg>
        </div>
    )
}

function CityInfo({ city, index } : { city: City, index: number }){
    const { theme } = useTheme();

    return (
        <div className={`flex justify-between gap-2 ${ index === 0 ? 'text-green-500' : index === 1 ? 'text-yellow-500' : index === 2 ? 'text-orange-500' : theme.secondary.textColor }`}>
            <p className='text-lg self-center'>{city.name}</p>
            <p className='text-sm self-center'>{city.visits} visits</p>
        </div>
    )
}