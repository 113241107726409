import { useState, useContext, createContext, ReactNode } from "react";

import { ThemeType, nervessStyle, retroStyle } from "../Utils/Settings";

type ThemesContextType = {
    theme: ThemeType;
    setTheme: (theme: ThemeType) => void;
}

export const ThemesContext = createContext<ThemesContextType>(
    {} as ThemesContextType
);

export const ThemesProvider = ({ children } : { children : ReactNode }) => {
    const [theme, setTheme] = useState(nervessStyle);

    return(
        <ThemesContext.Provider value={{ theme, setTheme }}>
            { children }
        </ThemesContext.Provider>
    )
}

export const useTheme = () => {
    const context = useContext(ThemesContext);

    if (!context){
        throw new Error('useTheme must be within ThemesProvider');
    }

    return context
}