import { createContext, useContext, useState, ReactNode } from "react";

interface LowPerformanceContextType {
    lowPerformance: boolean | null;
    setLowPerformance: (lowPerformance: boolean) => void;
}

export const LowPerformanceContext = createContext<LowPerformanceContextType>(
    {} as LowPerformanceContextType
);

export const LowPerformanceProvider = ({ children } : { children: ReactNode }) => {
    const [lowPerformance, setLowPerformance] = useState<boolean | null>(null);

    return (
        <LowPerformanceContext.Provider value={{ lowPerformance, setLowPerformance }}>
            { children }
        </LowPerformanceContext.Provider>
    )
}

export const useLowPerformance = () => {
    const context = useContext(LowPerformanceContext);

    if (!context){
        throw new Error('useLowPerformance must be within a LowPerformanceProvider');
    }

    return context;
}