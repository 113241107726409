export class Queue<T> implements Iterable<T> {
	private items: T[];
	public __limit: number;

	constructor(limit: number) {
		this.items = [];
		this.__limit = limit;
	}

	enqueue(element: T): void {
		if (this.items.length >= this.__limit) this.dequeue();
		this.items.push(element);
	}

	dequeue(): T | undefined {
		if (this.isEmpty()) {
			return undefined;
		}
		return this.items.shift();
	}

	peek(): T | undefined {
		if (this.isEmpty()) {
			return undefined;
		}
		return this.items[0];
	}

	isEmpty(): boolean {
		return this.items.length === 0;
	}

	[Symbol.iterator](): Iterator<T> {
		let index = 0;
		const items = this.items;

		return {
			next(): IteratorResult<T> {
				if (index < items.length) {
					return { value: items[index++], done: false };
				} else {
					return { value: undefined, done: true };
				}
			},
		};
	}
}
