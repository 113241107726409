import { useState, useEffect } from 'react'

import { useTheme } from '../../../Context/Themes'
import { useLanguage } from '../../../Context/LanguageContext'
import { Frame } from '../../Another/Frame'

export default function Stars() {
  const { theme } = useTheme();

  const [showContact, setShowContact] = useState(true)
  
  function showContactHandler() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (timezone === 'America/Tegucigalpa') {
      setShowContact(false)
    }
  }

  useEffect(() => {
    showContactHandler()
  }, [])

  return (
    <div className={`w-full h-full flex flex-col`}>
      <Frame title={<Title/>} primary={false} left={false}>  
        <ul className="list-disc list-inside text-white">
            <li className={`text-white flex flex-col content-center`}>
              {
                showContact ? <div>
                  <span className={`${theme.secondary.textBold} font-extrabold animate-pulse`}>Discord</span>
                  <span className={theme.secondary.textColor}>: sarahow</span>
                </div> : null
              }
              <div>
                <span className='flex'>
                  <span className={`${theme.secondary.textBold} font-bold animate-pulse`}>Email</span>
                  <a href='mailto:contact@nervess.cat' className={`text-center ${theme.primary.textColor} truncate`}>: contact@nervess.cat</a>
                </span>
              </div>
              <div>
                <span className='flex'>
                  <span className={`${theme.secondary.textBold} font-bold animate-pulse`}>Instagram</span>
                  <a href='https://www.instagram.com/nervesscat/' target="_blank" rel="noreferrer" className={`text-center ${theme.primary.textColor} truncate`}>: nervesscat</a>
                </span>
              </div>
            </li>
            {
              showContact ? <li>
                <a className={`text-pink-500 ${theme.secondary.textBold}`} href='https://linkr.bio/sarahow/' target="_blank" rel="noreferrer">
                  Sarah's linkr.bio
                </a>
              </li> : null
            }
            <li>
              <a className={theme.secondary.textColor} href='https://github.com/nervesscat' target="_blank" rel="noreferrer">
                Github
              </a>
            </li>
            {
              showContact ? <li>
                <a className={theme.secondary.textColor} href='https://twitter.com/_sarah_idk_' target="_blank" rel="noreferrer">
                  X (Twitter)
                </a>
              </li> : null
            }
            <li>
              <a className={theme.secondary.textColor} href='https://t.me/enriquemadrid' target="_blank" rel="noreferrer">
                Telegram
              </a>
            </li>
          </ul>
      </Frame>
    </div>
  )
}

const Title = () => {
  const { lang } = useLanguage();

  return(
    <h1 className={`text-sm text-white`}>
      { lang.stars.title }
    </h1>
  )
}