import { useTheme } from '../../Context/Themes'

export default function Foot() {
  const { theme } = useTheme();
  return (
    <div className='h-full w-full flex flex-col p-8 content-center'>
        <p className='text-center'>2023-2024 nervesscat©. All Right Reserved.</p>
        <a href='mailto:sarahowu.u@gmail.com' className={`text-center ${theme.secondary.textBold}`}>contact@nervess.cat</a>
    </div>
  )
}
