export type ThemeType = {
	primary: Pallete;
	secondary: Pallete;
	misc: Misc;
};

type Pallete = {
	bgColor: string;
	textColor: string;
	textBold: string;
	borderColor: string;
	bgTextColor: string;
};

type Misc = {
	hoverBg: string;
	textBoxBg: string;
	points: string;
	shadowImage: string;
	style: string;
};

export const christmasStyle: ThemeType = {
	primary: {
		bgColor: "bg-red-600",
		textColor: "text-white",
		textBold: "text-green-800",
		borderColor: "border-red-600",
		bgTextColor: "bg-red-800",
	},
	secondary: {
		bgColor: "bg-red-800",
		textColor: "text-green-700",
		textBold: "text-green-800",
		borderColor: "border-green-800",
		bgTextColor: "bg-green-900",
	},
	misc: {
		hoverBg: "hover:bg-red-500",
		textBoxBg: "transparent",
		points: "red",
		shadowImage: "hover:shadow-red-500",
		style: "christmasStyle",
	},
};

export const nervessStyle: ThemeType = {
	primary: {
		bgColor: "bg-fuchsia-600",
		textColor: "text-white",
		textBold: "text-purple-500",
		borderColor: "border-fuchsia-800",
		bgTextColor: "bg-fuchsia-700",
	},
	secondary: {
		bgColor: "bg-purple-800",
		textColor: "text-white",
		textBold: "text-fuchsia-500",
		borderColor: "border-purple-700",
		bgTextColor: "bg-purple-900",
	},
	misc: {
		hoverBg: "hover:bg-fuchsia-500",
		textBoxBg: "bg-black",
		points: "purple",
		shadowImage: "hover:shadow-fuchsia-500",
		style: "nervessStyle",
	},
};

export const retroStyle: ThemeType = {
	primary: {
		bgColor: "bg-green-600",
		textColor: "text-white",
		textBold: "text-green-800",
		borderColor: "border-green-800",
		bgTextColor: "bg-green-700",
	},
	secondary: {
		bgColor: "bg-green-800",
		textColor: "text-white",
		textBold: "text-green-500",
		borderColor: "border-green-700",
		bgTextColor: "bg-green-900",
	},
	misc: {
		hoverBg: "hover:bg-green-500",
		textBoxBg: "bg-black",
		points: "green",
		shadowImage: "hover:shadow-green-500",
		style: "retroStyle",
	},
};

export const oldMacStyle: ThemeType = {
	primary: {
		bgColor: "bg-red-600",
		textColor: "text-black",
		textBold: "text-zinc-800",
		borderColor: "border-white",
		bgTextColor: "bg-zinc-300",
	},
	secondary: {
		bgColor: "bg-zinc-900",
		textColor: "text-black",
		textBold: "rgba(220, 38, 38)",
		borderColor: "border-black",
		bgTextColor: "bg-zinc-300",
	},
	misc: {
		hoverBg: "hover:bg-zinc-50",
		textBoxBg: "bg-white",
		points: "rgba(220, 38, 38)",
		shadowImage: "hover:shadow-black",
		style: "oldMacStyle",
	},
};

export const halloweenStyle: ThemeType = {
	primary: {
		bgColor: "bg-gray-800",
		textColor: "text-white",
		textBold: "text-gray-500",
		borderColor: "border-gray-700",
		bgTextColor: "bg-gray-900",
	},
	secondary: {
		bgColor: "bg-orange-600",
		textColor: "text-white",
		textBold: "text-orange-500",
		borderColor: "border-orange-600",
		bgTextColor: "bg-orange-800",
	},
	misc: {
		hoverBg: "hover:bg-orange-500",
		textBoxBg: "bg-black",
		points: "orange",
		shadowImage: "hover:shadow-orange-500",
		style: "halloweenStyle",
	},
};

export const themesMap = {
	christmasStyle: christmasStyle,
	nervessStyle: nervessStyle,
	retroStyle: retroStyle,
	oldMacStyle: oldMacStyle,
	halloweenStyle: halloweenStyle,
};

type LanguageKeys =
	| "en"
	| "es"
	| "pt"
	| "fr"
	| "de"
	| "it"
	| "ru"
	| "zh"
	| "ja"
	| "ko"
	| "ka"
	| "hi"
	| "fil"
	| "vi"
	| "th"
	| "tr"
	| "pl"
	| "nl"
	| "sv"
	| "da"
	| "uk"
	| "ka";

export type LanguageStruct = {
	bio: {
		welcome: string;
		pageWork: string;
		loginAdvertisement: string;
		description: string[];
		languages: string[];
		quickFacts: string;
		facts: string[];
		nervesscatMessage: string;
	};
	stars: {
		title: string;
	};
	settings: {
		title: string;
		themes: string;
		language: string;
	};
	status: {
		title: string;
		online: string;
		offline: string;
		lastConnection: string;
	};
	zincNet: {
		title: string;
	};
	weather: {
		title: string;
		yourTime: string;
		serverTime: string;
		city: string;
		temperature: string;
		humidity: string;
		wind: string;
		precipitation: string;
		pressure: string;
		cloud: string;
		uv: string;
		lastUpdate: string;
	};
	art: {
		title: string;
		goCheck: string;
	};
	visitors: {
		title: string;
		total: string;
		unique: string;
		returning: string;
	};
	projects: {
		title: string;
		projects: {
			title: string;
			description: string;
			buttonsText: string[];
		}[];
	};
	skills: {
		title: string;
		status: string;
	};
	aiChat: {
		title: string;
		chat: string;
	};
	errorRouter: {
		title: string;
		description: string[];
	};
	connFailed: {
		title: string;
		description: string;
		support: string;
	};
};

type LanguageType = {
	[key in LanguageKeys]: LanguageStruct;
};

export const language: LanguageType = {
	en: {
		bio: {
			welcome: "Welcome to nervesscat",
			pageWork: "Page still in work!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat run out of money 😿, we aren't able to pay Google-san our payment, so the functionalities of the website are limited for now. Please consider supporting us on Kofi!! 💖",
			description: [
				"I code many things and I'm ever be excited about new technologies and make new things. I'm a full stack developer, In this page you could see my last projects and another hobbies, if you want to contact me, feel free to ask me in my social media (o゜▽゜)o☆.",
				"I started programming when I was 10 years old, I started with Java, currently I know many languages such as",
				"and more. I'm a self-taught person, I love to learn new things and I'm always looking for new challenges.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "QUICK FACTS:",
			facts: [
				"I'm 22 years old.",
				"I'm from Honduras.",
				"I'm currently studying System Engineering at UNAH.",
				"My hobbies are music production 🎹, art 🎨, graphic design and coding!!.",
				"I'm creating music to my first videogame. (✿◡‿◡)",
				"I'm AI nerd, so you probably you're going to see many project about that.",
				"I code websites, bots (especially discord bots), and any algorithm or script.",
				"I really like analog/retro technology.",
			],
			nervesscatMessage: "Nervesscat is here!!",
		},
		stars: {
			title: "Contact",
		},
		settings: {
			title: "Settings",
			themes: "Themes",
			language: "Choose language",
		},
		status: {
			title: "Status",
			online: "Online",
			offline: "Offline",
			lastConnection: "Last Connection:",
		},
		zincNet: {
			title: "Visit ZincNet!!!",
		},
		weather: {
			title: "My Weather",
			yourTime: "Your Time:",
			serverTime: "Server Time:",
			city: "City:",
			temperature: "Temperature:",
			humidity: "Humidity:",
			wind: "Wind:",
			precipitation: "Precipitation:",
			pressure: "Pressure:",
			cloud: "Cloud:",
			uv: "UV:",
			lastUpdate: "Last Update:",
		},
		art: {
			title: "Art",
			goCheck: "Go check it out:",
		},
		visitors: {
			title: "Visitors",
			total: "Total Visitors:",
			unique: "Unique Visitors:",
			returning: "Returning Visitors:",
		},
		projects: {
			title: "Projects",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"A discord bot to generate AI Art or to generate pictures, also you can use it to get information about a movie or a tv show, or to get a random quote",
					buttonsText: ["Hire Me on Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"A terminal application that can control your pc by simple instructions, it uses the GPT-3 model to understand the instructions and execute them. It can do things like open a program, close a program, take a screenshot, and more.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"A terminal application that uses the GPT-3 and Whisper models to generate a summary of a zoom meeting, it can also generate a summary of a youtube video, a podcast, or a any video.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"A journal app that uses AI to analyze your notes. It uses an AI model to analyze the information and goals in your notes to help you autocomplete, give you suggestions, ideas, and more.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"A mail client, indexer and search engine for the Enron Database. It uses a custom indexer and search engine to search for emails in the Enron Database.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description: "A funny math puzzle created in c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot is a Python library designed to facilitate the creation of AI-powered bots using OpenAI's API. This library provides a simple interface to integrate various functionalities and create a customizable assistant.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Skills",
			status: "System Status:",
		},
		aiChat: {
			title: "AI Chat",
			chat: "Hello, I'm Nervesscat 😼, I can help you with anything you want to know about this website. Type /help to see more options.",
		},
		errorRouter: {
			title: "Page Doesn't exist",
			description: ["Click Here", "or wait", "seconds to redirect"],
		},
		connFailed: {
			title: "Connection has some trouble :c",
			description:
				"If the problem persist, we'll let you know in our social media.",
			support: "Support Us on Kofi (✿◡‿◡)",
		},
	},
	es: {
		bio: {
			welcome: "Bienvenido a nervesscat",
			pageWork: "Página aún en construcción!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat se quedó sin dinero 😿. No podemos pagar a Google-san, así que las funcionalidades del sitio web están limitadas por ahora. ¡Por favor, considera apoyarnos en Ko-fi!!",
			description: [
				"Programo muchas cosas y siempre estoy emocionado por las nuevas tecnologías y hacer cosas nuevas. Soy un desarrollador full stack, en esta página puedes ver mis últimos proyectos y otros pasatiempos, si quieres contactarme, no dudes en preguntarme en mis redes sociales (o゜▽゜)o☆.",
				"Comencé a programar cuando tenía 10 años, comencé con Java, actualmente sé muchos lenguajes como",
				"y más. Soy una persona autodidacta, me encanta aprender cosas nuevas y siempre estoy buscando nuevos desafíos.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "DATOS RÁPIDOS:",
			facts: [
				"Tengo 22 años.",
				"Soy de Honduras.",
				"Actualmente estoy estudiando Ingeniería de Sistemas en la UNAH.",
				"Mis pasatiempos son la producción musical 🎹, el arte 🎨, el diseño gráfico y la programación!!.",
				"Estoy creando música para mi primer videojuego. (✿◡‿◡)",
				"Soy un nerd de la IA, así que probablemente verás muchos proyectos sobre eso.",
				"Programo sitios web, bots (especialmente bots de discord), y cualquier algoritmo o script.",
				"Me gusta mucho la tecnología analógica/retro.",
			],
			nervesscatMessage: "¡Nervesscat está aquí!!",
		},
		stars: {
			title: "Contacto",
		},
		settings: {
			title: "Configuración",
			themes: "Temas",
			language: "Elige un idioma",
		},
		status: {
			title: "Estado",
			online: "En línea",
			offline: "Desconectado",
			lastConnection: "Última Conexión:",
		},
		zincNet: {
			title: "¡Visita ZincNet!!!",
		},
		weather: {
			title: "Mi Clima",
			yourTime: "Tu Hora:",
			serverTime: "Hora del Servidor:",
			city: "Ciudad:",
			temperature: "Temperatura:",
			humidity: "Humedad:",
			wind: "Viento:",
			precipitation: "Precipitación:",
			pressure: "Presión:",
			cloud: "Nube:",
			uv: "UV:",
			lastUpdate: "Última Actualización:",
		},
		art: {
			title: "Arte",
			goCheck: "Ve a verlo:",
		},
		visitors: {
			title: "Visitantes",
			total: "Total de Visitantes:",
			unique: "Visitantes Únicos:",
			returning: "Visitantes que Regresan:",
		},
		projects: {
			title: "Proyectos",
			projects: [
				{
					title: "Bot de IA para Discord",
					description:
						"Un bot de discord para generar arte de IA o para generar imágenes, también puedes usarlo para obtener información sobre una película o un programa de televisión, o para obtener una cita aleatoria",
					buttonsText: ["Contrátame en Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Una aplicación de terminal que puede controlar tu pc mediante instrucciones simples, utiliza el modelo GPT-3 para entender las instrucciones y ejecutarlas. Puede hacer cosas como abrir un programa, cerrar un programa, tomar una captura de pantalla y más.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Una aplicación de terminal que utiliza los modelos GPT-3 y Whisper para generar un resumen de una reunión de zoom, también puede generar un resumen de un video de youtube, un podcast, o un video cualquiera.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Una aplicación de diario que utiliza IA para analizar tus notas. Utiliza un modelo de IA para analizar la información y los objetivos en tus notas para ayudarte a autocompletar, darte sugerencias, ideas y más.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Un cliente de correo, indexador y motor de búsqueda para la Base de Datos Enron. Utiliza un indexador y motor de búsqueda personalizado para buscar correos electrónicos en la Base de Datos Enron.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description: "Un divertido rompecabezas matemático creado en c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot es una biblioteca de Python diseñada para facilitar la creación de bots impulsados por IA utilizando la API de OpenAI. Esta biblioteca proporciona una interfaz simple para integrar diversas funcionalidades y crear un asistente personalizable.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Habilidades",
			status: "Estado del Sistema:",
		},
		aiChat: {
			title: "Chat de IA",
			chat: "Hola, soy Nervesscat 😼, puedo ayudarte con cualquier cosa que quieras saber sobre este sitio web. Escribe /help para ver más opciones.",
		},
		errorRouter: {
			title: "La página no existe",
			description: ["Haz Click Aquí", "o espera", "segundos para redirigir"],
		},
		connFailed: {
			title: "La conexión tiene problemas :c",
			description:
				"Si el problema persiste, te lo haremos saber en nuestras redes sociales.",
			support: "Apóyanos en Kofi (✿◡‿◡)",
		},
	},
	pt: {
		bio: {
			welcome: "Bem-vindo ao nervesscat",
			pageWork: "Página ainda em construção!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat ficou sem dinheiro 😿. Não conseguimos pagar ao Google-san, então as funcionalidades do site estão limitadas por enquanto. Por favor, considere nos apoiar no Ko-fi!!",
			description: [
				"Eu programo muitas coisas e estou sempre animado com as novas tecnologias e fazendo coisas novas. Sou um desenvolvedor full stack, nesta página você pode ver meus últimos projetos e outros hobbies, se quiser entrar em contato comigo, sinta-se à vontade para me perguntar nas minhas redes sociais (o゜▽゜)o☆.",
				"Comecei a programar quando tinha 10 anos, comecei com Java, atualmente sei muitas linguagens como",
				"e mais. Sou uma pessoa autodidata, adoro aprender coisas novas e estou sempre em busca de novos desafios.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "FATOS RÁPIDOS:",
			facts: [
				"Tenho 22 anos.",
				"Sou de Honduras.",
				"Atualmente estou estudando Engenharia de Sistemas na UNAH.",
				"Meus hobbies são produção musical 🎹, arte 🎨, design gráfico e programação!!.",
				"Estou criando música para o meu primeiro videogame. (✿◡‿◡)",
				"Sou um nerd de IA, então provavelmente você verá muitos projetos sobre isso.",
				"Eu programo sites, bots (especialmente bots de discord), e qualquer algoritmo ou script.",
				"Eu realmente gosto de tecnologia analógica/retro.",
			],
			nervesscatMessage: "Nervesscat está aqui!!",
		},
		stars: {
			title: "Contato",
		},
		settings: {
			title: "Configurações",
			themes: "Temas",
			language: "Escolha um idioma",
		},
		status: {
			title: "Status",
			online: "Online",
			offline: "Offline",
			lastConnection: "Última Conexão:",
		},
		zincNet: {
			title: "Visite o ZincNet!!!",
		},
		weather: {
			title: "Meu Clima",
			yourTime: "Sua Hora:",
			serverTime: "Hora do Servidor:",
			city: "Cidade:",
			temperature: "Temperatura:",
			humidity: "Umidade:",
			wind: "Vento:",
			precipitation: "Precipitação:",
			pressure: "Pressão:",
			cloud: "Nuvem:",
			uv: "UV:",
			lastUpdate: "Última Atualização:",
		},
		art: {
			title: "Arte",
			goCheck: "Vá conferir:",
		},
		visitors: {
			title: "Visitantes",
			total: "Total de Visitantes:",
			unique: "Visitantes Únicos:",
			returning: "Visitantes que Retornam:",
		},
		projects: {
			title: "Projetos",
			projects: [
				{
					title: "Bot de IA para Discord",
					description:
						"Um bot do discord para gerar arte de IA ou para gerar imagens, também pode usá-lo para obter informações sobre um filme ou um programa de TV, ou para obter uma citação aleatória",
					buttonsText: ["Contrate-me no Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Uma aplicação de terminal que pode controlar o seu pc por instruções simples, utiliza o modelo GPT-3 para entender as instruções e executá-las. Pode fazer coisas como abrir um programa, fechar um programa, tirar uma captura de tela e mais.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Uma aplicação de terminal que utiliza os modelos GPT-3 e Whisper para gerar um resumo de uma reunião do zoom, também pode gerar um resumo de um vídeo do youtube, um podcast, ou um vídeo qualquer.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Um aplicativo de diário que usa IA para analisar suas notas. Usa um modelo de IA para analisar as informações e objetivos em suas notas para ajudá-lo a autocompletar, dar sugestões, ideias e mais.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Um cliente de e-mail, indexador e mecanismo de busca para o Banco de Dados Enron. Usa um indexador e mecanismo de busca personalizado para pesquisar e-mails no Banco de Dados Enron.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description: "Um enigma matemático engraçado criado em c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot é uma biblioteca Python projetada para facilitar a criação de bots alimentados por IA usando a API da OpenAI. Esta biblioteca fornece uma interface simples para integrar várias funcionalidades e criar um assistente personalizável.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Habilidades",
			status: "Estado do Sistema:",
		},
		aiChat: {
			title: "Chat de IA",
			chat: "Olá, sou o Nervesscat 😼, posso ajudá-lo com qualquer coisa que você queira saber sobre este site. Digite /help para ver mais opções.",
		},
		errorRouter: {
			title: "A página não existe",
			description: ["Clique Aqui", "ou espere", "segundos para redirecionar"],
		},
		connFailed: {
			title: "A conexão tem problemas :c",
			description:
				"Se o problema persistir, informaremos nas nossas redes sociais.",
			support: "Apoie-nos no Kofi (✿◡‿◡)",
		},
	},
	fr: {
		bio: {
			welcome: "Bienvenue sur nervesscat",
			pageWork: "Page toujours en construction!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat est à court d'argent 😿. Nous ne pouvons pas payer Google-san, donc les fonctionnalités du site web sont limitées pour le moment. Merci de nous soutenir sur Ko-fi!!",
			description: [
				"Je code beaucoup de choses et je suis toujours excité par les nouvelles technologies et par la création de nouvelles choses. Je suis un développeur full stack, sur cette page vous pouvez voir mes derniers projets et mes autres passe-temps, si vous voulez me contacter, n'hésitez pas à me demander sur mes réseaux sociaux (o゜▽゜)o☆.",
				"J'ai commencé à programmer à l'âge de 10 ans, j'ai commencé avec Java, actuellement je connais de nombreux langages tels que",
				"et plus. Je suis une personne autodidacte, j'adore apprendre de nouvelles choses et je suis toujours à la recherche de nouveaux défis.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "FAITS RAPIDES:",
			facts: [
				"J'ai 22 ans.",
				"Je viens du Honduras.",
				"Je suis actuellement étudiant en génie des systèmes à l'UNAH.",
				"Mes hobbies sont la production musicale 🎹, l'art 🎨, le design graphique et la programmation!!.",
				"Je crée de la musique pour mon premier jeu vidéo. (✿◡‿◡)",
				"Je suis un nerd de l'IA, donc vous verrez probablement de nombreux projets à ce sujet.",
				"Je code des sites web, des bots (surtout des bots discord), et tout algorithme ou script.",
				"J'aime beaucoup la technologie analogique/retro.",
			],
			nervesscatMessage: "Nervesscat est là!!",
		},
		stars: {
			title: "Contact",
		},
		settings: {
			title: "Paramètres",
			themes: "Thèmes",
			language: "Choisissez une langue",
		},
		status: {
			title: "Statut",
			online: "En ligne",
			offline: "Hors ligne",
			lastConnection: "Dernière Connexion:",
		},
		zincNet: {
			title: "Visitez ZincNet!!!",
		},
		weather: {
			title: "Mon Temps",
			yourTime: "Votre Heure:",
			serverTime: "Heure du Serveur:",
			city: "Ville:",
			temperature: "Température:",
			humidity: "Humidité:",
			wind: "Vent:",
			precipitation: "Précipitation:",
			pressure: "Pression:",
			cloud: "Nuage:",
			uv: "UV:",
			lastUpdate: "Dernière Mise à Jour:",
		},
		art: {
			title: "Art",
			goCheck: "Allez vérifier:",
		},
		visitors: {
			title: "Visiteurs",
			total: "Total des Visiteurs:",
			unique: "Visiteurs Uniques:",
			returning: "Visiteurs de Retour:",
		},
		projects: {
			title: "Projets",
			projects: [
				{
					title: "Bot IA pour Discord",
					description:
						"Un bot discord pour générer de l'art IA ou pour générer des images, vous pouvez également l'utiliser pour obtenir des informations sur un film ou une émission de télévision, ou pour obtenir une citation aléatoire",
					buttonsText: ["Engagez-moi sur Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Une application de terminal qui peut contrôler votre pc par des instructions simples, elle utilise le modèle GPT-3 pour comprendre les instructions et les exécuter. Elle peut faire des choses comme ouvrir un programme, fermer un programme, prendre une capture d'écran et plus encore.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Une application de terminal qui utilise les modèles GPT-3 et Whisper pour générer un résumé d'une réunion zoom, elle peut également générer un résumé d'une vidéo youtube, d'un podcast, ou d'une vidéo quelconque.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Une application de journal qui utilise l'IA pour analyser vos notes. Elle utilise un modèle d'IA pour analyser les informations et les objectifs de vos notes pour vous aider à les autocompléter, vous donner des suggestions, des idées et plus encore.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Un client de messagerie, indexeur et moteur de recherche pour la Base de Données Enron. Il utilise un indexeur et un moteur de recherche personnalisé pour rechercher des e-mails dans la Base de Données Enron.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description: "Un puzzle mathématique amusant créé en c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot est une bibliothèque Python conçue pour faciliter la création de bots alimentés par l'IA en utilisant l'API d'OpenAI. Cette bibliothèque offre une interface simple pour intégrer diverses fonctionnalités et créer un assistant personnalisable.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Compétences",
			status: "État du Système:",
		},
		aiChat: {
			title: "Chat IA",
			chat: "Bonjour, je suis Nervesscat 😼, je peux vous aider avec tout ce que vous voulez savoir sur ce site web. Tapez /help pour voir plus d'options.",
		},
		errorRouter: {
			title: "La page n'existe pas",
			description: ["Cliquez Ici", "ou attendez", "secondes pour rediriger"],
		},
		connFailed: {
			title: "La connexion a des problèmes :c",
			description:
				"Si le problème persiste, nous vous en informerons sur nos réseaux sociaux.",
			support: "Soutenez-nous sur Kofi (✿◡‿◡)",
		},
	},
	de: {
		bio: {
			welcome: "Willkommen bei nervesscat",
			pageWork: "Seite noch in Arbeit!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat ist das Geld ausgegangen 😿. Wir können Google-san nicht bezahlen, daher sind die Funktionen der Website derzeit eingeschränkt. Bitte unterstütze uns auf Ko-fi!!",
			description: [
				"Ich programmiere viele Dinge und bin immer aufgeregt über neue Technologien und das Schaffen neuer Dinge. Ich bin ein Full-Stack-Entwickler, auf dieser Seite können Sie meine letzten Projekte und andere Hobbys sehen, wenn Sie mich kontaktieren möchten, fragen Sie mich gerne in meinen sozialen Medien (o゜▽゜)o☆.",
				"Ich habe mit 10 Jahren angefangen zu programmieren, ich habe mit Java angefangen, heute kenne ich viele Sprachen wie",
				"und mehr. Ich bin ein Autodidakt, ich liebe es, neue Dinge zu lernen und bin immer auf der Suche nach neuen Herausforderungen.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "SCHNELLE FAKTEN:",
			facts: [
				"Ich bin 22 Jahre alt.",
				"Ich komme aus Honduras.",
				"Ich studiere derzeit Systemtechnik an der UNAH.",
				"Meine Hobbys sind Musikproduktion 🎹, Kunst 🎨, Grafikdesign und Programmierung!!.",
				"Ich erstelle Musik für mein erstes Videospiel. (✿◡‿◡)",
				"Ich bin ein KI-Nerd, daher wirst du wahrscheinlich viele Projekte dazu sehen.",
				"Ich programmiere Websites, Bots (insbesondere Discord-Bots) und jeden Algorithmus oder jedes Skript.",
				"Ich mag analoge/retro Technologie sehr gerne.",
			],
			nervesscatMessage: "Nervesscat ist hier!!",
		},
		stars: {
			title: "Kontakt",
		},
		settings: {
			title: "Einstellungen",
			themes: "Themen",
			language: "Wähle eine Sprache",
		},
		status: {
			title: "Status",
			online: "Online",
			offline: "Offline",
			lastConnection: "Letzte Verbindung:",
		},
		zincNet: {
			title: "Besuchen Sie ZincNet!!!",
		},
		weather: {
			title: "Mein Wetter",
			yourTime: "Deine Zeit:",
			serverTime: "Server Zeit:",
			city: "Stadt:",
			temperature: "Temperatur:",
			humidity: "Luftfeuchtigkeit:",
			wind: "Wind:",
			precipitation: "Niederschlag:",
			pressure: "Druck:",
			cloud: "Wolke:",
			uv: "UV:",
			lastUpdate: "Letzte Aktualisierung:",
		},
		art: {
			title: "Kunst",
			goCheck: "Geh es dir ansehen:",
		},
		visitors: {
			title: "Besucher",
			total: "Gesamtbesucher:",
			unique: "Einzigartige Besucher:",
			returning: "Wiederkehrende Besucher:",
		},
		projects: {
			title: "Projekte",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"Ein Discord-Bot, um KI-Kunst zu generieren oder Bilder zu generieren, Sie können ihn auch verwenden, um Informationen über einen Film oder eine TV-Show zu erhalten oder ein Zitat zu erhalten",
					buttonsText: ["Mieten Sie mich auf Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Eine Terminalanwendung, die Ihren PC mit einfachen Anweisungen steuern kann, sie verwendet das GPT-3-Modell, um die Anweisungen zu verstehen und auszuführen. Sie kann Dinge wie ein Programm öffnen, ein Programm schließen, einen Screenshot machen und mehr.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Eine Terminalanwendung, die die Modelle GPT-3 und Whisper verwendet, um eine Zusammenfassung eines Zoom-Meetings zu generieren, sie kann auch eine Zusammenfassung eines YouTube-Videos, eines Podcasts oder eines beliebigen Videos generieren.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Eine Tagebuch-App, die KI verwendet, um Ihre Notizen zu analysieren. Sie verwendet ein KI-Modell, um die Informationen und Ziele in Ihren Notizen zu analysieren, um Ihnen beim Autocompleten, bei der Bereitstellung von Vorschlägen, Ideen und mehr zu helfen.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Ein E-Mail-Client, Indexer und Suchmaschine für die Enron-Datenbank. Er verwendet einen benutzerdefinierten Indexer und eine Suchmaschine, um nach E-Mails in der Enron-Datenbank zu suchen.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description:
						"Ein lustiges mathematisches Puzzle, das in c++ erstellt wurde.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot ist eine Python-Bibliothek, die entwickelt wurde, um die Erstellung von KI-gestützten Bots mithilfe der OpenAI-API zu erleichtern. Diese Bibliothek bietet eine einfache Schnittstelle, um verschiedene Funktionen zu integrieren und einen anpassbaren Assistenten zu erstellen.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Fähigkeiten",
			status: "Systemstatus:",
		},
		aiChat: {
			title: "AI Chat",
			chat: "Hallo, ich bin Nervesscat 😼, ich kann Ihnen bei allem helfen, was Sie über diese Website wissen möchten. Geben Sie /help ein, um weitere Optionen zu sehen.",
		},
		errorRouter: {
			title: "Die Seite existiert nicht",
			description: [
				"Klicken Sie hier",
				"oder warten Sie",
				"Sekunden umzuleiten",
			],
		},
		connFailed: {
			title: "Die Verbindung hat Probleme :c",
			description:
				"Wenn das Problem weiterhin besteht, werden wir Sie über unsere sozialen Medien informieren.",
			support: "Unterstützen Sie uns auf Kofi (✿◡‿◡)",
		},
	},
	it: {
		bio: {
			welcome: "Benvenuto su nervesscat",
			pageWork: "Pagina ancora in costruzione!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat ha finito i soldi 😿. Non possiamo pagare Google-san, quindi le funzionalità del sito web sono limitate per ora. Per favore, considera di supportarci su Ko-fi!!",
			description: [
				"Programmo molte cose e sono sempre entusiasta delle nuove tecnologie e di creare nuove cose. Sono uno sviluppatore full stack, in questa pagina puoi vedere i miei ultimi progetti e altri hobby, se vuoi contattarmi, sentiti libero di chiedermi nei miei social media (o゜▽゜)o☆.",
				"Ho iniziato a programmare a 10 anni, ho iniziato con Java, attualmente conosco molti linguaggi come",
				"e altro. Sono una persona autodidatta, amo imparare cose nuove e sono sempre alla ricerca di nuove sfide.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "FATTI RAPIDI:",
			facts: [
				"Ho 22 anni.",
				"Vengo da Honduras.",
				"Attualmente sto studiando Ingegneria dei Sistemi all'UNAH.",
				"I miei hobby sono la produzione musicale 🎹, l'arte 🎨, il design grafico e la programmazione!!.",
				"Sto creando musica per il mio primo videogioco. (✿◡‿◡)",
				"Sono un appassionato di IA, quindi probabilmente vedrai molti progetti su questo.",
				"Programmo siti web, bot (soprattutto bot discord), e qualsiasi algoritmo o script.",
				"Mi piace molto la tecnologia analogica/retro.",
			],
			nervesscatMessage: "Nervesscat è qui!!",
		},
		stars: {
			title: "Contatto",
		},
		settings: {
			title: "Impostazioni",
			themes: "Temi",
			language: "Scegli una lingua",
		},
		status: {
			title: "Stato",
			online: "Online",
			offline: "Offline",
			lastConnection: "Ultima Connessione:",
		},
		zincNet: {
			title: "Visita ZincNet!!!",
		},
		weather: {
			title: "Il Mio Tempo",
			yourTime: "La Tua Ora:",
			serverTime: "Ora del Server:",
			city: "Città:",
			temperature: "Temperatura:",
			humidity: "Umidità:",
			wind: "Vento:",
			precipitation: "Precipitazione:",
			pressure: "Pressione:",
			cloud: "Nuvola:",
			uv: "UV:",
			lastUpdate: "Ultimo Aggiornamento:",
		},
		art: {
			title: "Arte",
			goCheck: "Vai a controllare:",
		},
		visitors: {
			title: "Visitatori",
			total: "Visitatori Totali:",
			unique: "Visitatori Unici:",
			returning: "Visitatori che Ritornano:",
		},
		projects: {
			title: "Progetti",
			projects: [
				{
					title: "Bot IA per Discord",
					description:
						"Un bot discord per generare arte IA o per generare immagini, puoi anche usarlo per ottenere informazioni su un film o una serie TV, o per ottenere una citazione casuale",
					buttonsText: ["Assumimi su Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Un'applicazione terminale che può controllare il tuo pc con semplici istruzioni, utilizza il modello GPT-3 per comprendere le istruzioni ed eseguirle. Può fare cose come aprire un programma, chiudere un programma, fare uno screenshot e altro.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Un'applicazione terminale che utilizza i modelli GPT-3 e Whisper per generare un riassunto di una riunione zoom, può anche generare un riassunto di un video di youtube, un podcast, o un qualsiasi video.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Un'applicazione di diario che utilizza l'IA per analizzare le tue note. Utilizza un modello di IA per analizzare le informazioni e gli obiettivi delle tue note per aiutarti a completare automaticamente, darti suggerimenti, idee e altro.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Un client di posta, indicizzatore e motore di ricerca per il Database Enron. Utilizza un indicizzatore e un motore di ricerca personalizzato per cercare e-mail nel Database Enron.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description: "Un divertente rompicapo matematico creato in c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot è una libreria Python progettata per facilitare la creazione di bot basati sull'intelligenza artificiale utilizzando l'API di OpenAI. Questa libreria fornisce un'interfaccia semplice per integrare varie funzionalità e creare un assistente personalizzabile.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Competenze",
			status: "Stato del Sistema:",
		},
		aiChat: {
			title: "Chat IA",
			chat: "Ciao, sono Nervesscat 😼, posso aiutarti con qualsiasi cosa tu voglia sapere su questo sito web. Digita /help per vedere altre opzioni.",
		},
		errorRouter: {
			title: "La pagina non esiste",
			description: ["Clicca Qui", "o aspetta", "secondi per reindirizzare"],
		},
		connFailed: {
			title: "La connessione ha dei problemi :c",
			description:
				"Se il problema persiste, ti informeremo nei nostri social media.",
			support: "Supportaci su Kofi (✿◡‿◡)",
		},
	},
	ru: {
		bio: {
			welcome: "Добро пожаловать на nervesscat",
			pageWork: "Страница все еще в работе!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"У Nervesscat закончились деньги 😿. Мы не можем заплатить Google-san, поэтому функциональность сайта ограничена. Пожалуйста, поддержите нас на Ko-fi!!",
			description: [
				"Я пишу много вещей и всегда в восторге от новых технологий и создания новых вещей. Я full stack разработчик, на этой странице вы можете увидеть мои последние проекты и другие хобби, если вы хотите связаться со мной, не стесняйтесь спрашивать меня в моих социальных сетях (o゜▽゜)o☆.",
				"Я начал программировать в 10 лет, я начал с Java, в настоящее время я знаю много языков, таких как",
				"и другие. Я самоучка, я люблю учиться новым вещам и всегда ищу новые вызовы.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "БЫСТРЫЕ ФАКТЫ:",
			facts: [
				"Мне 22 год.",
				"Я из Гондураса.",
				"В настоящее время я учусь на инженера систем в UNAH.",
				"Мои хобб и это музыкальное производство 🎹, искусство 🎨, графический дизайн и программирование!!.",
				"Я создаю музыку для своей первой видеоигры. (✿◡‿◡)",
				"Я фанат ИИ, так что вы, вероятно, увидите много проектов на эту тему.",
				"Я пишу веб-сайты, ботов (особенно ботов discord) и любой алгоритм или скрипт.",
				"Мне очень нравится аналоговая/ретро технология.",
			],
			nervesscatMessage: "Nervesscat здесь!!",
		},
		stars: {
			title: "Контакт",
		},
		settings: {
			title: "Настройки",
			themes: "Темы",
			language: "Выберите язык",
		},
		status: {
			title: "Статус",
			online: "Онлайн",
			offline: "Офлайн",
			lastConnection: "Последнее Подключение:",
		},
		zincNet: {
			title: "Посетите ZincNet!!!",
		},
		weather: {
			title: "Моя Погода",
			yourTime: "Ваше Время:",
			serverTime: "Время Сервера:",
			city: "Город:",
			temperature: "Температура:",
			humidity: "Влажность:",
			wind: "Ветер:",
			precipitation: "Осадки:",
			pressure: "Давление:",
			cloud: "Облако:",
			uv: "УФ:",
			lastUpdate: "Последнее Обновление:",
		},
		art: {
			title: "Искусство",
			goCheck: "Пойди проверь:",
		},
		visitors: {
			title: "Посетители",
			total: "Всего Посетителей:",
			unique: "Уникальные Посетители:",
			returning: "Возвращающиеся Посетители:",
		},
		projects: {
			title: "Проекты",
			projects: [
				{
					title: "Бот ИИ для Discord",
					description:
						"Бот discord для генерации искусства ИИ или изображений, вы также можете использовать его для получения информации о фильме или телешоу или для получения случайной цитаты",
					buttonsText: ["Наймите меня на Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Терминальное приложение, которое может управлять вашим компьютером простыми инструкциями, оно использует модель GPT-3 для понимания инструкций и их выполнения. Он может делать такие вещи, как открытие программы, закрытие программы, снятие скриншота и многое другое.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Терминальное приложение, которое использует модели GPT-3 и Whisper для генерации резюме зум-собрания, оно также может генерировать резюме видео youtube, подкаста или любого видео.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Приложение для ведения дневника, которое использует ИИ для анализа ваших заметок. Оно использует модель ИИ для анализа информации и целей в ваших заметках, чтобы помочь вам автозаполнением, давать вам предложения, идеи и многое другое.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Почтовый клиент, индексатор и поисковая система для базы данных Enron. Он использует специальный индексатор и поисков ая система для поиска электронных писем в базе данных Enron.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description: "Забавная математическая головоломка, созданная на c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot — это библиотека Python, созданная для упрощения создания ботов на базе ИИ с использованием API OpenAI. Эта библиотека предоставляет простой интерфейс для интеграции различных функций и создания настраиваемого помощника.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Навыки",
			status: "Статус Системы:",
		},
		aiChat: {
			title: "Чат ИИ",
			chat: "Привет, я Nervesscat 😼, я могу помочь вам с любыми вопросами о этом сайте. Введите /help, чтобы увидеть больше вариантов.",
		},
		errorRouter: {
			title: "Страница не существует",
			description: [
				"Нажмите Здесь",
				"или подождите",
				"секунд для перенаправления",
			],
		},
		connFailed: {
			title: "У соединения проблемы :c",
			description:
				"Если проблема сохраняется, мы сообщим вам в наших социальных сетях.",
			support: "Поддержите нас на Kofi (✿◡‿◡)",
		},
	},
	zh: {
		bio: {
			welcome: "欢迎来到nervesscat",
			pageWork: "页面仍在建设中!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat 没钱了 😿。我们无法支付给 Google-san，因此网站的功能暂时受限。请考虑在 Ko-fi 上支持我们!!",
			description: [
				"我写很多东西，总是对新技术和创造新东西感到兴奋。我是一个全栈开发者，在这个页面上，您可以看到我的最新项目和其他爱好，如果您想联系我，请随时在我的社交媒体上问我 (o゜▽゜)o☆。",
				"我10岁开始编程，我从Java开始，现在我知道很多语言，比如",
				"等等。我是一个自学者，喜欢学习新东西，总是在寻找新挑战。",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "快速事实:",
			facts: [
				"我21岁。",
				"我来自洪都拉斯。",
				"我目前在UNAH学习系统工程。",
				"我的爱好是音乐制作🎹、艺术🎨、平面设计和编程!!。",
				"我正在为我的第一个视频游戏创作音乐。(✿◡‿◡)",
				"我是一个AI迷，所以你可能会看到很多关于这方面的项目。",
				"我写网站、机器人（尤其是discord机器人）和任何算法或脚本。",
				"我非常喜欢模拟/复古技术。",
			],
			nervesscatMessage: "Nervesscat在这里!!",
		},
		stars: {
			title: "联系",
		},
		settings: {
			title: "设置",
			themes: "主题",
			language: "选择语言",
		},
		status: {
			title: "状态",
			online: "在线",
			offline: "离线",
			lastConnection: "最后连接:",
		},
		zincNet: {
			title: "访问ZincNet!!!",
		},
		weather: {
			title: "我的天气",
			yourTime: "你的时间:",
			serverTime: "服务器时间:",
			city: "城市:",
			temperature: "温度:",
			humidity: "湿度:",
			wind: "风:",
			precipitation: "降水:",
			pressure: "压力:",
			cloud: "云:",
			uv: "紫外线:",
			lastUpdate: "最后更新:",
		},
		art: {
			title: "艺术",
			goCheck: "去看看:",
		},
		visitors: {
			title: "访客",
			total: "总访客:",
			unique: "独特访客:",
			returning: "回访访客:",
		},
		projects: {
			title: "项目",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"一个discord机器人，用于生成AI艺术或生成图像，您还可以使用它获取有关电影或电视节目的信息，或获取随机引用",
					buttonsText: ["在Fiverr上雇佣我"],
				},
				{
					title: "VisionGPT",
					description:
						"一个终端应用程序，可以通过简单的指令控制您的计算机，它使用GPT-3模型来理解指令并执行。它可以做一些事情，比如打开一个程序、关闭一个程序、截屏等。",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"一个终端应用程序，使用GPT-3和Whisper模型生成Zoom会议摘要，还可以生成YouTube视频、播客或任何视频的摘要。",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"一个使用AI分析您的笔记的日记应用。它使用AI模型分析您的笔记中的信息和目标，以帮助您自动完成、提供建议、想法等。",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"一个电子邮件客户端、索引器和Enron数据库的搜索引擎。它使用自定义索引器和搜索引擎在Enron数据库中搜索电子邮件。",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description: "一个有趣的数学谜题，用c++创建。",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot 是一个 Python 库，旨在通过 OpenAI 的 API 来简化 AI 驱动的机器人创建。该库提供了一个简单的接口，可以集成各种功能并创建可定制的助手。",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "技能",
			status: "系统状态:",
		},
		aiChat: {
			title: "AI聊天",
			chat: "你好，我是Nervesscat 😼，我可以帮助你了解这个网站上的任何信息。输入/help以查看更多选项。",
		},
		errorRouter: {
			title: "页面不存在",
			description: ["点击这里", "或等待", "秒钟重定向"],
		},
		connFailed: {
			title: "连接有问题 :c",
			description: "如果问题仍然存在，我们将在我们的社交媒体上通知您。",
			support: "在Kofi上支持我们 (✿◡‿◡)",
		},
	},
	ja: {
		bio: {
			welcome: "nervesscatへようこそ",
			pageWork: "ページはまだ作業中です！(⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat はお金がなくなりました 😿。Google-san に支払うことができないので、現在ウェブサイトの機能が制限されています。どうか Ko-fi で私たちをサポートしてください!!",
			description: [
				"私はたくさんのコードを書いており、新しいテクノロジーに興奮して新しいものを作ることに常に興味を持っています。私はフルスタック開発者で、このページでは私の最新のプロジェクトや他の趣味を見ることができます。お問い合わせがある場合は、気軽にソーシャルメディアで聞いてください(o゜▽゜)o☆。",
				"私は10歳の頃からプログラミングを始めました。Javaから始め、現在は多くの言語を知っています。",
				"その他。私は独学派で、新しいことを学ぶことが大好きで、常に新しいチャレンジを探しています。",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "クイックファクト:",
			facts: [
				"21歳です。",
				"ホンジュラス出身です。",
				"UNAHでシステムエンジニアリングを学んでいます。",
				"趣味は音楽制作 🎹、アート 🎨、グラフィックデザイン、コーディングです。",
				"初めてのビデオゲーム用の音楽を作成中です。(✿◡‿◡)",
				"AI好きなので、多くのAIプロジェクトを見ることができます。",
				"ウェブサイト、ボット（特にディスコードボット）、アルゴリズムやスクリプトをコーディングします。",
				"アナログ/レトロ技術が大好きです。",
			],
			nervesscatMessage: "Nervesscatがここにいます!!",
		},
		stars: {
			title: "連絡先",
		},
		settings: {
			title: "設定",
			themes: "テーマ",
			language: "言語を選択",
		},
		status: {
			title: "状態",
			online: "オンライン",
			offline: "オフライン",
			lastConnection: "前回の接続:",
		},
		zincNet: {
			title: "ZincNetを訪問してください！！！",
		},
		weather: {
			title: "私の天気",
			yourTime: "あなたの時間:",
			serverTime: "サーバーの時間:",
			city: "市:",
			temperature: "温度:",
			humidity: "湿度:",
			wind: "風:",
			precipitation: "降水量:",
			pressure: "気圧:",
			cloud: "クラウド:",
			uv: "UV:",
			lastUpdate: "最終更新:",
		},
		art: {
			title: "アート",
			goCheck: "確認してください:",
		},
		visitors: {
			title: "訪問者",
			total: "合計訪問者:",
			unique: "ユニーク訪問者:",
			returning: "再訪問者:",
		},
		projects: {
			title: "プロジェクト",
			projects: [
				{
					title: "Discord AIボット",
					description:
						"AIアートを生成したり、画像を生成したりするためのDiscordボット、また、映画やテレビ番組に関する情報を取得したり、ランダムな引用を入手したりすることもできます。",
					buttonsText: ["Fiverrで雇う"],
				},
				{
					title: "VisionGPT",
					description:
						"PCをシンプルな指示で制御できるターミナルアプリケーション。 GPT-3モデルを使用して指示を理解し、実行します。 プログラムを開く、プログラムを閉じる、スクリーンショットを撮るなどのことができます。",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"GPT-3およびWhisperモデルを使用して、Zoomミーティングの要約を生成するターミナルアプリケーション。 YouTubeビデオ、ポッドキャスト、または任意のビデオの要約も生成できます。",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"AIを使用してノートを分析するジャーナルアプリ。 AIモデルを使用して、ノート内の情報や目標を分析し、自動入力、提案、アイデアなどを表示してサポートします。",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Enronデータベースのメールクライアント、インデクサー、検索エンジン。 カスタムのインデクサーと検索エンジンを使用して、Enronデータベース内のメールを検索します。",
					buttonsText: ["Github"],
				},
				{
					title: "回転パズル",
					description: "c++で作成された楽しい数学パズル。",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBotは、OpenAIのAPIを使用してAIを活用したボットの作成を容易にするために設計されたPythonライブラリです。このライブラリは、さまざまな機能を統合し、カスタマイズ可能なアシスタントを作成するためのシンプルなインターフェースを提供します。",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "スキル",
			status: "システムの状態:",
		},
		aiChat: {
			title: "AIチャット",
			chat: "こんにちは、私はNervesscat 😼です。このサイトについて知りたいことがあればお手伝いします。/helpと入力してさらなるオプションを確認してください。",
		},
		errorRouter: {
			title: "ページが存在しません",
			description: [
				"こちらをクリック",
				"または待ってください",
				"秒後にリダイレクトします",
			],
		},
		connFailed: {
			title: "接続に問題が発生しました :c",
			description:
				"問題が解消しない場合は、ソーシャルメディアでお知らせします。",
			support: "Kofiでサポートしてください (✿◡‿◡)",
		},
	},
	ko: {
		bio: {
			welcome: "nervesscat에 오신 것을 환영합니다",
			pageWork: "페이지는 아직 작업 중입니다!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat 자금이 떨어졌습니다 😿. Google-san에게 지불할 수 없어서 현재 웹사이트 기능이 제한되어 있습니다. Ko-fi에서 저희를 지원해 주세요!!",
			description: [
				"많은 것을 코딩하고 새로운 기술에 대해 항상 흥분하며 새로운 것을 만드는 것에 흥미를 느낍니다. 저는 풀 스택 개발자이며, 이 페이지에서는 나의 최근 프로젝트와 다른 취미를 볼 수 있습니다. 저에게 연락하고 싶다면 언제든지 소셜 미디어에서 물어보세요 (o゜▽゜)o☆.",
				"제가 10살 때부터 프로그래밍을 시작했고, Java로 시작해서 현재 여러 언어를 알고 있습니다",
				"그리고 더 많습니다. 제가 자급자족형이며, 새로운 것을 배우는 것을 좋아하며 언제나 새로운 도전을 찾고 있습니다.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "빠른 사실:",
			facts: [
				"제 나이는 21세입니다.",
				"저는 온두라스 출신입니다.",
				"저는 현재 UNAH에서 시스템 공학을 공부 중입니다.",
				"취미는 음악 제작 🎹, 예술 🎨, 그래픽 디자인 및 코딩입니다!!.",
				"첫 번째 비디오 게임을 위해 음악을 작성 중입니다. (✿◡‿◡)",
				"나는 AI 초보자이기 때문에 아마도 많은 AI 프로젝트를 보게 될 것입니다.",
				"웹사이트, 봇(특히 디스코드 봇) 및 모든 알고리즘이나 스크립트를 코딩합니다.",
				"나는 아날로그/레트로 기술을 정말 좋아합니다.",
			],
			nervesscatMessage: "Nervesscat 여기 있어요!!",
		},
		stars: {
			title: "연락처",
		},
		settings: {
			title: "설정",
			themes: "테마",
			language: "언어 선택",
		},
		status: {
			title: "상태",
			online: "온라인",
			offline: "오프라인",
			lastConnection: "마지막 연결:",
		},
		zincNet: {
			title: "ZincNet 방문하세요!!!",
		},
		weather: {
			title: "나의 날씨",
			yourTime: "당신의 시간:",
			serverTime: "서버 시간:",
			city: "도시:",
			temperature: "온도:",
			humidity: "습도:",
			wind: "바람:",
			precipitation: "강수량:",
			pressure: "압력:",
			cloud: "구름:",
			uv: "자외선:",
			lastUpdate: "최근 업데이트:",
		},
		art: {
			title: "아트",
			goCheck: "확인하러 가기:",
		},
		visitors: {
			title: "방문자",
			total: "총 방문자 수:",
			unique: "고유 방문자:",
			returning: "재방문자:",
		},
		projects: {
			title: "프로젝트",
			projects: [
				{
					title: "Discord AI 봇",
					description:
						"AI 아트를 생성하거나 이미지를 생성하거나 영화 또는 TV 프로그램에 대한 정보를 얻거나 랜덤 인용구를 얻을 수 있는 Discord 봇입니다",
					buttonsText: ["Fiverr에서 고용하기"],
				},
				{
					title: "VisionGPT",
					description:
						"단순한 명령으로 PC를 제어할 수 있는 터미널 응용 프로그램. GPT-3 모델을 사용하여 명령을 이해하고 실행합니다. 프로그램 열기, 프로그램 닫기, 스크린샷 찍기 등이 가능합니다.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Zoom 미팅을 요약하거나 YouTube 비디오, 팟캐스트 또는 임의의 비디오의 요약을 생성하는 터미널 응용 프로그램입니다",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"노트를 분석하기 위해 AI를 사용하는 일지 앱입니다. AI 모델을 사용하여 노트의 정보와 목표를 분석하여 자동 완성, 제안, 아이디어 등을 제공하여 지원합니다.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Enron 데이터베이스의 메일 클라이언트, 색인 및 검색 엔진. Enron 데이터베이스에서 이메일을 검색하기 위해 사용자 지정 색인 및 검색 엔진을 사용합니다.",
					buttonsText: ["Github"],
				},
				{
					title: "회전 큐브",
					description: "C++로 만든 재미있는 수학 퍼즐.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot은 OpenAI API를 사용하여 AI 기반 봇을 쉽게 만들 수 있도록 설계된 Python 라이브러리입니다. 이 라이브러리는 다양한 기능을 통합하고 맞춤형 어시스턴트를 만들기 위한 간단한 인터페이스를 제공합니다.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "기술",
			status: "시스템 상태:",
		},
		aiChat: {
			title: "AI 채팅",
			chat: "안녕하세요, 저는 Nervesscat 😼입니다. 이 웹사이트에 대해 알고 싶은 것이 있으면 도와드리겠습니다. 더 많은 옵션을 보려면 /help를 입력하세요.",
		},
		errorRouter: {
			title: "페이지가 존재하지 않습니다",
			description: [
				"여기를 클릭하세요",
				"또는 기다려주세요",
				"초 후 리디렉션됩니다",
			],
		},
		connFailed: {
			title: "연결에 문제가 있습니다 :c",
			description: "문제가 지속되면 소셜 미디어에서 알려드리겠습니다.",
			support: "Kofi에서 지원하기 (✿◡‿◡)",
		},
	},
	hi: {
		bio: {
			welcome: "नर्वस्स्कैट में आपका स्वागत है",
			pageWork: "पेज अभी काम में है!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat के पास पैसे खत्म हो गए 😿। हम Google-san को भुगतान नहीं कर सकते, इसलिए वेबसाइट की सुविधाएं फिलहाल सीमित हैं। कृपया हमें Ko-fi पर समर्थन दें!!",
			description: [
				"मैं कई चीजें कोड करता हूं और मैं हमेशा नई तकनीकों के बारे में उत्सुक रहता हूं और नई चीजें बनाने में। मैं एक पूर्ण स्टैक डेवलपर हूँ, इस पेज पर आप मेरे अंतिम प्रोजेक्ट्स और अन्य रुचियों को देख सकते हैं, यदि आप मुझसे संपर्क करना चाहते हैं, तो मेरे सोशल मीडिया में मुझसे पूछने में स्वतंत्र महसूस करें (o゜▽゜)o☆।",
				"मैं 10 साल की उम्र में ही प्रोग्रामिंग शुरू कर दी थी, मैंने जावा के साथ शुरू किया था, वर्तमान में मुझे C++, Python, Javascript, Java, Go, Visual Basic आदि जैसी कई भाषाएं आती हैं।",
				"मैं एक स्व-शिक्षित व्यक्ति हूं, मुझे नई चीजें सीखने का बहुत शौक है और मैं हमेशा नए चुनौतियों की तलाश में रहता हूं।",
			],
			languages: [
				"सी++",
				"पायथन",
				"जावास्क्रिप्ट",
				"जावा",
				"गो",
				"विजुअल बेसिक",
			],
			quickFacts: "त्वरित तथ्य:",
			facts: [
				"मैं 22 साल का हूं।",
				"मैं हौंडुरास से हूं।",
				"मैं वर्तमान में यूएनएएच में सिस्टम इंजीनियरिंग पढ़ रहा हूं।",
				"मेरा शौक संगीत उत्पादन 🎹, कला 🎨, ग्राफिक डिजाइन और कोडिंग है!!।",
				"मैं अपने पहले वीडियोगेम के लिए संगीत बना रहा हूं। (✿◡‿◡)",
				"मैं AI नर्ड हूं, तो आप शायद मेरे बारे में कई परियोजनाएं देखेंगे।",
				"मैं वेबसाइट, बॉट (विशेष रूप से डिस्कॉर्ड बॉट) और किसी भी एल्गोरिदम या स्क्रिप्ट कोड करता हूं।",
				"मुझे अनालॉग / रेट्रो प्रौद्योगिकी बहुत पसंद है।",
			],
			nervesscatMessage: "नर्वस्स्कैट यहाँ है!!",
		},
		stars: {
			title: "संपर्क करें",
		},
		settings: {
			title: "सेटिंग्स",
			themes: "थीम",
			language: "भाषा चुनें",
		},
		status: {
			title: "स्थिति",
			online: "ऑनलाइन",
			offline: "ऑफ़लाइन",
			lastConnection: "पिछला कनेक्शन:",
		},
		zincNet: {
			title: "ZincNet पर जाएं!!!",
		},
		weather: {
			title: "मेरा मौसम",
			yourTime: "आपका समय:",
			serverTime: "सर्वर का समय:",
			city: "शहर:",
			temperature: "तापमान:",
			humidity: "नमी:",
			wind: "हवा:",
			precipitation: "वर्षा:",
			pressure: "दबाव:",
			cloud: "बादल:",
			uv: "UV:",
			lastUpdate: "अंतिम अद्यतन:",
		},
		art: {
			title: "कला",
			goCheck: "जाँच करें:",
		},
		visitors: {
			title: "आगंतुकों",
			total: "कुल आगंतुक:",
			unique: "अद्वितीय आगंतुक:",
			returning: "वापस आ रहे आगंतुक:",
		},
		projects: {
			title: "प्रोजेक्ट्स",
			projects: [
				{
					title: "डिसकोर्ड AI बॉट",
					description:
						"एक डिस्कोर्ड बॉट द्वारा AI कला उत्पन्न करने या चित्र उत्पन्न करने के लिए। इसके अलावा, आप इसे एक फिल्म या टीवी शो के बारे में जानकारी प्राप्त करने के लिए या एक यादृच्छिक कोट प्राप्त करने के लिए इस्तेमाल कर सकते हैं",
					buttonsText: ["Fiverr पर मुझे किराए पर लो"],
				},
				{
					title: "VisionGPT",
					description:
						"एक टर्मिनल एप्लिकेशन जो सरल निर्देशों द्वारा आपके पीसी को नियंत्रित कर सकती है। यह GPT-3 मॉडल का उपयोग निर्देशों को समझने और उन्हें क्रियान्वयन करने के लिए करता है। यह चीजें कर सकता हैं जैसे कि एक प्रोग्राम खोलना, एक प्रोग्राम बंद करना, एक स्क्रीनशॉट लेना और अधिक।",
					buttonsText: ["गिटहब"],
				},
				{
					title: "Zoomarizer",
					description:
						"GPT-3 और Whisper मॉडल का उपयोग करके ज़ूम मीटिंग की सारांशिकीरण उत्पन्न करने वाला एक टर्मिनल एप्लिकेशन। यह एक youtube वीडियो, पॉडकास्ट, या किसी भी वीडियो की सारांशिकीरण भी उत्पन्न कर सकता है।",
					buttonsText: ["गिटहब"],
				},
				{
					title: "DiarAIst",
					description:
						"एक जर्नल ऐप जो आपके नोट्स को विश्लेषण करने के लिए AI का उपयोग करता है। यह एक एआई मॉडल का उपयोग करके आपके नोट्स में जानकारी और लक्ष्यों का विश्लेषण करता है और आपको स्वत: पूर्ति करने, सुझाव, विचार आदि देने में मदद करता है।",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"ईमेल क्लाइंट, इंडेक्सर और Enron डेटाबेस के लिए एक सर्च इंजन। यह एक कस्टम इंडेक्सर और सर्च इंजन का उपयोग करके Enron डेटाबेस में ईमेल खोजने के लिए उपयोग करता है।",
					buttonsText: ["गिटहब"],
				},
				{
					title: "रोटेटिंग क्यूब",
					description: "सी++ में बनाई गई एक मजेदार गणित पहेली।",
					buttonsText: ["गिटहब"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot एक Python लाइब्रेरी है, जिसे OpenAI के API का उपयोग करके AI-संचालित बॉट्स बनाने की प्रक्रिया को सरल बनाने के लिए डिज़ाइन किया गया है। यह लाइब्रेरी विभिन्न कार्यक्षमताओं को एकीकृत करने और एक अनुकूलन योग्य सहायक बनाने के लिए एक सरल इंटरफ़ेस प्रदान करती है।",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "कौशल",
			status: "सिस्टम की स्थिति:",
		},
		aiChat: {
			title: "AI चैट",
			chat: "नमस्ते, मैं Nervesscat 😼 हूँ, मैं इस वेबसाइट के बारे में जानने के लिए आपकी सहायता कर सकता हूँ। और विकल्प देखने के लिए /help टाइप करें।",
		},
		errorRouter: {
			title: "पेज मौजूद नहीं है",
			description: [
				"यहाँ क्लिक करें",
				"या प्रतीक्षा करें",
				"सेकंड में पुनर्निर्देशित होने के लिए",
			],
		},
		connFailed: {
			title: "कनेक्शन में कुछ समस्या है :c",
			description:
				"यदि समस्या परिणामस्वरूप बरकरार रहती है, हम आपको हमारे सोशल मीडिया में सूचित करेंगे।",
			support: "हमें Kofi पर समर्थन करें (✿◡‿◡)",
		},
	},
	fil: {
		bio: {
			welcome: "Maligayang pagdating sa nervesscat",
			pageWork: "Ang pahina ay kasalukuyang ginagawa!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat naubusan ng pera 😿. Hindi namin mabayaran si Google-san, kaya limitado ang mga functionality ng website sa ngayon. Mangyaring isaalang-alang ang pagsuporta sa amin sa Ko-fi!!",
			description: [
				"Ako ay nagko-code ng maraming mga bagay at laging nae-excite sa mga bagong teknolohiya at paggawa ng mga bagong bagay. Ako ay isang full stack developer, Sa pahinang ito, maaari mong makita ang aking mga huling proyekto at iba pang mga hilig, kung nais mong makipag-ugnayan sa akin, huwag kang mag-atubili na magtanong sa akin sa aking social media (o゜▽゜)o☆.",
				"Nagsimula akong mag-program nang ako'y 10 taong gulang pa lamang, nagsimula ako sa Java, kasalukuyan kong alam ang iba't ibang mga wika tulad ng",
				"at marami pa. Ako ay isang self-taught na tao, gustong-gusto ko matuto ng mga bagong bagay at palaging naghahanap ng mga bagong hamon.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "MGA MABILIS NA KATOTOHANAN:",
			facts: [
				"Ako ay 22 taong gulang.",
				"Ako ay taga-Honduras.",
				"Kasalukuyan kong kinukuha ang kurso sa System Engineering sa UNAH.",
				"Ako ay mahilig sa paggawa ng musika 🎹, sining 🎨, graphic design at codiing!!.",
				"Ako ay gumagawa ng musika para sa aking unang video game. (✿◡‿◡)",
				"Ako ay isang AI nerd, kaya malamang na makakakita ka ng maraming proyekto tungkol dito.",
				"Ako ay gumagawa ng mga website, bots (lalo na ang mga discord bot), at alinman sa algorithm o script.",
				"Ako ay mahilig sa analog/retro na teknolohiya.",
			],
			nervesscatMessage: "Nandito si Nervesscat!!",
		},
		stars: {
			title: "Makipag-ugnayan",
		},
		settings: {
			title: "Mga Setting",
			themes: "Mga Tema",
			language: "Pumili ng Wika",
		},
		status: {
			title: "Kalagayan",
			online: "Online",
			offline: "Offline",
			lastConnection: "Huling Koneksyon:",
		},
		zincNet: {
			title: "Dalawin ang ZincNet!!!",
		},
		weather: {
			title: "Aking Panahon",
			yourTime: "Iyong Oras:",
			serverTime: "Oras ng Server:",
			city: "Lungsod:",
			temperature: "Temperatura:",
			humidity: "Kahalumigmigan:",
			wind: "Hangin:",
			precipitation: "Kulog:",
			pressure: "Presyon:",
			cloud: "Ulap:",
			uv: "UV:",
			lastUpdate: "Huling Pagsasaayos:",
		},
		art: {
			title: "Sining",
			goCheck: "Pumunta at tingnan:",
		},
		visitors: {
			title: "Mga Bisita",
			total: "Kabuuang Bisita:",
			unique: "Natatanging Bisita:",
			returning: "Bumabalik na Mga Bisita:",
		},
		projects: {
			title: "Mga Proyekto",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"Isang discord bot upang lumikha ng AI Art o lumikha ng mga larawan, maaring gamitin din ito upang kunin ang impormasyon tungkol sa isang pelikula o isang tv show, o upang makakuha ng isang random na quote",
					buttonsText: ["Upahan Ako sa Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Isang terminal application na maaaring kontrolin ang iyong pc sa pamamagitan ng simpleng mga tagubilin, ginagamit nito ang modelo ng GPT-3 para maunawaan ang mga tagubilin at ipatupad ang mga ito. Maaari itong gumawa ng mga bagay tulad ng magbukas ng programa, isara ang isang programa, kumuha ng screenshot, at marami pa.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Isang terminal application na gumagamit ng mga modelo ng GPT-3 at Whisper upang lumikha ng isang buod ng isang zoom meeting, maaari din itong lumikha ng isang buod ng isang youtube video, isang podcast o anumang video.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Isang journal app na gumagamit ng AI upang suriin ang iyong mga notes. Ginagamit nito ang isang AI modelo upang suriin ang impormasyon at mga layunin sa iyong mga notes upang matulungan kang mag-autocomplete, magbigay ng mga mungkahi, mga ideya, at marami pa.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Isang mail client, indexer at search engine para sa Enron Database. Ginagamit nito ang isang custom indexer at search engine upang maghanap ng mga email sa Enron Database.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description: "Isang nakakatawang math puzzle na ginawa sa c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"Ang EasyBot ay isang Python library na idinisenyo upang gawing mas madali ang paggawa ng mga AI-powered bot gamit ang API ng OpenAI. Ang library na ito ay nagbibigay ng simpleng interface upang isama ang iba't ibang mga kakayahan at lumikha ng isang nako-customize na assistant.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Mga Kakayahan",
			status: "Status ng System:",
		},
		aiChat: {
			title: "AI Chat",
			chat: "Kumusta, ako si Nervesscat 😼, matutulungan kita sa anumang nais mong malaman tungkol sa website na ito. I-type ang /help para makita ang iba pang mga pagpipilian.",
		},
		errorRouter: {
			title: "Ang Pahina ay Hindi umiiral",
			description: ["I-click Dito", "o Maghintay", "segundo upang i-redirekta"],
		},
		connFailed: {
			title: "May Problema sa Pagkakonekta :c",
			description:
				"Kung ang problema ay patuloy, ipagbigay-alam namin sa inyo sa aming social media.",
			support: "Suportahan kami sa Kofi (✿◡‿◡)",
		},
	},
	vi: {
		bio: {
			welcome: "Chào mừng đến với nervesscat",
			pageWork: "Trang đang trong quá trình làm việc!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat hết tiền rồi 😿. Chúng tôi không thể trả tiền cho Google-san, nên các chức năng của trang web hiện bị hạn chế. Vui lòng ủng hộ chúng tôi trên Ko-fi!!",
			description: [
				"Tôi code rất nhiều thứ và luôn hào hứng với các công nghệ mới và tạo ra những điều mới. Tôi là một lập trình viên toàn diện, Trên trang này, bạn có thể xem các dự án gần đây của tôi và sở thích khác, nếu bạn muốn liên hệ với tôi, hãy tự nhiên hỏi tôi trên mạng xã hội (o゜▽゜)o☆.",
				"Tôi bắt đầu lập trình khi tôi 10 tuổi, Tôi bắt đầu với Java, hiện tại tôi biết nhiều ngôn ngữ như",
				"và nhiều hơn nữa. Tôi là một người tự học, tôi rất thích học điều mới và luôn tìm kiếm những thách thức mới.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "THÔNG TIN NHANH:",
			facts: [
				"Tôi 22 tuổi.",
				"Tôi đến từ Honduras.",
				"Tôi đang học Kỹ thuật Hệ thống tại UNAH.",
				"Sở thích của tôi là sản xuất âm nhạc 🎹, nghệ thuật 🎨, thiết kế đồ họa và code!!.",
				"Tôi đang tạo nhạc cho trò chơi video đầu tiên của tôi. (✿◡‿◡)",
				"Tôi là một AI nerd, nên có thể bạn sẽ thấy nhiều dự án liên quan đến điều đó.",
				"Tôi code website, bot (đặc biệt là bot discord), và bất kỳ thuật toán hoặc script nào.",
				"Tôi rất thích công nghệ analog/retro.",
			],
			nervesscatMessage: "Nervesscat ở đây!!",
		},
		stars: {
			title: "Liên hệ",
		},
		settings: {
			title: "Cài đặt",
			themes: "Giao diện",
			language: "Chọn ngôn ngữ",
		},
		status: {
			title: "Trạng thái",
			online: "Trực tuyến",
			offline: "Ngoại tuyến",
			lastConnection: "Kết nối gần đây:",
		},
		zincNet: {
			title: "Ghé thăm ZincNet!!!",
		},
		weather: {
			title: "Thời tiết của tôi",
			yourTime: "Thời gian của bạn:",
			serverTime: "Thời gian máy chủ:",
			city: "Thành phố:",
			temperature: "Nhiệt độ:",
			humidity: "Độ ẩm:",
			wind: "Gió:",
			precipitation: "Mưa:",
			pressure: "Áp suất:",
			cloud: "Mây:",
			uv: "Tia tử ngoại:",
			lastUpdate: "Cập nhật gần đây:",
		},
		art: {
			title: "Nghệ thuật",
			goCheck: "Hãy kiểm tra đi:",
		},
		visitors: {
			title: "Khách thăm",
			total: "Tổng số lượt truy cập:",
			unique: "Lượt truy cập duy nhất:",
			returning: "Lượt truy cập trở lại:",
		},
		projects: {
			title: "Các dự án",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"Một discord bot để tạo ra AI Art hoặc tạo ra hình ảnh, bạn cũng có thể sử dụng nó để lấy thông tin về một bộ phim hoặc một chương trình truyền hình, hoặc để lấy một câu nói ngẫu nhiên",
					buttonsText: ["Thuê Tôi trên Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Một ứng dụng dòng lệnh có thể điều khiển máy tính của bạn bằng các chỉ thị đơn giản, nó sử dụng mô hình GPT-3 để hiểu các chỉ thị và thực hiện chúng. Nó có thể làm các công việc như mở một chương trình, đóng một chương trình, chụp ảnh màn hình và nhiều hơn nữa.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Một ứng dụng dòng lệnh sử dụng các mô hình GPT-3 và Whisper để tạo ra một bản tóm tắt về một cuộc họp trên Zoom, nó cũng có thể tạo ra một bản tóm tắt về một video youtube, một podcast hoặc bất kỳ video nào khác.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Một ứng dụng nhật ký sử dụng trí tuệ nhân tạo để phân tích ghi chú của bạn. Nó sử dụng một mô hình trí tuệ nhân tạo để phân tích thông tin và mục tiêu trong ghi chú của bạn để giúp bạn tự động hoàn thiện, đưa ra gợi ý, ý tưởng và nhiều hơn nữa.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Một client mail, indexer và công cụ tìm kiếm cho Cơ sở dữ liệu Enron. Nó sử dụng một chỉ mục và công cụ tìm kiếm tùy chỉnh để tìm kiếm email trong Cơ sở dữ liệu Enron.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description:
						"Một trò chơi đố toán toán vui nhộn được tạo ra bằng c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot là một thư viện Python được thiết kế để tạo điều kiện cho việc tạo các bot sử dụng AI bằng API của OpenAI. Thư viện này cung cấp một giao diện đơn giản để tích hợp các chức năng khác nhau và tạo ra một trợ lý tùy chỉnh.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Kỹ năng",
			status: "Trạng thái hệ thống:",
		},
		aiChat: {
			title: "Trò chuyện với AI",
			chat: "Xin chào, tôi là Nervesscat 😼, tôi có thể giúp bạn với bất cứ điều gì bạn muốn biết về trang web này. Gõ /help để xem thêm các tùy chọn.",
		},
		errorRouter: {
			title: "Trang không tồn tại",
			description: ["Nhấp vào Đây", "hoặc đợi", "giây để chuyển hướng"],
		},
		connFailed: {
			title: "Kết nối gặp một số sự cố :c",
			description:
				"Nếu vấn đề vẫn tiếp tục, chúng tôi sẽ thông báo cho bạn trong mạng xã hội của chúng tôi.",
			support: "Ủng hộ chúng tôi trên Kofi (✿◡‿◡)",
		},
	},
	th: {
		bio: {
			welcome: "ยินดีต้อนรับสู่ nervesscat",
			pageWork: "หน้ายังอยู่ในระหว่างการทำงาน!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat หมดเงินแล้ว 😿. เราไม่สามารถจ่ายให้ Google-san ได้ ดังนั้นฟังก์ชั่นการใช้งานของเว็บไซต์จึงถูกจำกัดในขณะนี้ โปรดพิจารณาสนับสนุนเราบน Ko-fi!!",
			description: [
				"ฉันเขียนโค้ดอยู่มากมายและฉันตื่นเต้นเสมอกับเทคโนโลยีใหม่ ๆ และสร้างสรรค์สิ่งใหม่ ๆ ฉันเป็นนักพัฒนา Full-stack ในหน้านี้คุณสามารถดูโปรเจกต์ล่าสุดของฉันและงานอดิเรกอื่น ๆ ได้ถ้าคุณต้องการติดต่อฉัน อย่าลังเลที่จะถามฉันในโซเชียลมีเดียของฉัน (o゜▽゜)o☆.",
				"ฉันเริ่มเขียนโปรแกรมตอนที่ฉันอายุ 10 ปี ฉันเริ่มด้วยภาษา Java ปัจจุบันฉันรู้จักภาษาหลายภาษาเช่น",
				"และอื่น ๆ ฉันเป็นคนที่เรียนรู้ด้วยตนเอง ฉันรักการเรียนรู้สิ่งใหม่ ๆ และฉันตลอดเวลามองหาความท้าทายใหม่ ๆ.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "ข้อมูลสั้น ๆ:",
			facts: [
				"ฉันอายุ 22 ปี.",
				"ฉันมาจากฮอนดูรัส.",
				"ฉันกำลังศึกษาวิศวกรรมระบบที่ UNAH.",
				"งานอดิเรกของฉันคือการผลิตเพลง 🎹 ศิลปะ 🎨 การออกแบบกราฟิกและการเขียนโค้ด!!.",
				"ฉันกำลังสร้างเพลงสำหรับเกมวิดีโอคอมพิวเตอร์ครั้งแรกของฉัน (✿◡‿◡)",
				"ฉันเป็นคนที่หลงใหลใน AI ดังนั้นคุณอาจจะเห็นโปรเจกต์เกี่ยวกับเรื่องนั้นมากมาย",
				"ฉันเขียนเว็บไซต์บอท (โดยเฉพาะบอท Discord) และอัลกอริทึมหรือสคริปต์ใด ๆ",
				"ฉันชอบเทคโนโลยีแบบแอนะล็อก / เรทโร",
			],
			nervesscatMessage: "Nervesscat มาแล้ว!!",
		},
		stars: {
			title: "ติดต่อ",
		},
		settings: {
			title: "ตั้งค่า",
			themes: "ธีม",
			language: "เลือกภาษา",
		},
		status: {
			title: "สถานะ",
			online: "ออนไลน์",
			offline: "ออฟไลน์",
			lastConnection: "การเชื่อมต่อล่าสุด:",
		},
		zincNet: {
			title: "เยี่ยมชม ZincNet !!!",
		},
		weather: {
			title: "สภาพอากาศของฉัน",
			yourTime: "เวลาของคุณ:",
			serverTime: "เวลาเซิร์ฟเวอร์:",
			city: "เมือง:",
			temperature: "อุณหภูมิ:",
			humidity: "ความชื้น:",
			wind: "ลม:",
			precipitation: "การตกน้ำ:",
			pressure: "ความกดอากาศ:",
			cloud: "เมฆ:",
			uv: "รังสี UV:",
			lastUpdate: "อัปเดตล่าสุด:",
		},
		art: {
			title: "ศิลปะ",
			goCheck: "ไปดูอันนี้:",
		},
		visitors: {
			title: "ผู้เยี่ยมชม",
			total: "ผู้เยี่ยมชมทั้งหมด:",
			unique: "ผู้เยี่ยมชมที่ไม่ซ้ำกัน:",
			returning: "ผู้เยี่ยมชมที่กลับมา:",
		},
		projects: {
			title: "โครงการ",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"บอท Discord สำหรับสร้าง AI Art หรือสร้างรูปภาพ คุณยังสามารถใช้งานได้เพื่อรับข้อมูลเกี่ยวกับภาพยนตร์หรือรายการทีวีหรือเพื่อรับคำคมสุ่ม",
					buttonsText: ["จ้างฉันที่ Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"แอปพลิเคชันทางเทอร์มินัลที่สามารถควบคุมคอมพิวเตอร์ของคุณด้วยคำสั่งง่าย ๆ มันใช้โมเดล GPT-3 เพื่อเข้าใจคำสั่งและดำเนินการ สามารถทำสิ่งต่าง ๆ เช่นเปิดโปรแกรม ปิดโปรแกรม บันทึกภาพหน้าจอ และอื่น ๆ",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"แอปพลิเคชันทางเทอร์มินัลที่ใช้โมเดล GPT-3 และ Whisper เพื่อสร้างสรุปของการประชุมผ่าน Zoom โดยสามารถสร้างสรุปของวิดีโอ YouTube, พอดคาสต์, หรือวิดีโออื่น ๆ ได้",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"แอปบันทึกประจำวันที่ใช้ AI วิเคราะห์บันทึกของคุณ ใช้โมเดล AI เพื่อวิเคราะห์ข้อมูลและเป้าหมายในบันทึกของคุณเพื่อช่วยคุณในการใส่ข้อความอัตโนมัติ ให้ข้อเสนอแนะ ไอเดีย เป็นต้น",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"ไคลเอ็นต์อีเมล ดัชนีเว็บและเครื่องมือค้นหาสำหรับฐานข้อมูล Enron ใช้เครื่องมือดัชนีและเครื่องมือค้นหาที่กำหนดเองเพื่อค้นหาอีเมลในฐานข้อมูล Enron",
					buttonsText: ["Github"],
				},
				{
					title: "หมุนจัตุรัส",
					description:
						"ประกอบไปด้วยปริศนาคณิตศาสตร์ที่สนุกสนานที่สร้างขึ้นใน c++",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot เป็นไลบรารี Python ที่ออกแบบมาเพื่อช่วยให้ง่ายต่อการสร้างบอทที่ขับเคลื่อนด้วย AI โดยใช้ OpenAI API ไลบรารีนี้มีอินเทอร์เฟซที่เรียบง่ายในการผสานรวมฟังก์ชันต่างๆ และสร้างผู้ช่วยที่ปรับแต่งได้",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "ทักษะ",
			status: "สถานะระบบ:",
		},
		aiChat: {
			title: "แชท AI",
			chat: "สวัสดี, ฉันคือ Nervesscat 😼 ฉันสามารถช่วยคุณได้ทุกเรื่องที่คุณอยากรู้เกี่ยวกับเว็บไซต์นี้ พิมพ์ /help เพื่อดูตัวเลือกเพิ่มเติม",
		},
		errorRouter: {
			title: "ไม่พบหน้า",
			description: ["คลิกที่นี่", "หรือรอ", "วินาทีเพื่อเปลี่ยนเส้นทาง"],
		},
		connFailed: {
			title: "มีปัญหาในการเชื่อมต่อ :c",
			description: "หากปัญหายังคงอยู่เราจะแจ้งให้คุณทราบในโซเชียลมีเดียของเรา",
			support: "สนับสนุนเราที่ Kofi (✿◡‿◡)",
		},
	},
	tr: {
		bio: {
			welcome: "nervesscat'a hoş geldiniz",
			pageWork: "Sayfa hala yapım aşamasında!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat'in parası bitti 😿. Google-san'a ödeme yapamıyoruz, bu yüzden sitenin işlevleri şu an için sınırlı. Lütfen bizi Ko-fi'de desteklemeyi düşünün!!",
			description: [
				"Birçok şey kodluyorum ve yeni teknolojilere heyecan duyuyor, yeni şeyler yapmaktan her zaman heyecan aldım. Ben bir full stack geliştiriciyim, bu sayfada en son projelerimi ve diğer hobilerimi görebilirsiniz, benimle iletişime geçmek isterseniz, sosyal medyada bana sorabilirsiniz (o゜▽゜)o☆.",
				"10 yaşındayken programlamaya başladım, Java ile başladım, şu anda",
				"gibi birçok dil biliyorum. Kendi kendine öğrenen biriyim, yeni şeyler öğrenmeyi seviyorum ve her zaman yeni zorluklar arıyorum.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "HIZLI GERÇEKLER:",
			facts: [
				"22 yaşındayım.",
				"Honduraslıyım.",
				"Şu anda UNAH'da Sistem Mühendisliği okuyorum.",
				"Hobilerim müzik prodüksiyonu 🎹, sanat 🎨, grafik tasarım ve kodlamadır!!. ",
				"İlk video oyunum için müzik yapıyorum. (✿◡‿◡)",
				"Ben bir Yapay Zeka manyağıyım, bu yüzden muhtemelen çok sayıda AI projesi göreceksiniz.",
				"Websitesi, botlar (özellikle discord botları) ve herhangi bir algoritma veya komut dosyası kodluyorum.",
				"Analog/retro teknolojileri gerçekten çok seviyorum.",
			],
			nervesscatMessage: "İşte Nervesscat!!",
		},
		stars: {
			title: "İletişim",
		},
		settings: {
			title: "Ayarlar",
			themes: "Temalar",
			language: "Dil Seçimi",
		},
		status: {
			title: "Durum",
			online: "Çevrimiçi",
			offline: "Çevrimdışı",
			lastConnection: "Son Bağlantı:",
		},
		zincNet: {
			title: "ZincNet'i Ziyaret Et!!!",
		},
		weather: {
			title: "Hava Durumu",
			yourTime: "Sizin Saatiniz:",
			serverTime: "Sunucu Saati:",
			city: "Şehir:",
			temperature: "Sıcaklık:",
			humidity: "Nem:",
			wind: "Rüzgar:",
			precipitation: "Yağış:",
			pressure: "Basınç:",
			cloud: "Bulut:",
			uv: "UV:",
			lastUpdate: "Son Güncelleme:",
		},
		art: {
			title: "Sanat",
			goCheck: "Bunu kontrol et:",
		},
		visitors: {
			title: "Ziyaretçiler",
			total: "Toplam Ziyaretçi:",
			unique: "Tekil Ziyaretçi:",
			returning: "Dönüş yapan Ziyaretçi:",
		},
		projects: {
			title: "Projeler",
			projects: [
				{
					title: "Discord AI Botu",
					description:
						"AI Sanatı oluşturmak veya resimler oluşturmak için bir discord botu, ayrıca bir film veya bir TV şovu hakkında bilgi almak veya rastgele bir alıntı almak için kullanabilirsiniz",
					buttonsText: ["Fiverr'da Bana İş Ver"],
				},
				{
					title: "VisionGPT",
					description:
						"Bilgisayarınızı basit talimatlarla kontrol edebilen bir terminal uygulaması. Talimatları anlamak ve uygulamak için GPT-3 modelini kullanır. Bir program açma, bir programı kapatma, ekran görüntüsü almak vb. şeyler yapabilir.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Zoom görüşmesinin özetini oluşturmak için GPT-3 ve Whisper modellerini kullanan bir terminal uygulaması. Ayrıca bir YouTube videosunun, bir podcastin veya herhangi bir videonun özetini de oluşturabilir.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Notlarınızı analiz etmek için AI kullanan bir günlük uygulaması. Notlarınızdaki bilgi ve hedefleri analiz etmek için bir AI modeli kullanır, otomatik tamamlama, öneriler, fikirler vb. sunarak size yardımcı olur.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Enron Veritabanı için bir posta istemcisi, dizinleyici ve arama motoru. Enron Veritabanı'ndaki e-postaları aramak için özel bir dizinleyici ve arama motoru kullanır.",
					buttonsText: ["Github"],
				},
				{
					title: "Dönen Küp",
					description:
						"C++ ile oluşturulmuş eğlenceli bir matematik bulmacası.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot, OpenAI API'sini kullanarak AI destekli botlar oluşturmayı kolaylaştırmak için tasarlanmış bir Python kütüphanesidir. Bu kütüphane, çeşitli işlevleri entegre etmek ve özelleştirilebilir bir asistan oluşturmak için basit bir arayüz sunar.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Yetenekler",
			status: "Sistem Durumu:",
		},
		aiChat: {
			title: "AI Sohbeti",
			chat: "Merhaba, ben Nervesscat 😼, bu web sitesi hakkında bilmek istediğiniz her konuda size yardımcı olabilirim. Daha fazla seçenek görmek için /help yazın.",
		},
		errorRouter: {
			title: "Sayfa Mevcut Değil",
			description: [
				"Buraya Tıklayın",
				"veya bekleyin",
				"saniyede yönlendirileceksiniz",
			],
		},
		connFailed: {
			title: "Bağlantıda Sorun Var :c",
			description:
				"Sorun devam ederse, sosyal medyamızda sizi bilgilendireceğiz.",
			support: "Kofi Üzerinde Bize Destek Olun (✿◡‿◡)",
		},
	},
	pl: {
		bio: {
			welcome: "Witaj w nervesscat",
			pageWork: "Strona wciąż w budowie!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat skończyły się pieniądze 😿. Nie możemy zapłacić Google-san, więc funkcjonalności strony internetowej są obecnie ograniczone. Proszę rozważ wsparcie nas na Ko-fi!!",
			description: [
				"Koduję wiele rzeczy i zawsze jestem podekscytowany nowymi technologiami i tworzeniem nowych rzeczy. Jestem programistą full stack, na tej stronie możesz zobaczyć moje ostatnie projekty i inne hobby, jeśli chcesz się ze mną skontaktować, śmiało pytaj na moich mediach społecznościowych (o゜▽゜)o☆.",
				"Zacząłem programować w wieku 10 lat, zaczynając od Javy, obecnie znam wiele języków takich jak",
				"i wiele innych. Jestem samoukiem, uwielbiam się uczyć nowych rzeczy i zawsze szukam nowych wyzwań.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "SZYBKIE FAKTY:",
			facts: [
				"Mam 22 lat.",
				"Pochodzę z Hondurasu.",
				"Aktualnie studiuję Inżynierię Systemów na UNAH.",
				"Moje hobby to produkcja muzyki 🎹, sztuka 🎨, projektowanie graficzne i programowanie!!.",
				"Tworzę muzykę do mojej pierwszej gry wideo. (✿◡‿◡)",
				"Jestem maniakiem sztucznej inteligencji, więc najprawdopodobniej zobaczysz wiele projektów z nią związanych.",
				"Koduję strony internetowe, boty (szczególnie boty na Discordzie) oraz różne algorytmy i skrypty.",
				"Bardzo lubię technologie analogowe/retro.",
			],
			nervesscatMessage: "Oto Nervesscat!!",
		},
		stars: {
			title: "Kontakt",
		},
		settings: {
			title: "Ustawienia",
			themes: "Motywy",
			language: "Wybierz język",
		},
		status: {
			title: "Status",
			online: "Online",
			offline: "Offline",
			lastConnection: "Ostatnie połączenie:",
		},
		zincNet: {
			title: "Odwiedź ZincNet!!!",
		},
		weather: {
			title: "Moja Pogoda",
			yourTime: "Twój czas:",
			serverTime: "Czas serwera:",
			city: "Miasto:",
			temperature: "Temperatura:",
			humidity: "Wilgotność:",
			wind: "Wiatr:",
			precipitation: "Opady:",
			pressure: "Ciśnienie:",
			cloud: "Chmura:",
			uv: "Promieniowanie UV:",
			lastUpdate: "Ostatnia aktualizacja:",
		},
		art: {
			title: "Sztuka",
			goCheck: "Sprawdź to:",
		},
		visitors: {
			title: "Odwiedzający",
			total: "Wszystkich odwiedzających:",
			unique: "Unikalnych odwiedzających:",
			returning: "Powracających odwiedzających:",
		},
		projects: {
			title: "Projekty",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"Bot Discord do generowania sztuki AI lub tworzenia obrazów, można go również używać do uzyskiwania informacji o filmie lub serialu lub do otrzymywania losowych cytatów",
					buttonsText: ["Zatrudnij mnie na Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Aplikacja terminalowa umożliwiająca sterowanie komputerem za pomocą prostych poleceń, korzysta z modelu GPT-3 do zrozumienia poleceń i ich wykonania. Może wykonywać takie czynności jak otwieranie programu, zamykanie programu, robienie zrzutu ekranu i wiele innych.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Aplikacja terminalowa korzystająca z modeli GPT-3 i Whisper do generowania podsumowania spotkania na platformie Zoom, może również generować podsumowanie filmu na YouTube, podcastu lub jakiegokolwiek innego filmu.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Aplikacja dziennikarska, która korzysta z AI do analizy Twoich notatek. Używa modelu AI do analizy informacji i celów w Twoich notatkach, aby pomóc Ci w autouzupełnianiu, dawaniu sugestii, pomysłów i wielu innych.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Klient poczty, indekser i wyszukiwarka dla bazy danych Enrona. Wykorzystuje niestandardowy indekser i wyszukiwarkę do wyszukiwania wiadomości e-mail w bazie danych Enrona.",
					buttonsText: ["Github"],
				},
				{
					title: "Obracająca się kostka",
					description:
						"Śmieszna matematyczna łamigłówka stworzona w języku C++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot to biblioteka Python zaprojektowana w celu ułatwienia tworzenia botów opartych na sztucznej inteligencji przy użyciu interfejsu API OpenAI. Ta biblioteka zapewnia prosty interfejs do integracji różnych funkcji i tworzenia konfigurowalnego asystenta.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Umiejętności",
			status: "Status systemu:",
		},
		aiChat: {
			title: "AI Czat",
			chat: "Cześć, jestem Nervesscat 😼, mogę ci pomóc ze wszystkim, co chcesz wiedzieć o tej stronie internetowej. Wpisz /help aby zobaczyć więcej opcji.",
		},
		errorRouter: {
			title: "Strona nie istnieje",
			description: [
				"Kliknij tutaj",
				"lub poczekaj",
				"sekundy, aby zostać przekierowanym",
			],
		},
		connFailed: {
			title: "Wystąpił problem z połączeniem :c",
			description:
				"Jeśli problem będzie się utrzymywać, poinformujemy Cię na naszych mediach społecznościowych.",
			support: "Wesprzyj nas na Kofi (✿◡‿◡)",
		},
	},
	nl: {
		bio: {
			welcome: "Welkom bij nervesscat",
			pageWork: "Pagina nog in bewerking!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat is door het geld heen 😿. We kunnen Google-san niet betalen, dus de functionaliteiten van de website zijn momenteel beperkt. Overweeg alstublieft ons te steunen op Ko-fi!!",
			description: [
				"Ik codeer veel dingen en ik ben altijd enthousiast over nieuwe technologieën en het maken van nieuwe dingen. Ik ben een full stack ontwikkelaar, op deze pagina kun je mijn laatste projecten en andere hobby's zien. Als je contact met me wilt opnemen, vraag me dan gerust op mijn social media (o゜▽゜)o☆.",
				"Ik begon te programmeren toen ik 10 jaar oud was, ik begon met Java, momenteel ken ik veel talen zoals",
				"en meer. Ik ben een autodidactisch persoon, ik hou ervan om nieuwe dingen te leren en ik ben altijd op zoek naar nieuwe uitdagingen.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "SNELLE FEITEN:",
			facts: [
				"Ik ben 22 jaar oud.",
				"Ik kom uit Honduras.",
				"Ik studeer momenteel System Engineering aan de UNAH.",
				"Mijn hobby's zijn muziekproductie 🎹, kunst 🎨, grafisch ontwerp en coderen!!.",
				"Ik maak muziek voor mijn eerste videospel. (✿◡‿◡)",
				"Ik ben een AI-nerd, dus je zult waarschijnlijk veel projecten daarover zien.",
				"Ik codeer websites, bots (vooral Discord-bots) en allerlei algoritmes of scripts.",
				"Ik hou echt van analoge/retro-technologie.",
			],
			nervesscatMessage: "Hier is Nervesscat!!",
		},
		stars: {
			title: "Contact",
		},
		settings: {
			title: "Instellingen",
			themes: "Thema's",
			language: "Kies taal",
		},
		status: {
			title: "Status",
			online: "Online",
			offline: "Offline",
			lastConnection: "Laatste verbinding:",
		},
		zincNet: {
			title: "Bezoek ZincNet!!!",
		},
		weather: {
			title: "Mijn Weer",
			yourTime: "Jouw Tijd:",
			serverTime: "Server Tijd:",
			city: "Stad:",
			temperature: "Temperatuur:",
			humidity: "Vochtigheid:",
			wind: "Wind:",
			precipitation: "Neerslag:",
			pressure: "Druk:",
			cloud: "Wolk:",
			uv: "UV:",
			lastUpdate: "Laatste Update:",
		},
		art: {
			title: "Kunst",
			goCheck: "Ga kijken:",
		},
		visitors: {
			title: "Bezoekers",
			total: "Totaal Bezoekers:",
			unique: "Unieke Bezoekers:",
			returning: "Terugkerende Bezoekers:",
		},
		projects: {
			title: "Projecten",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"Een Discord-bot om AI-kunst te genereren of afbeeldingen te genereren, je kunt het ook gebruiken om informatie te krijgen over een film of een tv-show, of om een willekeurig citaat te krijgen",
					buttonsText: ["Huur mij in op Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Een terminaltoepassing waarmee je je computer kunt bedienen met eenvoudige instructies, het gebruikt het GPT-3-model om de instructies te begrijpen en uit te voeren. Het kan dingen doen zoals een programma openen, een programma sluiten, een schermafbeelding maken en meer.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Een terminaltoepassing die de modellen GPT-3 en Whisper gebruikt om een samenvatting te genereren van een Zoom-vergadering, het kan ook een samenvatting genereren van een YouTube-video, een podcast of een willekeurige video.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Een dagboek-applicatie die AI gebruikt om je notities te analyseren. Het gebruikt een AI-model om de informatie en doelen in je notities te analyseren om je te helpen bij het automatisch aanvullen, suggesties, ideeën en meer.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Een e-mailclient, indexer en zoekmachine voor de Enron Database. Het gebruikt een aangepaste indexer en zoekmachine om e-mails in de Enron Database te doorzoeken.",
					buttonsText: ["Github"],
				},
				{
					title: "Draaiende Kubus",
					description: "Een grappige wiskundige puzzel gemaakt in c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot is een Python-bibliotheek die is ontworpen om het maken van AI-aangedreven bots met behulp van de API van OpenAI te vergemakkelijken. Deze bibliotheek biedt een eenvoudige interface om verschillende functionaliteiten te integreren en een aanpasbare assistent te creëren.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Vaardigheden",
			status: "Systeemstatus:",
		},
		aiChat: {
			title: "AI Chat",
			chat: "Hallo, ik ben Nervesscat 😼, ik kan je helpen met alles wat je wilt weten over deze website. Typ /help om meer opties te zien.",
		},
		errorRouter: {
			title: "Pagina bestaat niet",
			description: [
				"Klik Hier",
				"of wacht",
				"seconden om doorverwezen te worden",
			],
		},
		connFailed: {
			title: "Er is een probleem met de verbinding :c",
			description:
				"Als het probleem aanhoudt, laten we je dit weten via onze sociale media.",
			support: "Ondersteun ons op Kofi (✿◡‿◡)",
		},
	},
	sv: {
		bio: {
			welcome: "Välkommen till nervesscat",
			pageWork: "Sidans arbete pågår!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat har slut på pengar 😿. Vi kan inte betala Google-san, så funktionerna på webbplatsen är begränsade för tillfället. Vänligen överväg att stödja oss på Ko-fi!!",
			description: [
				"Jag kodar många saker och jag är alltid exalterad över nya teknologier och att skapa nya saker. Jag är en full stack-utvecklare, på denna sida kan du se mina senaste projekt och andra intressen. Om du vill kontakta mig, tveka inte att fråga mig på mina sociala medier (o゜▽゜)o☆.",
				"Jag började programmera när jag var 10 år gammal, jag började med Java, för närvarande kan jag många språk som",
				"och mer. Jag är en självlärd person, jag älskar att lära mig nya saker och jag söker alltid efter nya utmaningar.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "SNABBA FAKTA:",
			facts: [
				"Jag är 22 år gammal.",
				"Jag kommer från Honduras.",
				"Jag studerar för närvarande systemteknik vid UNAH.",
				"Mina hobbyn är musikproduktion 🎹, konst 🎨, grafisk design och programmering!!.",
				"Jag skapar musik till mitt första datorspel. (✿◡‿◡)",
				"Jag är en AI-nörd, så du kommer förmodligen att se många projekt om det.",
				"Jag kodar webbplatser, botar (särskilt Discord-bots) och alla slags algoritmer eller skript.",
				"Jag gillar verkligen analog/retro-teknik.",
			],
			nervesscatMessage: "Här är Nervesscat!!",
		},
		stars: {
			title: "Kontakt",
		},
		settings: {
			title: "Inställningar",
			themes: "Teman",
			language: "Välj språk",
		},
		status: {
			title: "Status",
			online: "Online",
			offline: "Offline",
			lastConnection: "Senaste anslutning:",
		},
		zincNet: {
			title: "Besök ZincNet!!!",
		},
		weather: {
			title: "Mitt Väder",
			yourTime: "Din Tid:",
			serverTime: "Server Tid:",
			city: "Stad:",
			temperature: "Temperatur:",
			humidity: "Luftfuktighet:",
			wind: "Vind:",
			precipitation: "Nederbörd:",
			pressure: "Tryck:",
			cloud: "Moln:",
			uv: "UV:",
			lastUpdate: "Senast Uppdaterad:",
		},
		art: {
			title: "Konst",
			goCheck: "Gå och kolla:",
		},
		visitors: {
			title: "Besökare",
			total: "Totalt Antal Besökare:",
			unique: "Unika Besökare:",
			returning: "Återvändande Besökare:",
		},
		projects: {
			title: "Projekt",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"En Discord-bot för att generera AI-konst eller skapa bilder, du kan också använda den för att få information om en film eller en tv-show eller för att få ett slumpmässigt citat",
					buttonsText: ["Anlita mig på Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"En terminalapplikation som låter dig styra din dator med enkla instruktioner, den använder GPT-3-modellen för att förstå och utföra instruktioner. Den kan göra saker som att öppna ett program, stänga ett program, ta en skärmdump och mer.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"En terminalapplikation som använder modellerna GPT-3 och Whisper för att generera en sammanfattning av ett Zoom-möte, den kan också generera en sammanfattning av en YouTube-video, en podcast eller en annan video.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"En dagboksapplikation som använder AI för att analysera dina anteckningar. Den använder en AI-modell för att analysera information och mål i dina anteckningar för att hjälpa dig med automatisk komplettering, förslag, idéer och mer.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"En e-postklient, indexerare och sökmotor för Enron-databasen. Den använder en anpassad indexerare och sökmotor för att söka efter e-postmeddelanden i Enron-databasen.",
					buttonsText: ["Github"],
				},
				{
					title: "Roterande Kub",
					description: "En rolig matematisk pussel skapad i c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot är ett Python-bibliotek utformat för att underlätta skapandet av AI-drivna botar med hjälp av OpenAI API. Detta bibliotek tillhandahåller ett enkelt gränssnitt för att integrera olika funktioner och skapa en anpassningsbar assistent.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Färdigheter",
			status: "Systemstatus:",
		},
		aiChat: {
			title: "AI Chatt",
			chat: "Hej, jag är Nervesscat 😼, jag kan hjälpa dig med allt du vill veta om den här webbplatsen. Skriv /help för att se fler alternativ.",
		},
		errorRouter: {
			title: "Sidan finns inte",
			description: [
				"Klicka här",
				"eller vänta",
				"sekunder för att omdirigeras",
			],
		},
		connFailed: {
			title: "Det finns ett problem med anslutningen :c",
			description:
				"Om problemet fortsätter kommer vi att meddela dig via våra sociala medier.",
			support: "Stöd oss på Kofi (✿◡‿◡)",
		},
	},
	da: {
		bio: {
			welcome: "Velkommen til nervesscat",
			pageWork: "Siden er stadig under arbejde!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat er løbet tør for penge 😿. Vi kan ikke betale Google-san, så funktionerne på hjemmesiden er begrænsede i øjeblikket. Venligst overvej at støtte os på Ko-fi!!",
			description: [
				"Jeg koder mange ting, og jeg er altid spændt på nye teknologier og nye projekter. Jeg er en fuld stack udvikler, På denne side kan du se mine seneste projekter og andre interesser. Hvis du vil kontakte mig, er du velkommen til at spørge mig på mine sociale medier (o゜▽゜)o☆.",
				"Jeg begyndte at programmere, da jeg var 10 år gammel, jeg startede med Java, i øjeblikket kender jeg mange sprog som",
				"og mere. Jeg er en selvlært person, jeg elsker at lære nye ting, og jeg leder altid efter nye udfordringer.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "HURTIGE FACTS:",
			facts: [
				"Jeg er 22 år gammel.",
				"Jeg er fra Honduras.",
				"Jeg studerer i øjeblikket System Engineering på UNAH.",
				"Mine hobbyer er musikproduktion 🎹, kunst 🎨, grafisk design og kodning!!.",
				"Jeg laver musik til mit første videospil. (✿◡‿◡)",
				"Jeg er en AI-nørd, så du kommer sandsynligvis til at se mange projekter om det.",
				"Jeg koder hjemmesider, bots (især Discord-bots) og enhver algoritme eller script.",
				"Jeg kan virkelig godt lide analog/retro teknologi.",
			],
			nervesscatMessage: "Her er Nervesscat!!",
		},
		stars: {
			title: "Kontakt",
		},
		settings: {
			title: "Indstillinger",
			themes: "Temaer",
			language: "Vælg sprog",
		},
		status: {
			title: "Status",
			online: "Online",
			offline: "Offline",
			lastConnection: "Seneste forbindelse:",
		},
		zincNet: {
			title: "Besøg ZincNet!!!",
		},
		weather: {
			title: "Mit Vejr",
			yourTime: "Din Tid:",
			serverTime: "Server Tid:",
			city: "By:",
			temperature: "Temperatur:",
			humidity: "Luftfugtighed:",
			wind: "Vind:",
			precipitation: "Nedbør:",
			pressure: "Tryk:",
			cloud: "Sky:",
			uv: "UV:",
			lastUpdate: "Sidst Opdateret:",
		},
		art: {
			title: "Kunst",
			goCheck: "Gå og tjek det ud:",
		},
		visitors: {
			title: "Besøgende",
			total: "Samlede Besøgende:",
			unique: "Unikke Besøgende:",
			returning: "Tilbagevendende Besøgende:",
		},
		projects: {
			title: "Projekter",
			projects: [
				{
					title: "Discord AI Bot",
					description:
						"En Discord-bot til at generere AI-kunst eller generere billeder, du kan også bruge den til at få information om en film eller et tv-show eller få et tilfældigt citat",
					buttonsText: ["Ansæt mig på Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"En terminalapplikation, der kan styre din pc ved hjælp af simple instruktioner, den bruger GPT-3-modellen til at forstå instruktionerne og udføre dem. Den kan gøre ting som at åbne et program, lukke et program, tage et skærmbillede og mere.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"En terminalapplikation, der bruger GPT-3- og Whisper-modellerne til at generere en resumé af et zoom-møde, den kan også generere et resumé af en YouTube-video, en podcast eller en hvilken som helst video.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"En journalapp, der bruger AI til at analysere dine notater. Den bruger en AI-model til at analysere information og mål i dine notater for at hjælpe med autoudfyldning, give forslag, ideer og mere.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"En mailklient, indexer og søgemaskine til Enron-databasen. Den bruger en brugerdefineret indexer og søgemaskine til at søge efter e-mails i Enron-databasen.",
					buttonsText: ["Github"],
				},
				{
					title: "Rotating Cube",
					description: "En sjov matematisk puslespil lavet i c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot er et Python-bibliotek designet til at lette oprettelsen af AI-drevne bots ved hjælp af OpenAI's API. Dette bibliotek giver en simpel grænseflade til at integrere forskellige funktionaliteter og skabe en tilpasselig assistent.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Evner",
			status: "Systemstatus:",
		},
		aiChat: {
			title: "AI Chat",
			chat: "Hej, jeg er Nervesscat 😼, jeg kan hjælpe dig med alt, hvad du vil vide om denne hjemmeside. Indtast /help for at se flere muligheder.",
		},
		errorRouter: {
			title: "Siden findes ikke",
			description: [
				"Klik Her",
				"eller vent",
				"sekunder for at blive omdirigeret",
			],
		},
		connFailed: {
			title: "Der er problemer med forbindelsen :c",
			description:
				"Hvis problemet fortsætter, giver vi dig besked på vores sociale medier.",
			support: "Støt os på Kofi (✿◡‿◡)",
		},
	},
	uk: {
		bio: {
			welcome: "Ласкаво просимо до nervesscat",
			pageWork: "Сторінка все ще працює!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"У Nervesscat закінчилися гроші 😿. Ми не можемо заплатити Google-san, тому функціональність сайту обмежена. Будь ласка, підтримайте нас на Ko-fi!!",
			description: [
				"Я кодую багато речей і завжди захоплююся новими технологіями та роблю нові речі. Я повноцінний розробник. На цій сторінці ви можете побачити мої останні проекти та інші хобі. Якщо ви хочете зв'язатися зі мною, не соромтеся запитати мене у моїх соціальних мережах (o゜▽゜)o☆.",
				"Я почав програмувати в 10 років, я почав з Java, наразі я знаю багато мов, таких як",
				"і багато іншого. Я самоучка, я люблю вчитися новому і завжди шукаю нові виклики.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "МИНУЛІ ФАКТИ:",
			facts: [
				"Мені 22 рік.",
				"Я з Гондурасу.",
				"Наразі я навчаюся в магістратурі з системної інженерії в UNAH.",
				"Мої хобі - це музична продукція 🎹, мистецтво 🎨, графічний дизайн та програмування!!.",
				"Я створюю музику до своєї першої відео-гри. (✿◡‿◡)",
				"Я фанат ШІ, тому ви, ймовірно, побачите багато проектів з цієї теми.",
				"Я програмую веб-сайти, ботів (особливо дискорд-ботів) та будь-які алгоритми або сценарії.",
				"Мені дуже подобається аналогова / ретро технологія.",
			],
			nervesscatMessage: "Ось Nervesscat!!",
		},
		stars: {
			title: "Контакт",
		},
		settings: {
			title: "Налаштування",
			themes: "Теми",
			language: "Виберіть мову",
		},
		status: {
			title: "Статус",
			online: "Онлайн",
			offline: "Офлайн",
			lastConnection: "Останнє підключення:",
		},
		zincNet: {
			title: "Відвідайте ZincNet!!!",
		},
		weather: {
			title: "Моя Погода",
			yourTime: "Ваш час:",
			serverTime: "Час сервера:",
			city: "Місто:",
			temperature: "Температура:",
			humidity: "Вологість:",
			wind: "Вітер:",
			precipitation: "Опади:",
			pressure: "Тиск:",
			cloud: "Хмарність:",
			uv: "УФ:",
			lastUpdate: "Останнє оновлення:",
		},
		art: {
			title: "Мистецтво",
			goCheck: "Перейдіть і перевірте це:",
		},
		visitors: {
			title: "Відвідувачі",
			total: "Всього відвідувачів:",
			unique: "Унікальні відвідувачі:",
			returning: "Повторні відвідувачі:",
		},
		projects: {
			title: "Проекти",
			projects: [
				{
					title: "Discord AI Бот",
					description:
						"Дискорд бот для генерації AI-мистецтва або зображень, ви також можете використовувати його, щоб отримати інформацію про фільм або телевізійне шоу або отримати випадкову цитату.",
					buttonsText: ["Найміть мене на Fiverr"],
				},
				{
					title: "VisionGPT",
					description:
						"Термінальний додаток, який може керувати комп'ютером за допомогою простих інструкцій. Він використовує модель GPT-3 для розуміння інструкцій та виконання їх. Він може відкривати програму, закривати програму, робити знімок екрана та багато іншого.",
					buttonsText: ["Github"],
				},
				{
					title: "Zoomarizer",
					description:
						"Термінальний додаток, який використовує моделі GPT-3 та Whisper для створення резюме зустрічі у Zoom, він також може створити резюме для відео на YouTube, подкасту або будь-якого відео.",
					buttonsText: ["Github"],
				},
				{
					title: "DiarAIst",
					description:
						"Додаток-журнал, який використовує AI для аналізу ваших нотаток. Він використовує модель штучного інтелекту для аналізу інформації та цілей у ваших нотатках для допомоги в автозаповненні, наданні пропозицій, ідей та багато іншого.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"Поштовий клієнт, індексатор та пошукова система для бази даних Enron. Він використовує власний індексатор та пошукову систему для пошуку електронних листів у базі даних Enron.",
					buttonsText: ["Github"],
				},
				{
					title: "Поворотний Куб",
					description: "Смішна математична головоломка, створена на c++.",
					buttonsText: ["Github"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot — це бібліотека Python, призначена для спрощення створення ботів на основі штучного інтелекту за допомогою API OpenAI. Ця бібліотека надає простий інтерфейс для інтеграції різних функцій і створення настроюваного помічника.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "Навички",
			status: "Статус системи:",
		},
		aiChat: {
			title: "AI Чат",
			chat: "Привіт, я Nervesscat 😼, можу допомогти вам з усім, що ви хочете знати про цей сайт. Введіть /help, щоб побачити більше варіантів.",
		},
		errorRouter: {
			title: "Сторінка не існує",
			description: [
				"Натисніть Тут",
				"або зачекайте",
				"секунд для перенаправлення",
			],
		},
		connFailed: {
			title: "Помилка підключення :c",
			description:
				"Якщо проблема продовжується, ми повідомимо вас на наших соціальних мережах.",
			support: "Підтримайте нас на Kofi (✿◡‿◡)",
		},
	},
	ka: {
		bio: {
			welcome: "კეთინი ქატა nervesscat-ზე",
			pageWork: "გვერდი ჯერ კიდევ მუშაობს!! (⊙_⊙;) ⚡⚡",
			loginAdvertisement:
				"Nervesscat-ს ფული გაუთავდა 😿. Google-san-ს ვერ გადავუხდით, ამიტომ ვებგვერდის ფუნქციები დროებით შეზღუდულია. გთხოვთ, დაგვეხმაროთ Ko-fi-ზე!!",
			description: [
				"მე შევქმნებ მრავად რაღა და ყველაფერი გამოვკვეთდება ახალ ტექნოლოგიებში და შევქმნის ახალ რაღა. მე ვარ ფულ სტექ დეველოპერი, ამ გვერდზე შეგიძლიათ ნახოთ ჩემი ბოლო პროექტები და სხვა ხისტები, თუ გსმენიან კონტაქტი მიმას სოციალურ მედიაში გთხოვთ (o゜▽゜)o☆.",
				"პროგრამირებას მე ვიწყე 10 წლის არეთში, სწორედ გარდასწორებელი შევარჩიე ჯავას, ამჟამთა მე ვიცი მრავად ენა როგორიცაა",
				"და მრავად სხვა. მე ვარ საკუთრეპროგრამელი, მიყვარხ ვისი რამის აწყება და იღსება ახალში შალებებს.",
			],
			languages: ["C++", "Python", "Javascript", "Java", "Go", "Visual Basic"],
			quickFacts: "სწრაფი ფაქტები:",
			facts: [
				"მე 22 წლის ვარ.",
				"ვარ ჰონდურასგან",
				"სივრცის ინჟინერიაში სტუდირება ვოუნასთან",
				"ჩემი ჰობიები მიუწესებელია მუსიკის შექმნა 🎹, ხელოვნება 🎨, გრაფიკური დიზაინი და კოდირება!!.",
				"ვქმნდ მუსიკას ჩემი პირველ ვიდეო თამაშებზე. (✿◡‿◡)",
				"ვარ არტემის ფანტიკი, ასე რომ თქვენ შესაძლებელია იხილოთ ბევრი პროექტები ამის შესახებ.",
				"კოდი ვფილტრებ ვებ გვერდებს, ბოტებს (სპეციალურად Discord ბოტებს) და ნემი ალგორითმებს ან სკრიპტებს.",
				"მე ნემი მიყვარხ ანალოგიურ და რეტრო ტექნოლოგიას.",
			],
			nervesscatMessage: "კეთინი ქატა nervesscat აქაა!!",
		},
		stars: {
			title: "კონტაქტი",
		},
		settings: {
			title: "პარამეტრები",
			themes: "თემები",
			language: "აირჩიე ენა",
		},
		status: {
			title: "სტატუსი",
			online: "ონლაინ",
			offline: "ოფლაინ",
			lastConnection: "ბოლო შეერთება:",
		},
		zincNet: {
			title: "ვიზიტი ZincNet!!!",
		},
		weather: {
			title: "ჩემი ამინდი",
			yourTime: "თქვენი დრო:",
			serverTime: "სერვერის დრო:",
			city: "ქალაქი:",
			temperature: "ტემპერატურა:",
			humidity: "საშინაგოება:",
			wind: "ქარი:",
			precipitation: "წრუსი:",
			pressure: "წონა:",
			cloud: "ღრმაღი:",
			uv: "UV:",
			lastUpdate: "ბოლო განახ:",
		},
		art: {
			title: "ხელოვნება",
			goCheck: "წაიკითხეთ ის:",
		},
		visitors: {
			title: "ვიზიტორები",
			total: "სულ ვიზიტორები:",
			unique: "უნიკალური ვიზიტორები:",
			returning: "დაბრუნებული ვიზიტორები:",
		},
		projects: {
			title: "პროექტები",
			projects: [
				{
					title: "Discord AI ბოტი",
					description:
						"Discord ბოტი, რომელიც შექმნის AI ხელსაწყო ან სურავი, ასევე შეგიძლია იყენოთ ასამისთვის ინფორმაცია ფილმზე ან ტელევიზიალურ შოუზე, ან მიიღო შემთხვევანი ცალკე სიტატმენტი",
					buttonsText: ["დამიი მე ფივერ-ში"],
				},
				{
					title: "VisionGPT",
					description:
						"ტერმინალური აპლიკაცია, რომელიც შეიძლება კონტროლეთ თქვენი პც სიმპლი ინსტრუქციებით, ის იყენებს GPT-3 პატარამეტრებს, რომ გაიგოს ინსტრუქციები და ასრებოს ისინი. ის შეიძლებს რამე რამე, როგორიცაა საძუზე პროგრამა, გახურებული პროგრამა, თანაფილმური დაფისატმენტი და უფროსად.",
					buttonsText: ["გითჰაბი"],
				},
				{
					title: "Zoomarizer",
					description:
						"ტერმინალური აპლიკაცია, რომელიც იყენებს GPT-3 და Whisper მოდელებს, რომ შექმნას მიჯურება zoom მოვთათამისის, იყენებს შეჯიჭმოთ მიჯსება სიტატმენტი ან არვიდეობს რომ მიჯუძოთ რაო ვიიდეოს სიტატმენტი, პოდკასთ ან ნემი ვიდეო.",
					buttonsText: ["გითჰაბი"],
				},
				{
					title: "DiarAIst",
					description:
						"ჟურნალული აპლიკაცია, რომელიც იყენს AI-ს სარკი რიჭე, რომ ანალიზები თქვე model-ის შოშოთა და გამართა თქვე შიშულება და დიდი რამი.",
					buttonsText: [],
				},
				{
					title: "MamuroMail",
					description:
						"ელ-ფოსტის ცლიენტი, ინდექსირის და ძედეუბეს უფშსაძმ. თქვენის ხტარიივილი, გრაფიქონი სიეს ეძენს წყებებს ენრონის ბალასეშდშე.",
					buttonsText: ["გითჰაბი"],
				},
				{
					title: "გრატინგ კუბი",
					description: "სიტკისკანლისული მათთ ოჩდებაითი შექმესანა სი++,",
					buttonsText: ["გითჰაბი"],
				},
				{
					title: "Easy Bot",
					description:
						"EasyBot არის Python ბიბლიოთეკა, რომელიც შექმნილია OpenAI-ის API-ის გამოყენებით ხელოვნური ინტელექტის მქონე ბოტების შექმნის გასაადვილებლად. ეს ბიბლიოთეკა გთავაზობთ მარტივ ინტერფეისს სხვადასხვა ფუნქციონალის ინტეგრაციისა და პერსონალიზებული ასისტენტის შესაქმნელად.",
					buttonsText: ["Github"],
				},
			],
		},
		skills: {
			title: "ურთიერთობისს შეცდომა:",
			status: "სისტემის სტატუსი:",
		},
		aiChat: {
			title: "AI ჩეთი",
			chat: "გამარჯობა, მე ვარ Nervesscat 😼, შემიძლია დაგეხმაროთ ნებისმიერი საკითხში, რაც გაინტერესებთ ამ ვებგვერდის შესახებ. აკრიფეთ /help რათა იხილოთ მეტი ვარიანტი.",
		},
		errorRouter: {
			title: "გვერდი არ იძქრთ",
			description: ["ეკლიიმ ჰერსი", "ან ითვლიან", "წარსულად რდეფისობა"],
		},
		connFailed: {
			title: "შეერთება გარდაიონო :c",
			description:
				"თუ პრობლემა დაგავჭეებეც, ჩვენთ სოციალურ მედიაში გამოგთხობილოთ.",
			support: "ჩვენგ მხმოთ Kofi-შთ მხმოთ.",
		},
	},
};
