import { useState, useCallback, useEffect, Fragment } from "react";
import { useTheme } from "../../../../Context/Themes";
import { useLanguage } from "../../../../Context/LanguageContext";

import { language } from "../../../../Utils/Settings";

const availableLanguages = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'pt', label: 'Português' },
    { value: 'de', label: 'Deutsch' },
    { value: 'uk', label: 'Українська' },
    { value: 'fr', label: 'Français' },
    { value: 'it', label: 'Italiano' },
    { value: 'ru', label: 'Русский' },
    { value: 'zh', label: '中文' },
    { value: 'ja', label: '日本語' },
    { value: 'ko', label: '한국어' },
    { value: 'ka', label: 'ქართული'},
    { value: 'hi', label: 'हिन्दी' },
    { value: 'fil', label: 'Filipino' },
    { value: 'vi', label: 'Tiếng Việt' },
    { value: 'th', label: 'ภาษาไทย' },
    { value: 'tr', label: 'Türkçe' },
    { value: 'pl', label: 'Polski' },
    { value: 'nl', label: 'Nederlands' },
    { value: 'sv', label: 'Svenska' },
    { value: 'da', label: 'Dansk' },
]

export const LanguageSelector = () => {
    const { theme } = useTheme();
    const { lang, setLang } = useLanguage();
    const [actualLanguage, setActualLanguage] = useState('en')

    function handleLanguageChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const key = event.target.value as keyof typeof language;
        setActualLanguage(event.target.value);
        setLang(language[key]);
        setLanguageInLocalStorage(event.target.value)
    }

    function setLanguageInLocalStorage(language: string) {
        localStorage.setItem('language', language)
    }

    function getLanguageFromLocalStorage() {
        const langLocal = localStorage.getItem('language')
        if(langLocal && langLocal in language) {
            const key = langLocal as keyof typeof language
            return key
        } else {
            return null
        }
    }

    const setLanguageFromBrowser = useCallback(() => {
        function checkIfLanguageIsAvailable(language: string) {
            return availableLanguages.some(availableLanguage => availableLanguage.value === language)
        }

        function getLanguageFromBrowser() {
            const browserLanguage = navigator.language
            const language = browserLanguage.split('-')[0]
            return language
        }

        const languageFromBrowser = getLanguageFromBrowser()
        const languageFromLocalStorage = getLanguageFromLocalStorage()

        if (languageFromLocalStorage){
            const key = languageFromLocalStorage as keyof typeof language
            setActualLanguage(languageFromLocalStorage)
            setLang(language[key])
            return
        }

        if(checkIfLanguageIsAvailable(languageFromBrowser)) {
            const key = languageFromBrowser as keyof typeof language
            setActualLanguage(languageFromBrowser)
            setLang(language[key])
        }
    }, [setLang])

    useEffect(setLanguageFromBrowser, [setLanguageFromBrowser])

    return(
        <Fragment>
            <h1 className={`text-center text-xl font-extrabold ${theme.secondary.textColor}`}>{ lang.settings.language }</h1>
            <select className={`w-full self-center ${theme.primary.textColor} ${theme.primary.bgColor} rounded-md p-2`} value={actualLanguage} onChange={handleLanguageChange} title='Select language'>
                {
                availableLanguages.map((language, index) => {
                    return(
                    <option value={language.value} key={index}>{language.label}</option>
                    )
                })
                }
            </select>
        </Fragment>
    )
} 