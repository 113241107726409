import { useState, useEffect, useCallback, Fragment } from 'react'

import { useLogin } from '../../../Context/Login'
import { useTheme } from '../../../Context/Themes'
import { useLanguage } from '../../../Context/LanguageContext'

import axios from 'axios'

import sun from '../../../Assets/Img/Weather/sun.png'
import moon from '../../../Assets/Img/Weather/moon.png'
import cloudyday from '../../../Assets/Img/Weather/cloudy-day.png'
import cloudynight from '../../../Assets/Img/Weather/cloudy-night.png'
import rain from '../../../Assets/Img/Weather/rain.png'
import thunder from '../../../Assets/Img/Weather/thunder.png'
import { Frame } from '../../Another/Frame'

interface WeatherType {
    location: {
        country: string;
        name: string;
        localtime: string;
    };
    current: {
        condition: {
            icon: string;
            text: string;
        };
        temp_c: number;
        temp_f: number;
        humidity: number;
        wind_kph: number;
        precip_mm: number;
        pressure_mb: number;
        cloud: number;
        uv: number;
        last_updated: string;
    }
}

export default function Weather() {
    const { login } = useLogin();
    
    const [weatherIcon, setWeatherIcon] = useState(moon);
    const [weather, setWeather] = useState({
        location: {
            country: 'Honduras',
            name: 'Tegucigalpa',
            localtime: '00:00:00'
        },
        current: {
            condition: {
                icon: 'https://cdn.weatherapi.com/weather/64x64/night/116.png',
                text: 'Clear'
            },
            temp_c: 0,
            temp_f: 0,
            humidity: 0,
            wind_kph: 0,
            precip_mm: 0,
            pressure_mb: 0,
            cloud: 0,
            uv: 0,
            last_updated: '00:00:00'
        }
    });

    const getWeatherData = useCallback(() => {
        const API_CALL_WEATHER = process.env.REACT_APP_WEATHER_API_URL;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        axios({
            method: 'get',
            url: API_CALL_WEATHER,
            headers: {
                'Content-Type': 'application/json',
                'Timezone': timezone,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => {
            const weatherData = res.data;
            setWeather(weatherData);
            if (weatherData.current.condition.text.includes('rain')) {
                setWeatherIcon(rain);
            } else if (weatherData.current.condition.text.includes('thunder')) {
                setWeatherIcon(thunder);
            } else if (weatherData.current.condition.text.includes('Overcast') || (weatherData.current.condition.text.includes('cloudy') || weatherData.current.condition.text.includes('Cloudy'))) {
                if (weatherData.current.condition.icon.includes('day')) {
                    setWeatherIcon(cloudyday);
                } else {
                    setWeatherIcon(cloudynight);
                }
            } else if (weatherData.current.condition.icon.includes('day') && !weatherData.current.condition.text.includes('Overcast')) {
                setWeatherIcon(sun);
            } else if (weatherData.current.condition.icon.includes('night') && !weatherData.current.condition.text.includes('Overcast')) {
                setWeatherIcon(moon);
            }
        }).catch((err) => {
            console.log(err)
        })
    }, []);

    useEffect(() => {
        if (login){
            getWeatherData()
        }
    }, [login, getWeatherData]);
    
    return (
        <Frame title={<Title/>} primary={false} left={false}>
            <MainInfo weather={weather} weatherIcon={weatherIcon}/>
            <OtherInfo weather={weather}/>
        </Frame>
    )
}

const Title = () => {
    const { lang } = useLanguage();

    return(
        <h1 className='text-sm break-words md:text-center text-left text-white'>{ lang.weather.title }</h1>
    )
}

interface MainInfoType {
    weather: WeatherType,
    weatherIcon: any 
}

const MainInfo = ({ weather, weatherIcon } : MainInfoType) => {
    const { theme } = useTheme();

    return(
        <Fragment>
            <img src={ weatherIcon } alt='pixel art planet' className='h-full w-full self-center'/>
            <h1 className={`text-sm font-extrabold ${theme.secondary.textColor}`}> { weather.current.condition.text } </h1>
        </Fragment>
    )
}

interface OtherInfoType {
    weather: WeatherType
}

const OtherInfo = ({ weather } : OtherInfoType) => {
    const { theme } = useTheme();
    const { lang } = useLanguage();

    const [actualTime, setActualTime] = useState(new Date().toLocaleTimeString());
    
    useEffect(() => {
        const interval = setInterval(() => {
            setActualTime(new Date().toLocaleTimeString());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Fragment>
            <p className={theme.primary.textColor}>{ lang.weather.yourTime } <span className={`font-extrabold ${theme.secondary.textBold}`}> { actualTime } </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.serverTime } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.location.localtime } </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.city } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.location.country } / { weather.location.name } </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.temperature } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.current.temp_c }°C / { weather.current.temp_f }°F </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.humidity } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.current.humidity }% </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.wind } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.current.wind_kph } km/h </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.precipitation } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.current.precip_mm } mm </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.pressure } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.current.pressure_mb } mb </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.cloud } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.current.cloud }% </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.uv } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.current.uv } </span></p>
            <p className={theme.primary.textColor}>{ lang.weather.lastUpdate } <span className={`font-extrabold ${theme.secondary.textBold}`}> { weather.current.last_updated } </span></p>
        </Fragment>
    )
}