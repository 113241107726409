import { debounce } from "lodash";

export class CanvasManager {
	canvas: HTMLCanvasElement;
	ctx: CanvasRenderingContext2D | null;
	height!: number;
	width!: number;
	id: number | null;
	addElementsToResize() {}

	constructor(canvas: HTMLCanvasElement) {
		this.canvas = canvas;
		this.ctx = this.canvas.getContext("2d");
		this.id = null;

		this.height = this.canvas.offsetHeight;
		this.width = this.canvas.offsetWidth;

		this.resize = debounce(this.resize.bind(this), 500);
		this.resize();
		window.addEventListener("resize", this.resize);
	}

	resize() {
		this.height = this.canvas.offsetHeight;
		this.width = this.canvas.offsetWidth;
		this.canvas.width = this.width;
		this.canvas.height = this.height;
		this.addElementsToResize();
	}

	cleanup() {
		window.removeEventListener("resize", this.resize);
		if (this.id) cancelAnimationFrame(this.id);
		this.drawBlackBackground();
	}

	drawBlackBackground() {
		if (!this.ctx) return;
		this.ctx.fillStyle = "black";
		this.ctx.fillRect(0, 0, this.width, this.height);
	}

	init() {}
}
