import { useState, useEffect } from 'react'

import { useTheme } from '../../Context/Themes'

import '../../Assets/Styles/Logo.scss'

export default function CMDLoading( { setFinished } : { setFinished : Function } ) {
    const [currentLine, setCurrentLine] = useState(0)
    const { theme } = useTheme();

    let loadingText = [
        'Welcome to the Nervesscat Web Page Loading Terminal',
        '===============================================',
        '',
        'Initializing...',
        'Loading necessary packages and dependencies...',
        '===============================================',
        '- Verifying package integrity... Done.',
        '- Installing dependencies... Done.',
        '- Loading packages... Done.',
        '- Checking network connection... Connected.',
        '',
        'Loading Nervesscat website...',
        '===============================================',
        '',
        '- Preparing HTML structure... Done.',
        '- Loading CSS styles... Done.',
        '- Fetching JavaScript files... Done.',
        '',
        'Creating log records...',
        '===============================================',
        '',
        '[INFO] - Package "cool_effects.scss" loaded successfully.',
        '[INFO] - Creating meawing_effect.wav ... Done.',
        '[INFO] - Package "marquee.css" loaded successfully.',
        '[INFO] - Stylesheet "main.css" applied.',
        '[INFO] - Loading font "PIXELITE".',
        '[INFO] - Font "PIXELITE" loaded successfully.',
        '[INFO] - JavaScript file "script.js" fetched and executed.',
        '[INFO] - Fetching data from API: "https://api.nervesscat.com/data".',
        '[ERROR] - Failed to fetch data. Network request timed out.',
        '[INFO] - Retrying API request...',
        '[INFO] - Data successfully retrieved, 153KB received.',
        '[INFO] - Rendering dynamic content... Done.',
        '',
        'Loading Images...',
        '===============================================',
        '',
        '[INFO] - Loading background image... Done.',
        '[INFO] - Loading logo image... Done.',
        '[INFO] - Preloading gallery images... 5 images preloaded.',
        '',
        'Finalizing...',
        '===============================================',
        '',
        '[INFO] - Nervesscat website successfully loaded!',
        '[INFO] - Page took 2.2 seconds to load.',
        '',
        'Terminal session ended.',
        '===============================================',
    ]

    useEffect(() => {
        setTimeout(() => {
          if (currentLine < loadingText.length - 1) {
            setCurrentLine(currentLine + 1);
          }
        }, 20);
        setTimeout(() => {
          if (currentLine === loadingText.length - 1) {
              setFinished(true)
          }
        }, 500);
      }, [currentLine, loadingText.length, setFinished]);

    useEffect(() => {
        if (loadingText.length === 0) {
            setFinished(true);
        }
    }, [loadingText.length, setFinished]);

  return (
    <div className={`w-full h-full pl-8 pt-8 flex flex-col justify-start loading`}>
        {loadingText.slice(0, currentLine + 1).map((line, index) => (
            <pre className='flex flex-col' key={index}>
                <p className={`flex-auto text-left text-clip text-xs ${theme.secondary.textBold}`}>
                    {line}
                </p>
            </pre>
        ))}
    </div>
  )
}
