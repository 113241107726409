import { useEffect, useRef } from "react";
import { CanvasMusicPlayer } from "../../../Assets/TS/Windows/Widgets/MusicPlayer";

import { Frame } from "../Frame";
import { useTheme } from "../../../Context/Themes";

import NervessBackground from '../../../Assets/Img/Background/music_player_background.png'
import RetroBackground from '../../../Assets/Img/Background/music_player_background_green.png'
import HalloweenBackground from '../../../Assets/Img/Background/music_player_background_orange.png'
import OldMacBackground from '../../../Assets/Img/Background/music_player_background_white.png'

const getBackground = (style: string) => {
    if (style === 'nervessStyle'){
        return NervessBackground;
    } else if (style === 'retroStyle'){
        return RetroBackground;
    } else if (style === 'halloweenStyle'){
        return HalloweenBackground
    } else if (style === 'oldMacStyle'){
        return OldMacBackground
    }
}

export default function MusicPlayer() {
    const { theme } = useTheme();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const windowRef = useRef<CanvasMusicPlayer | null>(null);
    

    useEffect(() => {
        if (!canvasRef.current) return;
        windowRef.current = new CanvasMusicPlayer(canvasRef.current, getBackground(theme.misc.style), theme.misc.points);
        windowRef.current.init();
        return () => {
            if (!windowRef.current) return;
            windowRef.current.cleanup();
        }
    }, [canvasRef, theme])

    useEffect(() => {
        if (!windowRef.current) return;

        windowRef.current.changeBackground(getBackground(theme.misc.style), theme.misc.points);
    }, [theme])

    return (
        <Frame title={<Title/>} primary={false}>
            <canvas className="w-full h-80 sm:h-44 rounded-md" ref={canvasRef}/>
        </Frame>
    )
}

const Title = () => {
    return(<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff"><path d="M388-68q-80.49 0-137.24-56.76Q194-181.51 194-262q0-79.49 56.76-136.74Q307.51-456 388-456q15.95 0 29.48 2 13.52 2 28.52 5v-443h321v221H582v409q0 80.49-57.26 137.24Q467.49-68 388-68Z"/></svg>)
}